import React, {Fragment, MouseEvent, ReactNode, useRef, useState} from 'react';

import TagsButton from '@/components/TagsButton';

import useTagsCheck from '@/hook/useTagsCheck';

import c from './index.less';

interface Props {
    contentConfig: Array<$objPropertyType<any>>;
    needUlList?: boolean;
    children?: ReactNode;
}

function isElementInViewport(element: HTMLElement) {
    const scrollContainer = document.querySelector('.leftContainer');
    const containerRect = scrollContainer!.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    return elementRect.top >= containerRect.top && elementRect.bottom <= containerRect.bottom;
}

export default function ScrollTab({contentConfig, needUlList, children}: Props) {
    const [, onMouseClick, selectClassNameFn, , , setContentIndex] = useTagsCheck(
        contentConfig as any,
        c.selected
    );
    const rightContainerRef = useRef<any>();

    const [isScroll, setIsScroll] = useState<boolean>(true);

    const scrollHandler = (e: any) => {
        // 防止点击menu 时触发tagsBtn的滚动会造成抽搐
        setIsScroll(true);
        const scroll = e.currentTarget.scrollTop;

        // 找出离顶部距离最小的内容，获得他的下标就可以将左侧对应的 menu 高亮
        const arr = Array(contentConfig.length)
            .fill(0)
            .map((_, index) => {
                const contentItem = document.getElementById(`policy${index}`);
                return Math.abs(scroll - (contentItem?.offsetTop || 0));
            });
        const min = Math.min(...arr);
        if (min < 200) {
            const activeIndex = arr.indexOf(min);
            setContentIndex(activeIndex);
            const tagsBtn = document.querySelector(`.tags${activeIndex}`) as HTMLDivElement;

            // 防止高亮的菜单并没有在可视区域内，isScroll对应上面第一条 setIsScroll 逻辑
            if (tagsBtn && isScroll && !isElementInViewport(tagsBtn)) {
                tagsBtn.parentNode
                    && ((tagsBtn.parentNode as any).scrollTop = tagsBtn.offsetTop - 330);
            }
        }
    };

    const menuClickHandler = (e: MouseEvent) => {
        e.stopPropagation();
        setIsScroll(false);
        const index = onMouseClick(e);
        const targetDiv = document.getElementById(`policy${index}`);
        if (targetDiv) {
            rightContainerRef.current
                && (rightContainerRef.current.scrollTop = targetDiv.offsetTop);
        }
    };

    return (
        <div className={c.contentContainer}>
            <div className={`leftContainer ${c.tagsBtnContainer}`} onMouseDown={menuClickHandler}>
                <TagsButton
                    className={c.tagsBtn}
                    contentConfig={contentConfig as any}
                    selectClassNameFn={selectClassNameFn}
                />
            </div>
            <div className={c.rightTextContainer} onScroll={scrollHandler} ref={rightContainerRef}>
                {needUlList ? (
                    <>
                        {children}
                        <div className="item">
                            <ul className="ulList">
                                {contentConfig.map((i, index) => (
                                    <Fragment key={i.tags}>
                                        <li id={`policy${index}`} className="headerLi">
                                            <h3>{i.tags}</h3>
                                        </li>
                                        <div className="text">
                                            {i.works ? (
                                                i.works.map((i: Record<string, any>) => (
                                                    <Fragment key={i.secondTitle}>
                                                        <h3 className="ident">{i.secondTitle}</h3>
                                                        {i.textList.map((i: string) => (
                                                            <p className="ident" key={i}>
                                                                {i}
                                                            </p>
                                                        ))}
                                                    </Fragment>
                                                ))
                                            ) : (
                                                <>
                                                    {i.notIdentText ? (
                                                        <p>{i.notIdentText}</p>
                                                    ) : null}
                                                    {i.textList.map((i: string) => (
                                                        <p className="ident" key={i}>
                                                            {i}
                                                        </p>
                                                    ))}
                                                </>
                                            )}
                                        </div>
                                    </Fragment>
                                ))}
                            </ul>
                            <h1 style={{textAlign: 'right'}}>百度</h1>
                        </div>
                    </>
                ) : (
                    <>
                        {contentConfig.map((item, index) => {
                            return (
                                <div id={`policy${index}`} key={item.tags}>
                                    <div
                                        className={c.text}
                                        // bca-disable-line
                                        dangerouslySetInnerHTML={{__html: item.text}}
                                    />
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
}

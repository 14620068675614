import img21 from '@/assets/xiaoduPDFImg/xiaoduyinsi2-1.png';
import img22 from '@/assets/xiaoduPDFImg/xiaoduyinsi2-2.png';
import img23 from '@/assets/xiaoduPDFImg/xiaoduyinsi2-3.png';
import img31 from '@/assets/xiaoduPDFImg/xiaoduyinsi3-3.png';
import img34 from '@/assets/xiaoduPDFImg/xiaoduyinsi3-4.png';
import img36 from '@/assets/xiaoduPDFImg/xiaoduyinsi3-6.png';
import img37 from '@/assets/xiaoduPDFImg/xiaoduyinsi3-7.png';
import img38 from '@/assets/xiaoduPDFImg/xiaoduyinsi3-8.png';
import img39 from '@/assets/xiaoduPDFImg/xiaoduyinsi3-9.png';
import img41 from '@/assets/xiaoduPDFImg/xiaoduyinsi4-1.png';
import img42 from '@/assets/xiaoduPDFImg/xiaoduyinsi4-2.png';
import img43 from '@/assets/xiaoduPDFImg/xiaoduyinsi4-3.png';
import img44 from '@/assets/xiaoduPDFImg/xiaoduyinsi4-4.png';
import img45 from '@/assets/xiaoduPDFImg/xiaoduyinsi4-5.png';
import img47 from '@/assets/xiaoduPDFImg/xiaoduyinsi4-7.png';
import img48 from '@/assets/xiaoduPDFImg/xiaoduyinsi4-8.png';

export default [
    {
        tags: '',
        key: '',
        content: '<h1 class="first">声明</h1><div class="one"><p>本白皮书仅为用户更好地了解小度隐私保护实践，'
        + '随着小度技术升级和产品迭代，小度会持续完善用户隐私保护的相关功能、机制和流程，'
        + '致力于在为用户提供优质产品服务的同时，持续加强对用户隐私的保护。本白皮书不应被视为法律建议，也不得被视为对小度现行任何合同安排的更改。</p>'
        + '<p>若发现白皮书中存在任何错误或对内容存在任何疑问，请通过Duer_security@baidu.com联系我们。</p>'
        + '<p>小度公司(本白皮书中指上海小度人工智能有限公司及其关联公司，以下亦称“小度”)依法对本白皮书内所有原创图片、架构设计、'
        + '文字描述等内容享有知识产权，未经小度事先书面许可，任何单位、公司或个人不得摘抄、翻译、复制本文档内容的部分或全部，'
        + '否则将追究其相关法律责任。</p><p>关联公司是指DuerOS的经营者上海小度人工智能有限公司及其他存在关联关系的公司的单称或合称，'
        + '包括但不限于上海小度技术有限公司。“关联关系”是指对于任何主体(包括个人、公司、合伙企业、组织或其他任何实体)而言，'
        + '即其直接或间接控制的主体，或直接或间接控制其的主体，或直接或间接与其受同一主体控制的主体。前述“控制”指，'
        + '通过持有表决权、合约或其他方式，直接或间接地拥有对相关主体的管理和决策作出指示或责成他人作出指示的权力或事实上构成实际控制的其他关系。</p></div>',
    },
    {
        tags: '概述',
        key: ' ',
        content: '<h1>概述</h1><div class="one"><p>小度公司是全球领先的智能科技企业，致力于以小度助手(DuerOS)为核心,'
        + '以硬件为重要载体，为大众用户提供不同场景下，更加精准、便捷、多元的智能服务。'
        + '小度公司不断通过AI技术的研发，建设、打磨国内乃至全球领先的多模态对话式、人机交互系统DuerOS。'
        + '打造无处不在的人工智能助手服务，用人工智能让人和设备的交互更自然，让生活更简单美好一直是小度的使命。</p>'
        + '<p>小度将DuerOS系统能力搭建在小度有屏端、小度无屏端、小度耳机、学习平板、健身镜、小度电视、车载支架等硬件产品上，'
        + '让更多用户通过语音对话式的交互提高获取信息的效率，享受便利的生活。在这个过程中，确保数据处理的合法合规是DuerOS运行的红线。'
        + '同时小度认识到在家庭、酒店等环境中隐私对用户的重要性，只有尊重和保护用户信息和隐私，才可以建立用户对小度产品的长期信任。'
        + '因此，尊重、保护、促进用户的个人信息权益和隐私权向来是小度产品设计、运营的基础理念。</p><p>基于小度一直秉承的“公开透明”原则,'
        + '《小度隐私保护白皮书》将公开展示小度在隐私保护所做的持续努力，希望通过与生态合作伙伴的共同努力，加强行业自律，'
        + '为语音助手生态各参与方提供参考，为用户提供更安全放心的产品和服务。</p><p>当然，实现隐私保护需要强大的安全能力提供支撑，'
        + '小度在安全方面具备行业领先的实践和积累，详细内容请查看《小度安全白皮书》。</p></div>',
    },
    {
        tags: '价值观的多维度贯彻',
        key: '1.',
        content: '<h1>1. 小度隐私保护价值观的多维度贯彻</h1>'
        + '<div class="one"><p>小度一直秉承"为帮助用户而生，做用户贴心可靠的助手"的产品价值观。'
        + '理解并认同用户在使用小度语音助手时对个人信息和隐私得到妥善保护的强烈需求。因此，'
        + '帮助用户实现个人信息和隐私权益，为用户提供贴心可靠的隐私安全保护天然蕴含在小度的产'
        + '品价值观中。自小度创立至今，持续将隐私保护价值观融入在组织建设、产品设计、生态建'
        + '设、技术研发等多个维度，让用户享受贴心可靠的语音助手服务的同时，也能享有贴心可靠'
        + '的隐私保护。</p></div>',
        children: [
            {
                tags: '融入小度组织建设',
                key: '1.1',
                content: '<h2>1.1 隐私保护价值观融入小度组织建设</h2>'
                + '<p>小度建立了成熟的隐私保护和数据治理体系，自上而下将隐私保护价值观贯彻融入到小度隐私保护体系各层建设中。</p>'
                + '<p>最高决策层负责数据、隐私相关的重要战略制定和问题决策;管理监督层确保小度数据、隐私的措施符合相关政策法规要求和行业最佳实践;'
                + '执行层面负责小度数据、隐私措施的落地实施。</p>'
                + '<p>另外，小度通过持续性的隐私保护文化宣贯、针对性的隐私保护培训将隐私保护价值观贯穿到整个组织体系中，并确保所有记录完整可考证。</p>'
                + '<p>（1）持续性的隐私保护文化宣贯：小度将提高全员的隐私保护意识融入到组织文化建设，通过内网宣传、办公区域投屏、会议、考核等多种形式'
                + '持续性地进行隐私保护宣贯，提高全员的隐私保护意识，营造隐私保护文化。</p>'
                + '<p>（2）针对性的隐私保护培训：小度针对不同的人群提供不同的隐私保护培训。对于新员工在入职之初会提供基础隐私保护培训，'
                + '对于管理人员、专业人员会基于管理权限提供系列的培训课程。</p>',
            },
            {
                tags: '善用隐私设计工具',
                key: '1.2',
                content: '<h2>1.2 善用隐私设计工具，落地隐私保护价值观</h2>'
                + '<p>小度在设计和开发过程中嵌入小度隐私保护价值观，通过制定、实施隐私保护默认设计策略将个人信息保护和隐私保护的关注点整合到系统和软件工程，'
                + '帮助用户更好地掌握和控制自己的个人信息。</p>'
                + '<p>小度的隐私保护默认设计策略包括“从设计入手保护隐私”（privacybydesign）和“默认隐私保护”（privacybydefault），'
                + '并遵循主动预防，隐私保护作为默认设置，将隐私保护嵌入设计之中，功能完整（正和而非零和），个人信息全生命周期保护，可见性和透明性，'
                + '尊重用户隐私等原则。</p>'
                + '<p>每个产品研发伊始，小度都会提前考虑如何预防和阻止侵害的发生，并提供从开发到下线全过程的安全防护。'
                + '如果用户不做任何主动同意个人信息处理的选择，那么他们的个人信息应当处于默认保护的状态。'
                + '将个人信息保护的需求嵌入产品的设计之中，使其既成为系统的核心组成部分，同时又不损害系统的功能；'
                + '在确保个人信息保护的同时，用户的产品功能体验良好。</p>',
            },
            {
                tags: '隐私保护上线前已发生',
                key: '1.3',
                content: '<h2>1.3 隐私保护，在产品上线前已经发生</h2>'
                + '<p>在小度隐私保护价值观的影响下，小度隐私保护框架覆盖产品全生命周期和数据全生命周期。'
                + '小度在产品和服务上线开展隐私审查和合规流程，确保达到隐私保护要求。</p>'
                + '<p>在产品方案设计阶段，小度引入安全、隐私的评审，保证产品方案在安全和隐私方面的完备性。</p>'
                + '<p>在新品上线前，小度会进行完整的渗透测试，只有安全验收通过后才允许外发，保证外发的设备符合安全隐私基线。</p>'
                + '<p>针对引入的第三方技能和应用，小度会进行上线前的安全扫描，并通过设备上部署的监控程序关注第三方应用和技能的运行状况，'
                + '一旦发现违反安全基线的行为即会上报。判定为违规行为后，小度会对用户进行提示并在必要时采用干预手段保护用户的数据安全和隐私。</p>',
            },
            {
                tags: '全生态隐私保护管控',
                key: '1.4',
                content: '<h2>1.4 全生态隐私保护管控</h2>'
                + '<p>小度始终重视小度生态内的用户个人信息和隐私保护的整体合规，在确保自身个人信息保护和隐私合规建设的基础上，'
                + '与小度生态内各类合作伙伴协同合规，共同创建小度数据安全及合规治理的良好生态。小度不仅制定了严格的供应商管理办法并对供应商的个人信息处理行为进行约束，'
                + '还通过将管控措施嵌入产品关键节点，实现第三方技能和应用上线前、上线中、上线后全流程的隐私保护管控。</p>'
                + '<p>（1）在第三方技能和应用上线前，需严格遵循《小度在家第三方APP安全&隐私准入规范》进行安全及隐私自查。'
                + '小度会对其资质、提交的用户隐私相关权限的必要性进行审核。对第三方技能和应用的用户提示，数据加密、匿名化等传输、存储安全措施，'
                + '以及存储期限等进行约束和限制。</p>'
                + '<p>（2）在第三方技能和应用上线中，会进行安全漏洞扫描检测，以禁止第三方技能和应用通过http协议或https弱校验的方式传输敏感数据，'
                + '禁止应用自更新行为或动态加载代码。如果第三方技能和应用上线前未解决所有的中高危漏洞问题，将不被准允上线。</p>'
                + '<p>（3）在第三方技能和应用上线后，小度会对第三方应用和技能进行安全隐私监控，一旦发现不符合隐私保护规范的行为，'
                + '则会向用户进行强提示，并在必要时对第三方技能和应用进行下线处理，以最大限度保护用户的隐私安全。</p>',
            },
            {
                tags: '隐私技术更好的保护',
                key: '1.5',
                content: '<h2>1.5 隐私技术提供更好的保护</h2>'
                + '<p>在隐私保护价值观的指导下，小度积极发展和采取技术措施确保数据安全，以保护用户的个人信息权益和隐私。</p>'
                + '<p>（1）脱敏技术措施：主要的脱敏技术措施包括数据混淆、替换、抽取等，从而降低数据敏感度和个人隐私风险，保障数据安全。</p>'
                + '<p>（2）加密技术措施：对于涉及敏感信息的传输链路均进行加密处理，使第三方无法截获解密传输数据。其中，对于网络请求采用TLS加密并进行双向证书验证；'
                + '对于射频请求如蓝牙、Wi-Fi，在使用标准的加密套件的基础上，会使用百度私有协议进行二次加密及身份验证。</p>'
                + '<p>（3）数据安全沙箱：对采用加密措施的数据存储在数据安全沙箱中，数据的访问需遵循严格的数据流通措施，对数据访问行为进行严格的安全审计。'
                + '数据安全沙箱将云端数据采用对称加密落盘存储在百度数据管理平台，仅能在使用时解密。密钥托管在具备硬件可信能力的管理平台中，'
                + '并对数据的使用，流转进行严格的安全审计，保障数据使用的合法合规，防止出现泄露、篡改、丢失及未授权访问。</p>',
            },
        ],
    },
    {
        tags: '小度隐私保护管理',
        key: '2.',
        content: '<h1>2. 小度隐私保护管理——全生态、全周期、全流程的立体隐私保护管理体系</h1>',
        children: [
            {
                tags: '小度生态隐私保护框架',
                key: '2.1',
                content: '<h2>2.1 小度生态隐私保护框架</h2>'
                + '<p>小度以用户为中心，建立生态隐私保护框架，明确不同角色的隐私保护和安全责任，'
                + '以更好实现生态内的全面隐私保护。</p>'
                + `<img src="${img21}" alt="2-1" />`
                + '<p>（1）用户：向小度反馈隐私方面的问题和请求权利实现。</p>'
                + '<p>（2）小度：承担DuerOS产品及服务对用户隐私的保护职责，并能够对设备进行安全管理，'
                + '对设备产生的安全风险能够及时感知，对于生态起到监管和约束的作用。</p>'
                + '<p>（3）开发者：保障自身的服务及应用的安全，并遵循开发者协议及相关的隐私及安全规范，'
                + '确保对用户信息的处理合法合规，流转和存储安全，一旦发生用户信息泄露的风险，应按照相关法律规定告知用户和主管部门。</p>'
                + '<p>（4）合作伙伴：建立更新机制，将更新的模块显示告知用户，并建立反馈渠道反馈隐私及安全相关问题。</p>'
                + '<p>（5）ODM/芯片厂商：建立安全补丁机制，针对其开发的模块定期修复安全问题，并将补丁负责任地输出。</p>',
            },
            {
                tags: '小度数据生命周期保护',
                key: '2.2',
                content: '<h2>2.2 小度数据全生命周期保护框架</h2>'
                + '<p>数据的安全、合规处理是实现隐私保护的重要保障，为了更好地保护用户的隐私、个人信息权益，'
                + '小度在数据全生命周期持续进行隐私管理，将规范和方案贯彻在每一阶段的数据处理之中。</p>'
                + `<img src="${img22}" alt="2-2"/>`,
            },
            {
                tags: '小度隐私保护原则',
                key: '2.3',
                content: '<h2>2.3 小度隐私保护原则</h2>'
                + '<p>小度要求在个人信息处理中，必须坚持“公开透明、知情同意、最少够用、确保安全、数据质量、用户体验”的原则。</p>'
                + '<p>（1）公开透明原则</p>'
                + '<p>小度确保产品上线时，同时发布对应的隐私权政策或隐私条款，以明确、易懂和合理的方式公开处理个人信息的范围、目的、规则等，使得用户能简单易懂的获取小度有关隐私权和安全实践的信息。</p>'
                + '<p>（2）知情同意原则</p>'
                + '<p>小度确保在数据处理前，明确向个人信息主体明示：个人信息处理目的、方式、范围、规则等，获得用户授权并在授权范围内进行数据处理。</p>'
                + '<p>（3）最少够用原则</p>'
                + '<p>小度确保在进行用户个人信息的收集、使用、存储、共享等数据处理时，具有明确、合理的目的，并采取与处理目的直接相关且对个人权益影响最小的方式。</p>'
                + '<p>（4）确保安全原则</p>'
                + '<p>小度确保具备与所面临的安全风险相匹配的安全能力，并采取足够的管理措施和技术手段，保护个人信息的保密性、完整性、可用性。</p>'
                + '<p>（5）数据质量原则</p>'
                + '<p>小度确保对收集的重要和常用的用户个人信息数据和衍生类个人信息进行定期检查、验证，依据用户授权或法律规定的使用目的，检查、验证所记录的用户个人信息的客观性、真实性和准确性并及时进行更正。</p>'
                + '<p>（6）用户体验原则</p>'
                + '<p>小度确保产品、服务提供中涉及数据收集、使用、存储、共享的，产品、服务的文案，包括提供给用户点击的授权文件，应具有友好性，不使用让用户感觉不舒适的词汇和表达方式，'
                + '避免使用易使用户或公众产生隐私侵犯误解的表述。关注用户对隐私问题的意见反馈，提升用户信任感；同时，确保向个人信息主体提供易于使用的工具和清晰的选项，'
                + '使得用户能够充分掌控其个人信息，并有查阅、更正、删除、复制、转移其个人信息的渠道，以及变更同意、注销账户、获得就个人信息处理规则的解释等方法。</p>',
            },
            {
                tags: '小度隐私保护管理制度',
                key: '2.4',
                content: '<h2>2.4 小度隐私保护管理制度</h2>'
                + '<p>小度建立了完善的个人信息保护合规制度，包括个人信息保护合规制度、个人信息分类管理制度和个人信息安全事件管理制度。</p>'
                + '<p>（1）个人信息保护合规制度</p>'
                + '<p>根据《民法典》《网络安全法》《个人信息保护法》等法律法规、配套规章和监管要求，小度设置了个人信息保护合规制度，并对个人信息处理设置了合规红线，'
                + '包括对个人信息的收集、个人信息的使用、个人信息的流通、个人信息的访问和操作权限都设置了明确禁止行为的“黑名单”。</p>'
                + '<p>（2）个人信息分类管理制度</p>'
                + '<p>根据《网络安全法》《数据安全法》《个人信息保护法》等相关法律政策对企业数据管理的责任要求，在合法、合规、安全前提下管理数据，'
                + '小度按照数据与自然人的关系，将数据分为非个人信息、非敏感个人信息、个人敏感信息，并采取了差异化的管理制度。</p>'
                + '<p>（3）个人信息安全事件管理制度</p>'
                + '<p>根据《个人信息保护法》的规定，为规范信息安全与隐私泄露事件处理流程，提高员工在信息安全和隐私泄露事件发生时的响应能力，'
                + '做到快速响应、正确处理、及时止损，确保信息系统正常、稳定运行，小度采用事件分类分级方法，设置信息安全事件处理规范。'
                + '首先，根据信息安全事件起因、表现、影响等，对信息安全事件进行分类。再按照信息安全事件的影响范围、影响程度和造成的损失，'
                + '并结合信息安全事件响应处理的紧急程度，对信息安全事件进行分级，并基于此制定了明确的报告与处置方案。</p>',
            },
            {
                tags: '小度隐私保护流程',
                key: '2.5',
                content: '<h2>2.5 小度隐私保护流程</h2>'
                + '<p>为了落实小度个人信息保护制度，小度还制定了详细的数据全生命周期安全流程、用户权利实现流程、PBD流程和隐私影响评估流程。</p>'
                + '<p>小度产品和服务上线前需通过隐私影响评估，评估维度包括数据收集、存储、使用、销毁等数据全生命周期环节</p>'
                + `<img src="${img23}" alt="2-3"/>`,
            },
        ],
    },
    {
        tags: '小度隐私保护路径',
        key: '3.',
        content: '<h1>3. 小度隐私保护路径——以用户为中心</h1>',
        children: [
            {
                tags: '提升感知',
                key: '3.1',
                content: '<h2>3.1 提升感知，帮助用户更好实现知情同意</h2>'
                + '<p>知情同意是我国法定个人信息处理情形之一，也是用户依法享有的权益。小度理解并贯彻用户的同意应当在事先充分告知的前提下取得，'
                + '小度采取以下方式向用户提示小度产品如何保护和收集、处理、使用、存储、共享、转让或公开披露用户提供或者小度收集的信息：</p>'
                + '<h2>（1）敏感系统功能应用时会通过显著方式予以提醒</h2>'
                + '<p>在收集语音信息时，小度设备会通过亮灯的显著方式提示（如下图所示），让用户能够明显感受到信息正在被获取，而做出下一步语音指令的抉择。</p>'
                + `<img src="${img31}" alt="3-1"/>`
                + '<p>在使用回家看看功能时，手机会通过震动的显著方式提示，用户能够明显感受到摄像头功能正在使用，而做出是否需要调整设备设置的选择。</p>'
                + '<h2>（2）隐私政策充分告知</h2>'
                + '<p>小度通过隐私政策向用户充分告知个人信息处理规则并帮助用户实现其个人信息主体权利。在个人信息处理规则的解释中，'
                + '涵盖了对个人信息收集、存储、使用、加工、传输、提供、公开、删除等处理全生命周期中方方面面的内容。</p>'
                + '<p>DuerOS产品及服务的隐私政策链接为：</p>'
                + '<p><a href="https://xiaodu.baidu.com/saiya/xiaodu/privacylaw/index.html" target="_blank">https://xiaodu.baidu.com/saiya/xiaodu/privacylaw/index.html</a></p>'
                + '<p>DuerOS产品及服务会集成第三方APP或SDK，因此小度设置了专门的DuerOS移动应用接入第三方SDK情况说明，链接为：</p>'
                + '<p><a href="https://xiaodu.baidu.com/saiya/xiaodu/privacylaw/three-sdk.html" target="_blank">https://xiaodu.baidu.com/saiya/xiaodu/privacylaw/three-sdk.html</a></p>'
                + '<p>小度非常注重对未成年人的保护，在产品中设置了儿童模式。考虑到儿童隐私和个人信息保护的特殊性，DuerOS产品及服务设置了专门的儿童隐私政策，链接为：</p>'
                + '<p><a href="https://xiaodu.baidu.com/saiya/xiaodu/privacylaw/child.html" target="_blank">https://xiaodu.baidu.com/saiya/xiaodu/privacylaw/child.html</a></p>'
                + '<p>另外，小度在《百度网盘同步隐私说明》中对网盘同步功能的使用、管理、隐私、数据安全等问题进行了详细的说明，链接为：</p>'
                + '<p><a href="https://xiaodu.baidu.com/saiya/xdv1/pan-protocol.html" target="_blank">https://xiaodu.baidu.com/saiya/xdv1/pan-protocol.html</a></p>'
                + '<p>除此以外，小度产品具备多个扩展功能，其中可能涉及敏感个人信息的处理，对此，小度设置了相应专门的协议文本，包括《看护助手协议》、'
                + '《智能声音看护协议》、《声纹服务协议》、《面容服务协议》、《个人语音服务协议》等多个文本向用户提供产品的详细功能和数据处理介绍。</p>',
            },
            {
                tags: '多模态交互',
                key: '3.2',
                content: '<h2>3.2 多模态交互，保障用户个人信息自决权</h2>'
                + '<p>小度提供包括语音交互在内的多模态交互方式。用户可以使用语音指令关闭摄像头和麦克风，'
                + '也可以通过点击麦克风和摄像头的图标进行开启和关闭；摄像头或麦克风在拍摄或收音时，'
                + '有屏设备的右上角会有摄像头和麦克风的禁用图标提示用户，用户也可以采用语音指令的方式关闭。</p>'
                + `<img src="${img34}" alt="3-4"/>`,
            },
            {
                tags: '权限管理工具',
                key: '3.3',
                content: '<h2>3.3 权限管理工具，提高用户对个人信息的掌控</h2>'
                + '<p>小度为确保用户对设备权限可以掌控，不仅在隐私政策中向用户公开系统设备权限的调用详情，'
                + '还在设备上设计了应用权限管理工具帮助用户提高控制个人信息的能力。</p>'
                + '<p>在小度智能屏设备上，用户可以通过下拉列表“禁用唤醒”和“禁用摄像头”（如前图3-4所示），'
                + '或者“隐私设置”的“语音唤醒”和“摄像头”开关控制麦克风与摄像头的状态（如下图3-6所示）。</p>'
                + `<img src="${img36}" alt="3-6"/>`
                + '<p>用户还可以在小度设备端通过“隐私设置->应用权限管理”对第三方应用的隐私权限设置进行管理（如下图所示）。</p>'
                + `<img src="${img37}" alt="3-7"/>`
                + '<p>另外，小度设备还提供针对第三方应用的隐私行为监控能力。'
                + '在小度智能屏设备上，用户可以通过“隐私设置->应用行为记录”对第三方应用的隐私权限行为进行查看和管理（如下图所示）。</p>'
                + `<img src="${img38}" alt="3-8"/>`,
            },
            {
                tags: '积极响应用户请求',
                key: '3.4',
                content: '<h2>3.4 积极响应用户请求</h2>'
                + '<p>为确保小度产品的用户与其个人信息有关的权利得到尊重和及时响应，'
                + '小度制定《用户主体权利响应管理办法》，对于不同部门的职责范围进行了明确分工，'
                + '并确立了完善的处理流程图。</p>'
                + `<img src="${img39}" alt="3-9"/>`,
            },
            {
                tags: '技术创新',
                key: '3.5',
                content: '<h2>3.5 技术创新，帮助用户减少提供个人信息情形</h2>'
                + '<p>尽管可以通过用户同意采集，再提供基于个人信息的服务。'
                + '但是小度仍坚持通过不断技术创新，在不同功能模式下，'
                + '探索减少对用户提供个人信息的利用即可实现相关功能服务的路径。'
                + '例如天气服务，通常情况下的产品可能会通过申请调取设备的位置权限以采集用户的实时地理位置信息，'
                + '辅助完成天气查询，但是小度语音助手经多轮产品优化，'
                + '可为用户提供替代性方案，即在不调取位置权限的前提下，'
                + '为用户提供多种个人信息处理方案下的功能服务实现路径。</p>',
            },
        ],
    },
    {
        tags: '小度隐私保护方案',
        key: '4.',
        content: '<h1>4. 小度隐私保护方案——“从设计入手保护隐私”和“默认隐私保护”</h1>'
        + '<div class="one"><p>小度致力于积极、主动、保护用户隐私和个人信息权益，'
        + '因此在产品设计开发过程中始终贯彻“从设计入手保护隐私“和”默认隐私保护”的理念，'
        + '将隐私保护和个人信息保护关注点整合到系统和软件工程，'
        + '帮助用户更好地实现其对自己个人信息的控制。</p></div>',
        children: [
            {
                tags: '语音唤醒和识别',
                key: '4.1',
                content: '<h2>4.1 语音唤醒和识别--小度只在听到召唤后才开始聆听</h2>'
                + '<p>小度仅在听到唤醒词后才会收集语音信息（语音指令）。'
                + '经过语音唤醒和识别，小度用户可以通过DuerOS语音交互实现指令控制、搜索信息和服务、'
                + '日常聊天、智能提醒、寻址导航、控制设备等功能。</p>'
                + '<p>在收集用户的语音信息及周边的环境音时可能会涉及敏感个人信息，因此，小度在设计之初就'
                + '从硬件设计、软件设计两个层面来保护用户的隐私。</p>'
                + '<p>在硬件设计方面，小度设备提供用户关闭麦克风的物理按键，用户可通过硬件按键来关闭麦克风'
                + '（如下图4-1所示）。在软件设计层面，用户可在小度设备屏幕上，通过下拉列表或“隐私设置”'
                + '来控制麦克风与摄像头的状态。（如图3-6所示）</p>'
                + `<img src="${img41}" alt="4-1"/>`
                + '<p>当设备的麦克风和摄像头在被使用时，硬件设计上也有明显提示方案。'
                + '例如，在小度T系列设备上，设备右上角就会有绿点橙点提示。</p>'
                + '<p>同时，小度唤醒过程的算法和处理是在本地进行，'
                + '唤醒之后的交互语音会上传到云端进行解析成对应到设备控制指令，'
                + '云端不会存储交互原始语音的音频文件数据。</p>',
            },
            {
                tags: '守护家人',
                key: '4.2',
                content: '<h2>4.2 守护家人--让用户知道谁在使用“回家看看”</h2>'
                + '<p>“回家看看”可供用户实时查看设备面前的实时画面。'
                + '当开启回家看看功能开启时，小度设备上会出现明显的提醒（如下图），'
                + '提醒设备前的用户谁正在使用“回家看看”。同时，设备前的用户可手动断开回家看看。</p>'
                + `<img src="${img42}" alt="4-2"/>`
                + '<p>此外，同一家庭圈中的家庭成员默认无法开启回家看看，'
                + '需对该权限进行单独申请且征得管理员单独同意后，方可使用该功能，'
                + '管理员也可随时取消家庭成员的权限</p>',
            },
            {
                tags: '音视频通话',
                key: '4.3',
                content: '<h2>4.3 音视频通话--加密技术守护通话安全</h2>'
                + '<p>小度提供音视频通话的能力，用户可在小度APP和小度设备上发起音视频通话。'
                + '音视频通话过程采用小度自研的加密方案进行加密，保证通话内容无法被还原或篡改。'
                + '另外，整个RTC的链路都采用了加密技术，防止中间人嗅探呼叫双方的音视频通话。</p>'
                + '<p>小度设备端在“设置-通话设置”里提供了诸多针对音视频通话的隐私保护能力：'
                + '用户可以选择拒绝所有的来电请求；也可选择特定时间段拒接来电，避免该时间段被打扰；'
                + '也可以通过黑名单、权限设置、拒接陌生人来电选择接受或者拒接特定人的来电，'
                + '让用户方便、快捷地设置各种隐私保护模式</p>'
                + `<img src="${img43}" alt="4-3"/>`
                + '<p class="centerText">图 4-3 小度设备通话设置中的隐私保护</p>'
                + '<p>小度APP端在设备设置中默认“拒绝陌生人来电”。</p>'
                + `<img src="${img44}" alt="4-4"/>`,
            },
            {
                tags: '生物特征识别',
                key: '4.4',
                content: '<h2>4.4 生物特征识别--多层加密且用户可控</h2>'
                + '<p>声纹ID服务和面容ID服务都是小度为用户提供的基于生物特征信息的识别服务。'
                + '小度能够通过声纹ID和面容ID为用户提供个性化的技能、音乐、视频等内容。'
                + '声纹和人脸是敏感个人信息，因此小度对此进行了更严格的隐私保护设计。</p>'
                + '<p>在使用声纹ID和面容ID时，用户需要手动开启该能力，在开启之前同意'
                + '《声纹ID服务详情》和《面容ID服务详情》，并能够随时关闭声纹ID和面容ID服务。</p>'
                + `<img src="${img45}" alt="4-5"/>`
                + '<p>在使用过程中，对采集的原始声音片段和人脸图像，均进行加密保护，'
                + '并且加密密钥以设备和用户为粒度进行派生。加密后的原始样本均存储在本地，'
                + '识别过程均在设备本地进行。当用户删除自己的声纹ID和面容ID，'
                + '则会在设备上删除对应的特征以及原始文件。在跨设备识别的场景中，'
                + '小度在云端仅保存声纹和脸纹特征，声音和人脸图片的原始样本无法通过特征还原</p>',
            },
            {
                tags: '儿童模式',
                key: '4.5',
                content: '<h2>4.5 儿童模式--小度对儿童的隐私保护</h2>'
                + '<p>为了更好地促进儿童健康成长以及儿童的隐私和个人信息保护，'
                + 'DuerOS设备设置了专门的儿童模式。在儿童模式下，DuerOS设备的使用将受到一定的限制，'
                + '例如使用时长、可获得的技能和内容等。监护人可以通过小度APP和设备对儿童使用设备内容、'
                + '使用时长等进行管理。</p>'
                + '<p>为了帮助监护人更好了解儿童模式和管理儿童的设备使用，'
                + '小度APP在设计上将儿童隐私协议置于更容易触达和获得的位置，让家长更容易获得儿童隐私协议。'
                + '家长在第一次使用小度APP时，即能获知儿童隐私协议的内容。'
                + '同时，家长在“我的-关于小度”二次跳转后即可到达协议内容的页面。</p>'
                + `<img src="${img47}" alt="4-7"/>`
                + '<p>小度在儿童模式的“进入”和“退出”设计中加强家长识别机制，'
                + '家长通过设置进入儿童模式后，如果没有通过家长的解锁退出，小度就会保持儿童模式。</p>',
            },
            {
                tags: '守护合作',
                key: '4.6',
                content: '<h2>4.6 守护合作--小度把保护用户隐私安全深入到酒店场景</h2>'
                + '<p>小度针对酒店行业这个特殊场景定制化研发的酒店版小度智能音箱，'
                + '采取了多种措施，以提升智能音箱在酒店行业各个场景下对住客隐私信息安全的保障能力。'
                + '酒店版小度智能音箱在酒店住客入住、住宿期间、退房等各个阶段提供全方位的保护。</p>'
                + '<h2>（1）无摄像头设计，杜绝监视可能</h2>'
                + '<p>针对酒店场景，小度从硬件设计上做起，酒店版小度音箱移除摄像头，不会采集任何视频图像内容。</p>'
                + `<img src="${img48}" alt="4-8"/>`
                + '<h2>（2）专属激活工具无需登陆个人账号</h2>'
                + '<p>酒店版小度智能音箱提供专属激活工具，酒店住客无需登陆个人账户即可正常使用，'
                + '保护住客隐私信息安全。</p>'
                + '<h2>（3）退房后设备自动重置</h2>'
                + '<p>小度酒店版智能音箱配置“重置”接口，酒店管理系统可在住客退房后自动触发小度酒店版智能音箱的“重置”功能，'
                + '使得设备一键恢复到初始状态，彻底清除用户在小度上的交互记录，保障住客的隐私安全。</p>',
            },
        ],
    },
    {
        tags: '小度隐私保护倡议',
        key: '5.',
        content: '<h1>5. 小度隐私保护倡议</h1>'
        + '<div class="one"><p>小度始终坚持将安全和隐私保护作为经营的重要核心，'
        + '从组织建设、产品设计、技术发展和生态搭建等多维度贯彻隐私保护价值观。'
        + '在“以用户中心”的驱动下，建立了全生态、全周期、全流程的立体隐私保护管理体系，'
        + '通过“从设计入手保护隐私”和“默认隐私保护”将隐私保护理念嵌入到产品的研发和运营中，'
        + '不断加大对安全和隐私的投入并尝试使用更多创新的技术和方法来保护隐私。</p>'
        + '<p>小度也认识到更好地实现用户隐私保护，还需要合作伙伴生态的共同努力。'
        + '小度致力于与生态合作伙伴分享信息安全和隐私保护方面的方法、实践和技术能力，'
        + '共同成长，共同保护用户隐私。自此我们倡议：</p>'
        + '<p><b>尊重用户个人隐私，加强合规体系管理：</b>严格遵守所适用的个人信息和数据保护法律法规，杜绝任何形式的违法违规处理。</p>'
        + '<p><b>尊重用户个人权益，技术创新造福用户：</b>积极维护、保障用户行使个人数据相关的合法权益，'
        + '持续利用创新技术为用户提供便捷的工具和服务。</p>'
        + '<p><b>诚实守信，严格履行隐私保护承诺：</b>：严格遵守隐私政策，'
        + '让数据收集、索权，透明、安全、可靠；严格遵守数据处理协议，'
        + '履行协议中各自数据保护的义务。</p>'
        + '<p>我们呼吁更多的小度生态合作伙伴与我们一同，'
        + '为保障用户对数据处理的知情权、对设备操作的可控权尽最大努力，'
        + '更好保护用户隐私安全，促进小度生态健康发展。</p></div>',
    },
    {
        tags: '小度隐私外部认证及荣誉',
        key: '  ',
        content: '<h1>小度隐私外部认证及荣誉</h1>'
        + '<div class="one"><p>小度始终坚持将安全和隐私保护作为经营的重要核心，'
        + '从组织建设、产品设计、技术发展和生态搭建等多维度贯彻隐私保护价值观。'
        + '在“以用户中心”的驱动下，建立了全生态、全周期、全流程的立体隐私保护管理体系，'
        + '通过“从设计入手保护隐私”和“默认隐私保护”将隐私保护理念嵌入到产品的研发和运营中，'
        + '不断加大对安全和隐私的投入并尝试使用更多创新的技术和方法来保护隐私。</p></div>'
        + '<p>1．2022年6月，小度手表语音助手入选中国信息通信研究院2022年【护童计划】APP儿童个人信息保护优秀案例。</p>'
        + '<p>2．2022年6月，通过等级保护三级。</p>'
        + '<p>3．2022年5月，小度智能音箱红外智控版在中国软件评测中心2022年智能音箱安全测评活动中，'
        + '通过终端、App、通信及用户个人信息4大维度近50项安全检测，'
        + '荣获“智能音箱安全测评优秀产品证书”。</p>'
        + '<p>4．2022年5月，小度获得由国际权威认证机构BSI授予的ISO/IEC27001信息安全管理体系认证。</p>'
        + '<p>5．2022年5月，小度获得由国际权威认证机构BSI授予的ISO/IEC27701隐私信息管理体系认证。</p>'
        + '<p>6．2022年5月，小度获得由国际权威认证机构BSI授予的ISO/IEC27018公有云个人信息保护管理体系认证。</p>'
        + '<p>7．2021年10月，参与《信息安全技术人脸识别数据安全要求》试点工作。</p>'
        + '<p>8．2021年4月，参与《个人信息安全规范》试点工作。</p>',
    },
];

import React from 'react';

import {NavLink} from 'react-router-dom';

interface Props {
    configs: $routerConfigsType;
}

function NavLinkWrapper({configs}: Props) {
    return configs.map(item =>
        // 根据isNotNavItem确定是不是头部导航栏，有些页面属于某个页面的下属页面，不存在导航。
        (item.isNotNavItem ? null : (
            <li key={item.path}>
                <NavLink to={item.path} end={item.exact}>
                    {item.title}
                </NavLink>
            </li>
        ))
    );
}
export default React.memo(NavLinkWrapper);

import oneBgImg from '@/assets/img/img_anli1_386.png';
import oneHoverBgImg from '@/assets/img/img_anli1hover_386.png';
import towBgImg from '@/assets/img/img_anli2_386.png';
import towHoverBgImg from '@/assets/img/img_anli2hover_386.png';
import threeBgImg from '@/assets/img/img_anli3_386.png';
import threeHoverBgImg from '@/assets/img/img_anli3hover_386.png';
import fourBgImg from '@/assets/img/img_anli4_386.png';
import fourHoverBgImg from '@/assets/img/img_anli4hover_386.png';
import fiveBgImg from '@/assets/img/img_anli5_386.png';
import fiveHoverBgImg from '@/assets/img/img_anli5hover_386.png';
import sixBgImg from '@/assets/img/img_anli6_386.png';
import sixHoverBgImg from '@/assets/img/img_anli6hover_386.png';

export default [
    {
        title: (
            <>
                移动互联网应用服务能力
                <br />
                提升优秀案例
            </>
        ),
        secondTitle: (
            <>
                全流程合规管理实践探索：
                <br />
                移动应用全生命周期隐私与安全管控
            </>
        ),
        description:
            '第四期移动互联网APP产品安全漏洞技术沙龙，会上公示了“2023年度移动互联网APP产品安全漏洞治理十大优秀案例”，'
            + '百度安全旗下“百度移动APP全生命周期漏洞治理平台”入选并获颁证书。充分体现了百度在移动互联网应用服务能力的提升。',
        bgImg: fourBgImg,
        hoverImg: fourHoverBgImg,
    },
    {
        title: (
            <>
                大数据“星河”数据要素流
                <br />
                通专项优秀案例
            </>
        ),
        secondTitle: (
            <>
                隐私计算技术在贵州烟草商业供应链
                <br />
                金融数字化研究与应用
            </>
        ),
        description:
            '百度「隐私计算技术在贵州烟草商业供应链金融数字化研究与应用」案例作为首家烟草行业供应链数据要素流通案例，'
            + '从累计706份申报项目中脱颖而出，荣获2023大数据“星河”案例优秀案例，也是连续第三年斩获大数据“星河”案例的殊荣。',
        bgImg: fiveBgImg,
        hoverImg: fiveHoverBgImg,
    },
    {
        title: (
            <>
                移动互联网APP产品安全
                <br />
                漏洞治理优秀案例
            </>
        ),
        secondTitle: '百度移动APP全生命周期漏洞治理平台',
        description:
            '中国软件评测中心展开2022年度网络和数据安全优秀案例征集评选活动，经过多轮严格评审，百度移动端人脸识别安全治理方案成功'
            + '入选“移动互联网APP产品安全漏洞治理”优秀案例。 充分体现了百度在移动互联网APP产品安全漏洞的优秀治理能力。',
        bgImg: sixBgImg,
        hoverImg: sixHoverBgImg,
    },
    {
        title: (
            <>
                2023可信AI案例知识计算
                <br />
                应用优秀案例
            </>
        ),
        secondTitle: '移网跨域智能排障知识图谱',
        description:
            '百度、中国联通、诺基亚贝尔联合申报的“移网跨域智能排障知识图谱案例” 成功入选“2023可信AI案例知识计算应用优秀案例”，在创新性和实践应用上获得了评选组的认可。'
            + '本次获奖，充分体现了百度在知识计算方向的技术创新优势及行业落地实践能力。',
        bgImg: oneBgImg,
        hoverImg: oneHoverBgImg,
    },
    {
        title: (
            <>
                2023数据安全“星熠”
                <br />
                隐私计算技术应用优秀案例
            </>
        ),
        secondTitle: '小度端云一体数据安全解决方案',
        description:
            '2023年3月3日，由中国信息通信研究院安全研究所主办，大数据应用与安全创新实验室承办的首届“数据安全共同体计划成员大会”顺利在京举行。'
            + '与会，由百度安全与小度联合共创的“小度端云一体数据安全解决方案”荣膺数据安全“星熠”征集活动优秀案例。',
        bgImg: towBgImg,
        hoverImg: towHoverBgImg,
    },
    {
        title: (
            <>
                2023数据安全“星熠”
                <br />
                数据安全技术与产品应用优秀案例
            </>
        ),
        secondTitle: '小度语音智慧酒店建设中的实践应用',
        description:
            '2023年3月3日，由中国信息通信研究院安全研究所主办，大数据应用与安全创新实验室承办的首届“数据安全共同体计划成员大会”顺利在京举行。'
            + '与会，由百度安全与小度联合共创的“小度语音智慧酒店建设中的数据安全技术与产品实践应用”荣膺数据安全“星熠”征集活动优秀案例。',
        bgImg: threeBgImg,
        hoverImg: threeHoverBgImg,
    },
    {
        title: (
            <>
                大数据星河案例
                <br />
                「隐私计算标杆案例」
            </>
        ),
        secondTitle: '百度点石在生物医疗领域的创新应用案例',
        description:
            '科研机构通过构建安全可信的生物计算环境，在机密环境下完成序列比对工作，避免比对和共享的过程中出现数据缓存、数据泄露的风险。',
        bgImg: fourBgImg,
        hoverImg: fourHoverBgImg,
    },
    {
        title: (
            <>
                大数据星河案例
                <br />
                「隐私计算优秀案例」
            </>
        ),
        secondTitle: '百度点石在政务公共数据开放场景的创新应用案例',
        description:
            '实现公共数据安全开放，吸引科研团队落地，促进产业转型，帮助转化公共数据价值的同时无需担心数据安全问题。',
        bgImg: fiveBgImg,
        hoverImg: fiveHoverBgImg,
    },
    {
        title: (
            <>
                2021年企业数字化治理
                <br />
                先锋实践案例
            </>
        ),
        secondTitle: '智能安全与隐私合规类的“最具价值应用案例”',
        description:
            '中国信通院围绕“科技赋能企业治理，治理护航数字未来”的主题组织开展了“2021企业数字化治理先锋实践案例征集活动”。'
            + '经专家评审，百度安全史宾格安全及隐私合规平台凭借其技术领先性、产品成熟度与客户效益等方面的卓越表现成功入选。',
        bgImg: sixBgImg,
        hoverImg: sixHoverBgImg,
    },
];

import React from 'react';

import Card from '@/components/Card';
import TagsButton from '@/components/TagsButton';

import useTagsCheck from '@/hook/useTagsCheck';

import tagsConfig from './tags';
import c from './index.less';

export default function Join() {
    const [currentTag, onMouseHandler, selectClassNameFn] = useTagsCheck(tagsConfig, c.selected);

    return (
        <Card
            className={c.card}
            title="我们参与的行业联盟组织"
            description={
                <>
                    百度深度参与了包括人工智能安全、数据安全、个人信息保护等领域在内的数十家生态联盟，
                    <br />
                    在不断升级自身安全能力的同时，积极助力行业安全生态建设。
                </>
            }
        >
            <div className={c.contentContainer}>
                <div className={c.positionBox}>
                    <div className={c.leftContainer}>
                        <div className={c.tagsBtnBox} onMouseDown={onMouseHandler}>
                            <TagsButton
                                className={c.tagsBtn}
                                contentConfig={tagsConfig}
                                selectClassNameFn={selectClassNameFn}
                            />
                        </div>
                    </div>
                    <div
                        className={c.rightContainer}
                        style={{backgroundImage: `url(${currentTag.content.bgImg})`}}
                    >
                        <div className={c.titleBox}>
                            {currentTag.content.icon && <i
                                className={c.icon}
                                style={{backgroundImage: `url(${currentTag.content.icon})`}}
                            />}
                            <h1 className={c.title}>{currentTag.tags}</h1>
                        </div>
                        <div className={c.descriptionBox}>
                            <p className={c.description}>{currentTag.content.description}</p>
                            {currentTag.content.link ? (
                                <a
                                    className={c.lookMore}
                                    href={currentTag.content.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    了解更多
                                </a>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}

import React from 'react';

import PrivacyPolicyContent from './PrivacyPolicyContent';
import AppPrivacyPolicy from './AppPrivacyPolicy';

export default function PrivacyPolicy() {
    return (
        <>
            <PrivacyPolicyContent />
            <AppPrivacyPolicy />
        </>
    );
}

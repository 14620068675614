import React from 'react';

import Card from '@/components/Card';
import TagsButton from '@/components/TagsButton';

import useTagsCheck from '@/hook/useTagsCheck';

import tagsConfig from './tags';
import c from './index.less';

export default function SafeApprove() {
    const [currentTags, onMouseEventHandler, selectClassNameFn] = useTagsCheck(
        tagsConfig,
        c.selected
    );

    return (
        <Card
            className={c.card}
            title="安全认证"
            description={
                <span style={{whiteSpace: 'nowrap'}}>
                    百度产品、平台及服务严格遵循法律法规和标准要求，建立体系化信息安全管理框架，并通过独立的第三方权威认证机构的审核和监督，
                    <br />
                    提升企业的竞争力和信誉度。
                </span>
            }
        >
            <div className={c.contentContainer}>
                <div className={c.tagsBtnContainer} onMouseDown={onMouseEventHandler}>
                    <TagsButton
                        className={c.tagsBtn}
                        contentConfig={tagsConfig}
                        selectClassNameFn={selectClassNameFn}
                    />
                </div>
                <div
                    className={c('tagsContentContainer', {
                        showApproveIcon: currentTags.showApproveIcon,
                    })}
                >
                    <div className={c.titleIconBox}>
                        <p className={c.title}>{currentTags.tags}</p>
                        <i
                            className={c.icon}
                            style={{
                                display: currentTags.icon ? 'inline-block' : 'none',
                                backgroundImage: `url(${currentTags.icon})`,
                                ...currentTags.iconStyle,
                            }}
                        />
                    </div>
                    <div className={c.descriptionBox}>
                        <p className={c.tips}>认证简介</p>
                        <p className={c.description}>{currentTags.content}</p>
                    </div>
                </div>
            </div>
        </Card>
    );
}

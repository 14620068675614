import React, {Fragment, MouseEvent, useCallback, useMemo, useRef, useState} from 'react';

import './index.global.less';
import c from './index.less';

interface configItem {
    tags: string;
    key: string;
    content: string;
    icon?: string | React.ReactNode;
    children?: configItem[];
}

interface Props {
    treeData: {
        title: string;
        date: string;
        configs: configItem[];
    };
}

function flattenTree(tree: configItem[]) {
    const result: configItem[] = [];

    function flattenNode(node: configItem) {
        result.push(node);
        if (node.children && node.children.length > 0) {
            node.children.forEach(flattenNode);
        }
    }

    tree.forEach(flattenNode);
    return result;
}

function isElementInViewport(element: HTMLElement) {
    const scrollContainer = document.querySelector('.treeContainer');
    const containerRect = scrollContainer!.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    return elementRect.top >= containerRect.top && elementRect.bottom <= containerRect.bottom;
}

export default function Tree({treeData: {configs}}: Props) {
    const [activeKey, setActiveKey] = useState('');
    const [switcherOpenKeys, setSwitcherOpenKeys] = useState<string[]>([]);
    const [isScroll, setIsScroll] = useState<boolean>(true);
    const timer = useRef<ReturnType<typeof setTimeout>>();

    const flatConfigs = useMemo(
        () => flattenTree(configs),
        [configs]
    );

    const needOpenFather = (needActiveKey: string) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            needActiveKey.split('.').reduce((pre, item) => {
                const fatherKey = pre ? pre + item : item + '.';
                const father = flatConfigs.find(item => item.key === fatherKey);
                setSwitcherOpenKeys(state => {
                    const cacheState = [...state];
                    if (
                        !cacheState.includes(fatherKey)
                        && father?.children
                        && father.children.length
                    ) {
                        cacheState.push(fatherKey);
                    }
                    return cacheState;
                });
                return fatherKey;
            }, '');
            clearTimeout(timer.current);
        }, 200);
    };

    const scrollHandler = (e: any) => {
        const scroll = e.currentTarget.scrollTop;
        const scrollHeight = e.currentTarget.scrollHeight;
        const clientHeight = e.currentTarget.clientHeight;
        !isScroll && setIsScroll(true);

        const arr = flatConfigs.map(item => {
            const contentItem = document.getElementById(`${item.key}`);
            return Math.abs(scroll - (contentItem?.offsetTop || 0) + 330);
        });
        const min = Math.min(...arr);

        let needActiveKey = '';

        if (scrollHeight - clientHeight - scroll <= 50) {
            needActiveKey = flatConfigs[flatConfigs.length - 1].key;
        } else if (min < 200) {
            needActiveKey = flatConfigs[arr.indexOf(min)].key;
        }

        if (needActiveKey) {
            const menu = document.getElementById(`${needActiveKey}_menu`);
            needOpenFather(needActiveKey);
            if (menu && isScroll) {
                if (menu.parentNode && !isElementInViewport(menu)) {
                    (menu.parentNode as any).scrollTop = menu.offsetTop - 330;
                }
            }
            setActiveKey(needActiveKey);
        }
    };

    const onMouseDown = useCallback(
        (e: MouseEvent) => {
            setIsScroll(false);
            const activeKey = e.currentTarget.getAttribute('data-key');
            if (activeKey) {
                setActiveKey(activeKey);
                const targetDiv = document.getElementById(`${activeKey}`);
                if (targetDiv) {
                    targetDiv.parentNode
                        && ((targetDiv.parentNode as any).scrollTop = targetDiv.offsetTop - 330);
                }
            }
        },
        [setActiveKey]
    );

    const onDownIconClick = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            const activeKey = e.currentTarget.getAttribute('data-key');
            setSwitcherOpenKeys(state => {
                const cacheState = [...state];
                if (activeKey) {
                    const index = cacheState.indexOf(activeKey);
                    if (index >= 0) {
                        // 如果字符串已存在，就从数组中删除它
                        cacheState.splice(index, 1);
                    } else {
                        // 如果字符串不存在，就添加它到数组中
                        cacheState.push(activeKey);
                    }
                }
                return cacheState;
            });
        },
        [setSwitcherOpenKeys]
    );

    const renderItem = useCallback(
        (item: configItem, level: number, isLeaf: boolean) => {
            const isDropDown = switcherOpenKeys.includes(item.key);
            const isSelected = activeKey === item.key;
            return (
                item.tags && (
                    <div
                        id={`${item.key}_menu`}
                        key={item.key}
                        className={c('treeNode', {
                            switchNode: !isLeaf,
                            select: isSelected,
                        })}
                        data-key={item.key}
                        onClick={onMouseDown}
                    >
                        <span className={c.identBox}>
                            {Array.from({length: level}, (_, index) => {
                                const identKey = `ident_${item.key}_${index}`;
                                return <span key={identKey} className={c.ident}></span>;
                            })}
                        </span>
                        <span
                            data-key={item.key}
                            className={c('icon', {dropDownIcon: isDropDown})}
                            onClick={onDownIconClick}
                            style={item.icon ? {backgroundImage: `url(${item.icon})`} : undefined}
                        />
                        <span className={c.tags}>{item.key + ' ' + item.tags}</span>
                    </div>
                )
            );
        },
        [activeKey, switcherOpenKeys, onDownIconClick, onMouseDown]
    );

    const menuRender = useCallback(
        (config: configItem[], level: number): React.ReactNode => {
            return config.map(item => {
                if (item.children && item.children.length) {
                    const isOpen = switcherOpenKeys.includes(item.key);
                    return (
                        <Fragment key={item.key}>
                            {renderItem(item, level, false)}
                            {isOpen && menuRender(item.children, level + 1)}
                        </Fragment>
                    );
                } else {
                    return renderItem(item, level, true);
                }
            });
        },
        [renderItem]
    );

    const renderContentItem = (item: configItem, level: number) => {
        return (
            <div
                id={item.key}
                // bca-disable-line
                dangerouslySetInnerHTML={{__html: item.content}}
                style={{paddingLeft: `${level * 0.2}rem`}}
            />
        );
    };

    const contentRender = useCallback(
        (configs: configItem[], level: number) => {
            return configs.map(item => {
                if (item.children && item.children.length) {
                    return (
                        <Fragment key={item.key}>
                            {renderContentItem(item, level)}
                            {contentRender(item.children, level + 1)}
                        </Fragment>
                    );
                } else {
                    return renderContentItem(item, level);
                }
            });
        },
        []
    );

    return (
        <div className={c.root}>
            <div className={`treeContainer ${c.treeContainer}`}>{menuRender(configs, 0)}</div>
            <div className={`whiteBookContentContainer ${c.contentContainer}`} onScroll={scrollHandler}>
                {contentRender(configs, 0)}
            </div>
        </div>
    );
}

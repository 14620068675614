// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".report-content-card-c36ab{background-color:#fff;padding-bottom:1.2rem;padding-top:1rem}.report-content-banner-common-text-21751{color:#fff}.report-content-banner-common-text-21751 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.report-content-banner-common-text-21751 p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "report-content-card-c36ab",
	"banner-common-text": "report-content-banner-common-text-21751",
	"bannerCommonText": "report-content-banner-common-text-21751"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

import Banner from '@/components/Banner';

import yinsitoumingImg from '@/assets/img/banner_yinsitouming_1920.png';

export default function PrivacyTransBanner() {
    return (
        <Banner
            imgSrc={yinsitoumingImg}
            title="隐私"
            text={
                <>
                    以用户可理解的方式明确个人信息处理规则，承担企业责任，
                    <br />
                    同时为用户提供简单实用的隐私实现方案。
                </>
            }
        />
    );
}

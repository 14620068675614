import React, {useEffect, useMemo, useRef} from 'react';
import {Outlet} from 'react-router-dom';

import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default function BaseLayout() {
    const timer = useRef<ReturnType<typeof setTimeout>>();
    const baseRootRef = useRef<HTMLDivElement>(null);

    const isPC = useMemo(
        () => {
        // 判断是否为PC端
            if (
                /(iPhone|iPod|Android|ios|Mobi)/i.exec(navigator.userAgent)
            || window.innerWidth <= 768
            || document.documentElement.clientWidth <= 768
            ) {
                return false;
            } else {
                return true;
            }
        },
        []
    );

    const triggerScale = () => {
        const targetX = 1200;
        // const targetY = 1080;

        const currentX =
            document.documentElement.clientWidth || document.body.clientWidth;

        if (document.documentElement.clientWidth <= 1200 && !isPC) {
            const ratioX = currentX / targetX;

            baseRootRef.current
                && baseRootRef.current.setAttribute(
                    'style',
                    `transform:scale(${ratioX});
                    transform-origin: left top;`
                );
        } else if (baseRootRef.current && baseRootRef.current.style.transform) {
            baseRootRef.current.style.transform = '';
        }
    };

    const throttle = (fn: () => void, num: number) => {
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
            timer.current = setTimeout(() => {
                fn();
                clearTimeout(timer.current);
            }, num);
        };
    };

    useEffect(
        () => {
            const throttledScale = throttle(triggerScale, 400);
            window.addEventListener('resize', throttledScale);
            throttledScale();

            return () => {
                window.removeEventListener('resize', throttledScale);
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <div id="App" ref={baseRootRef}>
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
}

import React from 'react';

import useTagsCheck from '@/hook/useTagsCheck';
import TagsButton from '@/components/TagsButton';
import Card from '@/components/Card';

import zhiduIconImg from '@/assets/img/icon_zhidu_Normal_40.png';
import zhiduPressedIconImg from '@/assets/img/icon_zhidu_pressed_40.png';
import zuzhiIconImg from '@/assets/img/icon_zuzhi_Normal_40.png';
import zuzhiPressedIconImg from '@/assets/img/icon_zuzhi_pressed_40.png';
import guanliIconImg from '@/assets/img/icon_guanli_normal_40.png';
import guanliPressedIconImg from '@/assets/img/icon_guanli_pressed_40.png';
import zhiduImg from '@/assets/img/img_zhidu_800.png';
import zuzhiImg from '@/assets/img/img_zuzhi_800.png';
import guanliImg from '@/assets/img/img_guanli_800.png';

import c from './index.less';

const contentConfig = [
    {
        tags: '制度层面',
        icon: zhiduIconImg,
        pressedIcon: zhiduPressedIconImg,
        content: {
            title: '制度层面',
            text: '百度建立了完善的安全制度体系以及严格的制度评审、发布和修订流程，覆盖安全红线、办公红线、基础安全、数据安全与隐私保护、产品安全、第三方安全合作和安全管理等领域。',
            img: zhiduImg,
        },
    },
    {
        tags: '组织层面',
        icon: zuzhiIconImg,
        pressedIcon: zuzhiPressedIconImg,
        content: {
            title: '组织层面',
            text: '百度数据管理委员会作为顶层管理与决策机构，与建设层和运营层共同积极维护百度、员工、用户、客户与生态伙伴安全权益，确保百度所有产品及服务遵循适用的网络安全、数据安全与个人信息保护相关法律法规。',
            img: zuzhiImg,
        },
    },
    {
        tags: '管理层面',
        icon: guanliIconImg,
        pressedIcon: guanliPressedIconImg,
        content: {
            title: '管理层面',
            text: '百度建立“三道防线”网络数据安全风险管控模型，包括基础防守的第一道防线，制度、能力和风险专项的第二道防线，稽核和内部审计的第三道防线，将传统的风险防范模式升级为主动的风险管理模式。',
            img: guanliImg,
        },
    },
];

function SafeManagement() {
    const [currentTags, tagsOnClick, selectClassNameFn] = useTagsCheck(
        contentConfig,
        c.tagsSelectedBtn
    );

    return (
        <Card title="安全管理" className={c.card}>
            <div className={c.safeTagsContainer} onMouseDown={tagsOnClick}>
                <TagsButton
                    className={c.tagsBtn}
                    contentConfig={contentConfig}
                    selectClassNameFn={selectClassNameFn}
                />
            </div>
            <div className={c.safeContentContainer}>
                <div className={c.leftContentBox}>
                    <div>
                        <h1>{currentTags.content.title}</h1>
                        <p>{currentTags.content.text}</p>
                    </div>
                </div>
                <div className={c.rightContentBox}>
                    <img src={currentTags.content.img} alt="层级图" />
                </div>
            </div>
        </Card>
    );
}
export default React.memo(SafeManagement);

import React from 'react';

import Card from '@/components/Card';

import c from './index.less';

export default function PrivacyPolicy() {
    return (
        <Card className={c.card} title={<span style={{color: '#fff'}}>隐私政策</span>}>
            <div className={c.contentContainer}>
                <p className={c.text}>
                    我们通过《百度隐私政策总则》、《儿童个人信息保护规则》及各产品单独隐私政策向您告知百度对于个人信息的处理情况及保护规则。
                    您可通过该分栏点击进入，并能够在加粗字体提示下阅读重要条款，
                    更好地了解百度如何收集、使用及保护您的个人信息。
                </p>
                <div className={c.lookBtnContainer}>
                    <a className={c.btn} href="/policy/PrivacyPolicy" target="_blank">
                        查看《百度隐私政策总则》
                    </a>
                    <a className={c.btn} href="/policy/children-privacy-policy/child" target="_blank">
                        查看《儿童个人信息保护声明》
                    </a>
                </div>
            </div>
        </Card>
    );
}

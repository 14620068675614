// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quotes-bg-card-textBox-24ddd{flex:1;padding:.4rem .75rem .4rem 0}.quotes-bg-card-textBox-24ddd .quotes-bg-card-backgroundImgBox-3285c{background-position:.6rem;background-position:0 0,100% 100%;background-repeat:no-repeat;background-size:.75rem;height:100%;padding-top:1.02rem}.quotes-bg-card-banner-common-text-fa871{color:#fff}.quotes-bg-card-banner-common-text-fa871 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.quotes-bg-card-banner-common-text-fa871 p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textBox": "quotes-bg-card-textBox-24ddd",
	"backgroundImgBox": "quotes-bg-card-backgroundImgBox-3285c",
	"banner-common-text": "quotes-bg-card-banner-common-text-fa871",
	"bannerCommonText": "quotes-bg-card-banner-common-text-fa871"
};
export default ___CSS_LOADER_EXPORT___;

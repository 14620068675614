const aboutLink = [
    {
        label: '百度安全',
        link: 'https://anquan.baidu.com/',
    },
    {
        label: '百度用户服务中心',
        link: 'https://help.baidu.com/personalinformation',
    },
    {
        label: '百度安全应急响应中心',
        link: 'https://bsrc.baidu.com/v2/#/home',
    },
    {
        label: '百度信誉',
        link: 'https://trust.baidu.com/?fl=1&castk=LTE%3D',
    },
    {
        label: '百度智能云',
        link: 'https://cloud.baidu.com/',
    },
];

const commonLink = [
    {
        label: '关于我们',
        link: 'https://home.baidu.com/',
    },
    {
        label: '京公网安备11000002000001号',
        link: 'https://beian.miit.gov.cn/#/Integrated/index',
    },
    {
        label: '京ICP证030173号',
        link: 'https://beian.miit.gov.cn/#/Integrated/index',
    },
    {
        label: '@2024 Baidu 使用百度前必读',
        link: 'https://www.baidu.com/duty/',
    },
];

export {aboutLink, commonLink};

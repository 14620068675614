import React from 'react';

import Card from '@/components/Card';
import QuotesBgCard from '@/components/QuotesBgCard';

import shangyinhaoImg from '@/assets/img/bg_shangyinhao3_75.png';
import xiayinhaoImg from '@/assets/img/bg_xiayinhao3_75.png';
import liuchengImg from '@/assets/img/img_liucheng_655.png';

import c from './index.less';

const text =
    '百度已制定严格清晰的应急响应流程规范制度，'
    + '明确各类安全问题的相关处理角色及职责，'
    + '配备24小时全天候安全应急响应值班团队，'
    + '并已建立与监管机构的联动和上报机制，'
    + '确保所有安全问题得到妥善解决，并保持信息及时反馈与报告。';

export default function SafeResponse() {
    return (
        <Card className={c.card} title="全天候安全应急响应">
            <div className={c.contentContainer}>
                <img className={c.img} src={liuchengImg} alt="安全应急事件响应流程" />
                <QuotesBgCard
                    className={c.textBox}
                    topImg={shangyinhaoImg}
                    bottomImg={xiayinhaoImg}
                    text={text}
                />
            </div>
        </Card>
    );
}

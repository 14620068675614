// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".banner-root-41583{height:4.6rem;width:100%}.banner-root-41583 .banner-card-c309d{background-position:50%;background-repeat:no-repeat;background-size:cover;height:100%;padding-bottom:1.8rem;padding-top:1.4rem}.banner-root-41583 .banner-card-c309d .banner-textContainer-b7d05{color:#fff;height:100%}.banner-root-41583 .banner-card-c309d .banner-textContainer-b7d05 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.banner-root-41583 .banner-card-c309d .banner-textContainer-b7d05 p{color:#e3e9f1;font-size:18px;line-height:30px}.banner-banner-common-text-167b3{color:#fff}.banner-banner-common-text-167b3 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.banner-banner-common-text-167b3 p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "banner-root-41583",
	"card": "banner-card-c309d",
	"textContainer": "banner-textContainer-b7d05",
	"banner-common-text": "banner-banner-common-text-167b3",
	"bannerCommonText": "banner-banner-common-text-167b3"
};
export default ___CSS_LOADER_EXPORT___;

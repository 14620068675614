import React from 'react';

import Banner from '@/components/Banner';

import heguifunengImg from '@/assets/img/banner_heguifuneng_1920.png';

export default function ComplianceBanner() {
    return (
        <Banner
            imgSrc={heguifunengImg}
            title="合规"
            text={
                <>
                    主动遵从法律法规及权威标准认证，打造适用行业及客户需求的最佳实践，
                    <br />
                    并积极加强合作赋能，共同应对安全及隐私保护挑战。
                </>
            }
        />
    );
}

import React from 'react';

import Card from '@/components/Card';
import QuotesBgCard from '@/components/QuotesBgCard';

import guanlijizhiImg from '@/assets/img/img_guanlijizhi_682.png';
import shangyinhaoImg from '@/assets/img/bg_shangyinhao2_75.png';
import xiayinhaoImg from '@/assets/img/bg_xiayinhao2_75.png';

import c from './index.less';

const text =
    '百度制定了覆盖数据全生命周期的安全与隐私保护方案，'
    + '包括数据的采集、传输、存储、使用、流通、销毁等环节。'
    + '通过将制度规范、审批流程、技术方案应用在自研产品和平台，'
    + '建立了“事前主动识别，事中灵活控制，事后全维追踪”的数据全生命周期安全管理体系。';

export default function DataLife() {
    return (
        <Card className={c.card} title="数据全生命周期安全保障" whiteTitle>
            <div className={c.contentContainer}>
                <QuotesBgCard topImg={shangyinhaoImg} bottomImg={xiayinhaoImg} text={text} />
                <img className={c.img} src={guanlijizhiImg} alt="数据全生命周期安全管理机制" />
            </div>
        </Card>
    );
}

import React from 'react';

import ContactBanner from './ContactBanner';
import FeedBack from './FeedBack';
import Address from './Address';

export default function ContactUs() {
    return (
        <>
            <ContactBanner />
            <FeedBack />
            <Address />
        </>
    );
}

import React from 'react';

import ScrollTab from '@/components/ScrollTab';

import Card from '@/components/Card';
import contentConfig from './contentConfig';

import c from './index.less';
import './index.global.less';

export default function ReportContent() {
    return (
        <Card title="百度个人信息保护社会责任报告" className={c.card}>
            <div id="SocialResponsibilityReport">
                <ScrollTab contentConfig={contentConfig} needUlList>
                    <div className="item">
                        <h2>
                            {'百度的使命是用科技让复杂的世界更简单。我们希望通过不断地研发新技术、推出新服务，以产品和业务不断提升的智能化推动您的生活更加高效、更加便利。'
                            + '百度的愿景是成为最懂用户，并能帮助人们成长的全球顶级高科技公司。我们希望以多年的技术积累和持续创新的产品，为用户、客户、伙伴提供最贴心的服务；'
                            + '我们将一如既往的重视用户的隐私保护，积极践行个人信息保护社会责任。'}
                        </h2>
                    </div>
                </ScrollTab>
            </div>
        </Card>
    );
}

import React, {useEffect, useMemo, useState} from 'react';

import Card from '@/components/Card';
import TagsButton from '@/components/TagsButton';
import useTagsCheck from '@/hook/useTagsCheck';

import tagsConfig from './tags';

import c from './index.less';

export default function PrivacyManagement() {
    const [currentTags, onMouseEvent, selectClassNameFn] = useTagsCheck(tagsConfig, c.oneSelected);
    const [secondCurrentTags, onSecondMouseEvent, secondSelectClassNameFn] = useTagsCheck(
        currentTags.content as $tagsConfigType,
        c.secondTagsSelected
    );
    const [currentImgIndex, setCurrentImgIndex] = useState(0);

    const RCheckIconCanDisable = useMemo(
        () => currentImgIndex === secondCurrentTags.imgs.length - 1,
        [currentImgIndex, secondCurrentTags]
    );

    const leftAndRCheckClick = (RorL: string) => {
        if (RorL === 'L') {
            currentImgIndex && setCurrentImgIndex(state => state - 1);
        } else if (RorL === 'R') {
            !RCheckIconCanDisable && setCurrentImgIndex(state => state + 1);
        }
    };

    useEffect(
        () => {
            setCurrentImgIndex(0);
        },
        [secondCurrentTags]
    );
    return (
        <Card
            title="隐私管理"
            className={c.card}
            description={
                <>
                    百度希望通过产品的隐私管理设计让用户感受到“信息被保护、意愿被尊重、服务有价值”，
                    <br />
                    致力于将隐私保护理念融入产品和服务开发的各个环节，打造更加自主、合规、透明的产品隐私保护功能。
                </>
            }
        >
            <div className={c.contentContainer}>
                <div className={c.contentBox}>
                    <div className={c.oneTagsBtnBox} onMouseDown={onMouseEvent}>
                        <TagsButton
                            className={c.tagsBtn}
                            contentConfig={tagsConfig}
                            selectClassNameFn={selectClassNameFn}
                        />
                    </div>
                    <div className={c.secondContainer}>
                        <div className={c.tagsBtnContainer} onMouseDown={onSecondMouseEvent}>
                            <TagsButton
                                className={c.tagsBtn}
                                contentConfig={currentTags.content as $tagsConfigType}
                                selectClassNameFn={secondSelectClassNameFn}
                            />
                        </div>
                        <div className={c.contentImgContainer}>
                            <i
                                className={c('leftIcon', {
                                    disable: !currentImgIndex,
                                    hidden: !(secondCurrentTags?.imgs.length > 1),
                                })}
                                onClick={() => leftAndRCheckClick('L')}
                            />
                            <div className={c.imgBox}>
                                <div className={c.phoneBox}>
                                    <div
                                        className={c.sliderBox}
                                        style={{
                                            transform: `translateX(-${
                                                currentImgIndex * 360
                                            }px)`,
                                        }}
                                    >
                                        {(secondCurrentTags.imgs as string[]).map((item, index) => (
                                            <div
                                                className={c.img}
                                                style={{
                                                    height: currentImgIndex === index ? 'auto' : '0',
                                                }}
                                                key={item}
                                            >
                                                <img src={item} alt={item} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className={c.pageIconBox}>
                                    {secondCurrentTags.imgs.length > 1
                                        && (secondCurrentTags.imgs as string[]).map((item, index) => (
                                            <i
                                                key={item}
                                                className={c('pageIcon', {
                                                    active: currentImgIndex === index,
                                                })}
                                            />
                                        ))}
                                </div>
                            </div>
                            <i
                                className={c('rightIcon', {
                                    disable: RCheckIconCanDisable,
                                    hidden: !(secondCurrentTags?.imgs.length > 1),
                                })}
                                onClick={() => leftAndRCheckClick('R')}
                            />
                        </div>
                        <div className={c.rightTextContainer}>
                            <div className={c.iconBox}>
                                <img
                                    className={c.iconImg}
                                    src={currentTags.icon}
                                    alt={currentTags.tags}
                                />
                                <h1 className={c.name}>{currentTags.tags}</h1>
                            </div>
                            <p
                                className={c.description}
                                // bca-disable-line
                                dangerouslySetInnerHTML={{__html: secondCurrentTags.text}}
                            />
                            {secondCurrentTags.isLongImg?.includes(currentImgIndex) && (
                                <span className={c.tips}>提示：滑动长图查看更多内容。</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}

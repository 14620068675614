import React, {CSSProperties} from 'react';

import Card from '../Card';

import c from './index.less';

interface Props {
    imgSrc: string;
    className?: string;
    children?: React.ReactNode;
    title?: string;
    text?: string | React.ReactNode;
    cardStyle?: CSSProperties;
}

export default function Banner({
    imgSrc,
    className = '',
    children,
    title = '',
    text = '',
    cardStyle = {},
}: Props) {
    return (
        <div className={`${c.root} ${className}`}>
            <Card className={c.card} style={{...cardStyle, backgroundImage: `url(${imgSrc})`}}>
                {children ? (
                    children
                ) : (
                    <div className={c.textContainer}>
                        <h1 className={c.title}>{title}</h1>
                        <p className={c.text}>{text}</p>
                    </div>
                )}
            </Card>
        </div>
    );
}

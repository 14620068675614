// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-privacy-policy-card-b08d9 .app-privacy-policy-contentContainer-a2c45{align-items:flex-start;display:flex;flex-wrap:wrap;gap:.6rem 1.5rem;justify-content:center;width:100%}.app-privacy-policy-card-b08d9 .app-privacy-policy-contentContainer-a2c45 .app-privacy-policy-itemBox-5c57f{align-items:center;display:flex;flex-direction:column;justify-content:flex-start;width:1rem}.app-privacy-policy-card-b08d9 .app-privacy-policy-contentContainer-a2c45 .app-privacy-policy-itemBox-5c57f .app-privacy-policy-img-4dd10{border-radius:.2rem;box-shadow:0 .1rem .2rem 0 rgba(0,0,0,.2);height:.8rem;width:.8rem}.app-privacy-policy-card-b08d9 .app-privacy-policy-contentContainer-a2c45 .app-privacy-policy-itemBox-5c57f .app-privacy-policy-title-c8653{color:#000;font-size:20px;font-weight:500;margin-top:.2rem;text-align:center}.app-privacy-policy-banner-common-text-7f4ea{color:#fff}.app-privacy-policy-banner-common-text-7f4ea h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.app-privacy-policy-banner-common-text-7f4ea p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "app-privacy-policy-card-b08d9",
	"contentContainer": "app-privacy-policy-contentContainer-a2c45",
	"itemBox": "app-privacy-policy-itemBox-5c57f",
	"img": "app-privacy-policy-img-4dd10",
	"title": "app-privacy-policy-title-c8653",
	"banner-common-text": "app-privacy-policy-banner-common-text-7f4ea",
	"bannerCommonText": "app-privacy-policy-banner-common-text-7f4ea"
};
export default ___CSS_LOADER_EXPORT___;

import tongyiIcon from '@/assets/img/icon_tongyi_pressed_40.png';
import tongyiPressedIcon from '@/assets/img/icon_tongyi_normal-hover_40.png';
import gouyongIcon from '@/assets/img/icon_gouyong_pressed_40.png';
import gouyongPressedIcon from '@/assets/img/icon_gouyong_normal-hover_40.png';
import tiyanIcon from '@/assets/img/icon_tiyan_pressed_40.png';
import tiyanPressedIcon from '@/assets/img/icon_tiyan_normal-hover_40.png';
import baozhangIcon from '@/assets/img/icon_baozhang_pressed_40.png';
import baozhangPressedIcon from '@/assets/img/icon_baozhang_normal-hover_40.png';

import tongyiImg from '@/assets/img/img_tongyi_760.png';
import gouyongImg from '@/assets/img/img_gouyong_760.png';
import tiyanImg from '@/assets/img/img_tiyan_760.png';
import baozhangImg from '@/assets/img/img_baozhang_760.png';

export default [
    {
        tags: '知情同意',
        icon: tongyiIcon,
        pressedIcon: tongyiPressedIcon,
        content: {
            title: '知情同意',
            text: '百度依法保障用户知情权，并在用户授权范围内处理个人信息。',
            img: tongyiImg,
        },
    },
    {
        tags: '最少够用',
        icon: gouyongIcon,
        pressedIcon: gouyongPressedIcon,
        content: {
            title: '最少够用',
            text: '百度确保对个人信息的收集、使用均在必要范围内进行。',
            img: gouyongImg,
        },
    },
    {
        tags: '用户体验',
        icon: tiyanIcon,
        pressedIcon: tiyanPressedIcon,
        content: {
            title: '用户体验',
            text: '百度通过清晰、简明的产品设计，保障用户权利、优化用户体验。',
            img: tiyanImg,
        },
    },
    {
        tags: '安全保障',
        icon: baozhangIcon,
        pressedIcon: baozhangPressedIcon,
        content: {
            title: '安全保障',
            text: '百度通过管理流程和技术工具的综合应用保障用户数据安全。',
            img: baozhangImg,
        },
    },
];

import wangluokongjianIcon from '@/assets/img/img_wangluokongjianxiehui.png';
import wangluokongjianBgImg from '@/assets/img/bg_wangluokongjianxiehui.png';
// import zhongguancunIcon from '@/assets/img/img_zhongguancun.png';
// import zhongguancunBgImg from '@/assets/img/bg_zhongguancun.png';
import wangluoanquanIcon from '@/assets/img/img_wangluoanquan.png';
import wangluoanquanBgImg from '@/assets/img/bg_wangluoanquan.png';
import zhuoxinIcon from '@/assets/img/img_zhuoxin.png';
import zhuoxinBgImg from '@/assets/img/bg_zhuoxin.png';
import yinsijisuanIcon from '@/assets/img/img_yinsijisuan.png';
import yinsijisuanBgImg from '@/assets/img/bg_yinsijisuan.png';
// import cciaIcon from '@/assets/img/img_ccia.png';
// import cciaBgImg from '@/assets/img/bg_ccia.png';
import rengongzhinengImg from '@/assets/complianceEnable/bg_北京市人工智能产业联盟.png';
import quanguowangluoanquanImg from '@/assets/complianceEnable/bg_全国网络安全.png';
import quanguowangluoanquanIcon from '@/assets/complianceEnable/logo_quanguowangluoanquan.png';
import tongxingxiehuiImg from '@/assets/complianceEnable/bg_中国通信标准化协会.png';
import tongxingxiehuiIcon from '@/assets/complianceEnable/logo_zhongguotongxinbiaozhunhua.png';

export default [
    {
        tags: '中国网络空间安全协会',
        content: {
            icon: wangluokongjianIcon,
            bgImg: wangluokongjianBgImg,
            description:
                '中国网络空间安全协会（CyberSecurity Association of China，CSAC）于2016年3月25日在北京成立。'
                + '中国网络空间安全协会是由中国国内从事网络空间安全相关产业、教育、科研、应用的机构、企业及个人共同自愿结成的全国性、'
                + '行业性、非营利性社会组织，旨在发挥桥梁纽带作用，组织和动员社会各方面力量参与中国网络空间安全建设，'
                + '为会员服务、为行业服务、为国家战略服务，促进中国网络空间的安全和发展。',
            link: 'https://www.cybersac.cn/',
        },
    },
    {
        tags: '全国网络安全标准化技术委员会',
        content: {
            icon: quanguowangluoanquanIcon,
            bgImg: quanguowangluoanquanImg,
            description:
                    '全国网络安全标准化技术委员会，是在网络安全技术专业领域内从事网络安全标准化工作的技术工作组织。 '
                    + '委员会主要工作范围包括：网络安全技术、机制、服务、管理、评估等领域的标准化技术工作。',
            link: 'https://www.tc260.org.cn/',
        },
    },
    {
        tags: '中国网络安全产业联盟',
        content: {
            icon: wangluoanquanIcon,
            bgImg: wangluoanquanBgImg,
            description:
                '中国网络安全产业联盟（CCIA）联盟是由积极投身于网络安全产业发展，开展网络安全理论研究、技术研发、'
                + '产品研制、测评认证、教育培训、安全服务等相关业务的企事业单位以及用户单位自愿组成的，属于全国性非营利行业组织。',
            link: 'http://china-cia.org.cn/',
        },
    },
    {
        tags: '中国通信标准化协会',
        content: {
            icon: tongxingxiehuiIcon,
            bgImg: tongxingxiehuiImg,
            description:
                    '中国通信标准化协会是由国内从事信息通信技术及相关行业标准化的科研、技术开发、'
                    + '设计、产品制造、运营、服务和高等院校等企事业单位、社会组织自愿结成的全国性、行业性、'
                    + '非营利性社会组织。2002年12月18日，经信息产业部和国家标准化管理委员会批准，中国通信标准化协会（CCSA）成立。',
            link: 'https://www.ccsa.org.cn/',
        },
    },
    {
        tags: '北京市人工智能产业联盟',
        content: {
            bgImg: rengongzhinengImg,
            description: (
                <>
                    <span>北京人工智能产业联盟发起于2021年，2022年在北京市民政局正式完成登记注册，业务主管单位为北京市经济和信息化局。</span>
                    <br />
                    <span>
                        联盟发起成立至今，积极发挥行业组织优势特性，坚定打造人工智能产业生态运营服务体系，坚持以政策为先导、
                        市场为主导、企业为主体，立足北京市人工智能产学研用资源优势，搭建着具有国际视野的人工智能技术与产业协作平台。
                    </span>
                </>
            ),
            link: '',
        },
    },
    {
        tags: '卓信大数据计划',
        content: {
            icon: zhuoxinIcon,
            bgImg: zhuoxinBgImg,
            description:
                '“卓信大数据计划”是由中国信通院发起的聚焦于数据安全领域的政、产、学、研、法交流平台。'
                + '该计划将构建包括基础设施、检测评估、人才培训、法律咨询、数据审计、宣贯宣传的数据安全治理方面的全要素生态。',
            link: '',
        },
    },
    {
        tags: '隐私计算联盟',
        content: {
            icon: yinsijisuanIcon,
            bgImg: yinsijisuanBgImg,
            description:
                '在工业和信息化部相关司局的指导和支持下，中国信息通信研究院拟牵头成立“隐私计算联盟”，'
                + '从隐私计算核心技术研究、行业应用落地、标准建设、政策监管研究，技术普及等多个方面构建政产学研合作交流平台。'
                + '该联盟是信通院云大所牵头成立的，百度作为核心起草单位参与了云大所的多个行业标准的制定（MPC、FL、TEE、脱敏等），'
                + '另外百度的点石、联邦计算、MesaTEE、金融云度信等产品也参与了云大所组织的专业测评。',
            link: '',
        },
    },
    /* {
        tags: '中关村标准化协会',
        content: {
            icon: zhongguancunIcon,
            bgImg: zhongguancunBgImg,
            description:
                '在中关村的核心产业联盟和龙头企业的支持下，创制、发布、实施和推广高质量的“中关村标准”。',
            link: 'http://www.zgcsa.org/#/',
        },
    },
    {
        tags: 'CCIA数据安全工作委员会',
        content: {
            icon: cciaIcon,
            bgImg: cciaBgImg,
            description:
                '中国网络安全产业联盟（CCIA）组建了数据安全工作委员会，共同推动网络安全产业在数据安全方面的创新发展，'
                + '促进数据安全法律法规标准的有效落地，营造行业自律和数据合规利用的良好环境。',
            link: '',
        },
    }, */
];

import React from 'react';

import Card from '@/components/Card';

import c from './index.less';

export default function SocialResponsibility() {
    return (
        <Card title="社会责任" className={c.card}>
            <div className={c.contentContainer}>
                <div className={c.bgContainer}>
                    <div className={c.textBox}>
                        <p className={c.text}>
                            百度积极承担“守门人”的法律责任，向社会定期公布《个人信息保护社会责任报告》阐述百度对于个人信息保护的价值观与理念，以及相关工作的开展情况等，履行平台义务、接受社会监督。
                        </p>
                        <a
                            className={c.btn}
                            href="/organization/reports"
                            target="_blank"
                        >
                            查看最新《百度个人信息社会责任报告》
                        </a>
                    </div>
                </div>
            </div>
        </Card>
    );
}

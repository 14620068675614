import React from 'react';

import Card from '@/components/Card';
import quanjingImg from '@/assets/img/img_quanjing_1200.png';

import c from './index.less';

export default function SecurityCapability() {
    return (
        <Card title="百度安全能力护航" className={c.card}>
            <div className={c.contentContainer}>
                <p className={c.text}>
                    百度已采用先进的安全防护技术保障网络、系统、产品等领域安全，可有效防范网络攻击和恶意行为，
                    <br />
                    确保业务可靠安全的运行，同时采取动态数据安全保护技术并严格遵守相关法规标准要求，致力于保障用户的隐私权利。
                </p>
                <img className={c.img} src={quanjingImg} alt="百度安全技术能力全景图谱" />
            </div>
        </Card>
    );
}

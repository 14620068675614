// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".security-capability-card-7c721{background-color:#fff}.security-capability-card-7c721 .security-capability-contentContainer-c2207 .security-capability-text-fe18a{color:#000;margin-bottom:.6rem;text-align:center}.security-capability-card-7c721 .security-capability-contentContainer-c2207 .security-capability-img-d2f23{height:4.9rem}.security-capability-banner-common-text-b2ecc{color:#fff}.security-capability-banner-common-text-b2ecc h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.security-capability-banner-common-text-b2ecc p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "security-capability-card-7c721",
	"contentContainer": "security-capability-contentContainer-c2207",
	"text": "security-capability-text-fe18a",
	"img": "security-capability-img-d2f23",
	"banner-common-text": "security-capability-banner-common-text-b2ecc",
	"bannerCommonText": "security-capability-banner-common-text-b2ecc"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card-root-b6714{align-items:center;display:flex;justify-content:center;min-width:12rem;padding-bottom:1.2rem;width:100%}.card-root-b6714 .card-cardBody-0ca1e{height:-moz-fit-content;height:fit-content;width:12rem}.card-root-b6714 .card-cardBody-0ca1e .card-titleContainer-4854e{margin:1rem 0 .8rem}.card-root-b6714 .card-cardBody-0ca1e .card-titleContainer-4854e .card-titleBox-9498f{align-items:center;display:flex;justify-content:center;margin-bottom:.3rem}.card-root-b6714 .card-cardBody-0ca1e .card-titleContainer-4854e .card-titleBox-9498f .card-title-8df56{color:#000;font-size:40px;font-weight:500;line-height:50px;margin:0 .3rem}.card-root-b6714 .card-cardBody-0ca1e .card-titleContainer-4854e .card-titleBox-9498f .card-icon-b33b0{background-position:50%;background-repeat:no-repeat;background-size:70%;height:.4rem;width:.8rem}.card-root-b6714 .card-cardBody-0ca1e .card-titleContainer-4854e .card-description-fd8fd{color:#666;font-size:20px;line-height:36px;text-align:center}.card-banner-common-text-3a8b6{color:#fff}.card-banner-common-text-3a8b6 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.card-banner-common-text-3a8b6 p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "card-root-b6714",
	"cardBody": "card-cardBody-0ca1e",
	"titleContainer": "card-titleContainer-4854e",
	"titleBox": "card-titleBox-9498f",
	"title": "card-title-8df56",
	"icon": "card-icon-b33b0",
	"description": "card-description-fd8fd",
	"banner-common-text": "card-banner-common-text-3a8b6",
	"bannerCommonText": "card-banner-common-text-3a8b6"
};
export default ___CSS_LOADER_EXPORT___;

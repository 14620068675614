// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer-root-c350b{align-items:center;background-color:#000;box-sizing:border-box;display:flex;height:1.5rem;justify-content:center;padding:.4rem 0;text-align:center}.footer-root-c350b .footer-contentContainer-8d54b{width:12rem}.footer-root-c350b .footer-contentContainer-8d54b>div:first-child{margin-bottom:.1rem}.footer-root-c350b .footer-contentContainer-8d54b a{line-height:30px;margin:0 .15rem}.footer-banner-common-text-5b57b{color:#fff}.footer-banner-common-text-5b57b h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.footer-banner-common-text-5b57b p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "footer-root-c350b",
	"contentContainer": "footer-contentContainer-8d54b",
	"banner-common-text": "footer-banner-common-text-5b57b",
	"bannerCommonText": "footer-banner-common-text-5b57b"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".home-banner-textContainer-f3eb1{color:#fff;float:right;font-family:FZLTZHUNHK--GBK1-0;font-size:56px;font-weight:400;width:6.24rem}.home-banner-banner-common-text-1460b{color:#fff}.home-banner-banner-common-text-1460b h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.home-banner-banner-common-text-1460b p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textContainer": "home-banner-textContainer-f3eb1",
	"banner-common-text": "home-banner-banner-common-text-1460b",
	"bannerCommonText": "home-banner-banner-common-text-1460b"
};
export default ___CSS_LOADER_EXPORT___;

import img1 from '@/assets/yidonyingyongPDFImg/img1.png';
import img2 from '@/assets/yidonyingyongPDFImg/img2.png';
import img3 from '@/assets/yidonyingyongPDFImg/img3.png';

export default [
    {
        tags: '前言',
        key: ' ',
        content: '<h1 class="first">前言</h1>'
        + '<div class="one"><p>移动网络时代，移动互联网应用程序（下简称“应用程序”或“应用”）已然成为用户接入和使用网络服务的门户工具，'
        + '渗透用户网络生活的方方面面。为保障用户个人信息处理安全、保障用户各项合法权益，'
        + '法律法规规定文件中都对移动互联网应用程序的提出了规范要求。</p>'
        + '<p>百度移动应用平台作为移动应用程序的分发平台，一贯将用户权益保护作为业务开展的首要宗旨，'
        + '严格落实法律法规和监管部门要求，对平台内的移动互联网应用程序进行严格管理。'
        + '从开发者入驻、资质审核、应用上架检测、应用分发使用期的运营管理、安全漏洞检测等多个环节入手，'
        + '百度移动应用平台提供了全生命周期的安全运营机制，力求向用户提供安全可信赖的服务。</p>'
        + '<p>百度移动应用平台不断致力于发挥平台的价值，通过不断迭代产品功能、健全相关规程制度、'
        + '探索尝试创新设计，为用户和开发者搭建绿色、安全的交互枢纽，'
        + '促进平台多方角色参与者的共治、共享、共赢。</p></div>',
    },
    {
        tags: '应用上架审核',
        key: '1.',
        content: '<h1>1. 应用上架审核</h1>'
        + '<div class="one"><p>为切实保障终端用户的信息和使用安全，百度移动应用平台建立健全开发者入驻、'
        + '应用上架审核的相关规则，对开发者入驻和应用上架执行严格的资质审查和准入机制。</p></div>',
        children: [
            {
                tags: '开发者信息审核',
                key: '1.1',
                content: '<h2>1.1 开发者信息审核</h2>'
                + '<p>开发者通过手机号实名注册百度账号，开通账户后根据自身不同类型，如为企业开发者、个人开发者、'
                + '政府/事业单位开发者，按照当前要求提供相应的营业执照、身份证信息、服务类型等资质材料。'
                + '百度移动应用平台将核实开发者主体信息，包括开发者名称、开发者联系方式(如联系电话、联系邮箱等)与证件信息的一致性。'
                + '如发现存在开发者主体信息不一致、资质造假、开发者主体停止运营等情况，百度移动应用平台将拒绝开发者申请账号，'
                + '情节严重的禁止其再提交应用包。</p>'
                ,
            },
            {
                tags: 'APP上架审核',
                key: '1.2',
                content: '<h2>1.2APP上架审核</h2>'
                + '<p>2022年，百度移动应用平台基于系统检测和人工复测双重检验，处理了超过百万次应用上架的申请。</p>'
                + '<p>在系统检测环节，百度移动应用平台接入百度自主研发的史宾格隐私检测及病毒检测能力，对上架应用进行病毒木马扫描、隐私泄露分析，'
                + '同时进行风险APP检测等多种安全检测，利用百度先进的检测工具，移动应用平台能够更准确、高效地发现上架应用的潜在问题。</p>'
                + '<p>在人工复测环节，百度移动应用平台严格对上架应用功能、模块进行实测体验，如涉诈app检测，'
                + '人工检测团队使用多品牌(华为、三星、vivo、小米、OPPO)的终端真机设备和操作系统，实现真机、真场景复验。</p>'
                + '<p>百度移动应用平台留存在架APP相关版本、上线时间、功能简介、用途、信息摘要等必要信息，以备追溯检测。</p>'
                + '<p>基于上述一系列风控检测，百度移动应用平台严格对不合格应用予以不上架处理。'
                + '百度移动应用平台不断优化入驻和上架检测流程，致力于构建和提供安全、健康的应用供给和使用体验。</p>'
                ,
            },
        ],
    },
    {
        tags: '应用下载公示',
        key: '2.',
        content: '<h1>2. 应用下载公示</h1>'
        + '<div class="one">'
        + '<p>为向用户提供更真实、更透明的应用服务，响应监管政策要求，'
        + '百度移动应用平台要求在架开发者均应具备隐私政策，通过隐私政策详细向用户说明其个人信息收集处理的目的、方式、种类、期限等信息。'
        + '除此之外，每个应用开发者均应在应用下载页面明示APP名称、开发者名称、版本信息、隐私权限、隐私政策相关内容，进一步保障用户知情了解的诉求。</p>'
        + `<div 
                style="
                    width: 100%;
                    height: 866px;
                    background-position: center;
                    background-image:url(${img1});
                    background-repeat: no-repeat;
                "
            />`
        + '</div>'
        ,
    },
    {
        tags: '应用在架安全保护',
        key: '3.',
        content: '<div class="one">'
        + '<h1>3. 应用在架安全保护</h1>'
        + '<p>百度移动应用平台针对已上架应用，建立了APP开发运营者风险巡查机制，'
        + '采用机审加人工审核方式，每季度定期进行安全扫描、复测，对应用上架后出现的违规行为及时进行相应处置。'
        + '同时，百度移动应用平台也建立了开发者申诉处理机制，保障开发者合法权利。</p>'
        + `<img src="${img2}" alt="2" />`
        + '<p>同时，进行应用分发的百度手机助手端内提供了用户反馈渠道，与风控内部处理机制相衔接，'
        + '用户可通过百度手机助手APP、联系电话、官方邮箱等渠道投诉、举报问题应用，风控团队第一时间响应，'
        + '对各方反馈的问题应用进行验证，核实问题后及时处理。</p>'
        + '<p>2022年，百度移动应用平台总计共复测应用超百万次。'
        + '对上架时合规，但上架后涉嫌违规的问题应用进行处置，涉及应用1.6万余款。</p>'
        + `<img src="${img3}" alt="3" />`
        + '</div>',
    },
    {
        tags: '2022专项行动',
        key: '4.',
        content: '<h1>4. 2022专项行动</h1>',
        children: [
            {
                tags: '隐私专项复测',
                key: '4.1',
                content: '<h2>4.1 隐私专项复测</h2><p>自工业和信息化部于2020年发布'
                + '《工业和信息化部关于开展纵深推进APP侵害用户权益专项整治行动的通知》以来,'
                + '百度移动应用平台高度重视并严格贯彻执行,对新上架的应用按照规定严格审核,并对在架的应用定期专项复测巡检,'
                + '针对每一位开发者提供一对一隐私检测报告并通知整改,同时针对部分长期未更新应用的隐私政策没有依照新规定进行更新的情况,'
                + '百度移动应用平台及时给予下架处理,切实保障用户的个人隐私安全。</p>',
            },
            {
                tags: '游戏防沉迷专项复测',
                key: '4.2',
                content: '<h2>4.2 游戏防沉迷专项复测</h2><p>2022年7月,响应中宣部出版局指令,'
                + '百度移动应用平台全面排查游戏实名认证防沉迷情况。百度移动应用平台通过人工复测方式,累计核查上万款软件,'
                + '共计下架千余款未实名认证的软件,同时推动机器安全回扫策略更新,优化机器配置,提升安全回扫效率。</p>'
                + '<p>与此同时,百度建立了畅通的举报渠道,包括内部反馈入口、邮件组、开发者平台等多通道留言反馈方式。'
                + '百度移动应用平台及时处理,对重大高危投诉做到24小时内处理完毕，并总结归纳特点,遇到重大信息反馈,及时收集材料上报。'
                + '百度移动应用平台始终坚持维护良好的市场生态系统,切实保障网民合法权益。</p>',
            },
            {
                tags: '“清朗”专项复测',
                key: '4.3',
                content: '<h2>4.3“清朗”专项复测</h2><p>2022年，国家互联网信息办公室围绕“未成年人网络环境整治”等8个网络安全突出问题，开展“清朗”系列专项行动。'
                + '对此,百度移动应用平台主动配合相关工作,开展持续进行涉及未成年人应用的专项复测，'
                + '对可能干扰未成年人的应用进行处理，净化未成年人的网络环境。</p><p>此外，'
                + '国家互联网信息办公室开展“清朗.移动互联网应用程序领域乱象整治”专项以来,百度移动应用平台加强管理,'
                + '全面规范移动应用程序在搜索下载、使用等环节的运营行为,着力解决损害用户合法权益的突出问题，'
                + '深入治理当前各种乱象，净化网络环境，营造清朗网络空间，促进行业健康有序发展。</p>',
            },
        ],
    },
    {
        tags: '制度建设',
        key: '5.',
        content: '<h1>5. 制度建设</h1><div class="one"><p>百度移动应用平台建立了规范开发者和保障用户权益相关的平台协议、规则、公告、指引。'
        + '开发者入驻百度移动应用平台,应同意《开发者服务协议》《用户隐私保护承诺协议》,遵循有关个人信息保护和隐私保护的法律法规的要求,'
        + '尊重用户隐私,保护用户个人信息安全。在日常运营中,百度移动应用平台通过平台公告、通知、培训等形式,不断面向开发者输出规范应用开发上架隐私安全行为的知识。</p>'
        + '<p>在百度公司全面细致的个人信息合规制度的框架下,百度的应用分发服务平台百度手机助手还建立了专门的个人信息保护规则、工作流程、风控审核政策、管理规定等。'
        + '通过这些规章制度的建设和履行,应用分发业务不断提升细化对个人信息保护的认知,搭建起全方位的用户权益保护的安全操作环境。</p></div>',
    },
    {
        tags: '技术能力',
        key: '6.',
        content: '<h1>6. 技术能力</h1><div class="one"><p>百度公司开发自研的史宾格安全及隐私合规系统，该系统基于百度AI技术的动态场景识别能力,'
        + '结合深度学习图像识别和自然语言理解,在运行时实时识别APP的业务场景和特殊隐私场景，并可以智能引导APP的遍历，'
        + '利用百度NLP技术的隐私政策文本自动化理解技术，对App个人信息收集使用条款进行语义理解和分析，发现隐私政策中违规风险点，'
        + '使用沙箱技术结合云手机，动态重现用户真实使用场景，自动捕获APP运行时的隐私API调用、网络行为、文件操作、非标准API绕过等相关隐私行为，'
        + '结合以隐私场景覆盖为目标的新型算法，全面深入的遍历整个应用，有效触达APP各隐私敏感场景，触发APP的深层次隐私问题，'
        + '能够深度挖掘App隐私合规风险产生的源头。该系统的病毒检测能力，可对上架应用进行病毒木马扫描、恶意程序分析，'
        + '以及进行风险APP检测等多维度安全检测。借助自研的史宾格安全及隐私合规系统，百度助力服务每位应用开发者和用户。</p></div>',
    },
];

import React, {CSSProperties, ReactNode} from 'react';

import titleLeftIcon from '@/assets/img/img_biaotilan_80_left.png';
import titleRightIcon from '@/assets/img/img_biaotilan_80_right.png';

import whiteTitleLeftIcon from '@/assets/img/img_biaotibai_80_left.png';
import whiteTitleRightIcon from '@/assets/img/img_biaotibai_80_right.png';

import c from './index.less';

interface Props {
    className?: string;
    title?: string | ReactNode;
    description?: string | ReactNode;
    whiteTitle?: boolean;
    children: ReactNode;
    style?: CSSProperties;
}

export default function Card({
    className = '',
    title,
    description,
    whiteTitle,
    children,
    style = {},
}: Props) {
    return (
        <div className={`card ${c.root} ${className}`} style={style}>
            <div className={`cardBody ${c.cardBody}`}>
                {title ? (
                    <div className={`titleContainer ${c.titleContainer}`}>
                        <div className={c.titleBox}>
                            <div
                                className={c.icon}
                                style={{
                                    backgroundImage: `url(${
                                        whiteTitle ? whiteTitleLeftIcon : titleLeftIcon
                                    })`,
                                }}
                            />
                            <h1 className={c.title} style={{color: whiteTitle ? '#fff' : '#000'}}>
                                {title}
                            </h1>
                            <div
                                className={c.icon}
                                style={{
                                    backgroundImage: `url(${
                                        whiteTitle ? whiteTitleRightIcon : titleRightIcon
                                    })`,
                                }}
                            />
                        </div>
                        {description ? (
                            <p className={`titleDescription ${c.description}`}>{description}</p>
                        ) : null}
                    </div>
                ) : null}
                {children}
            </div>
        </div>
    );
}

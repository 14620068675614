import React from 'react';

import Card from '@/components/Card';

import config from './config';

import c from './index.less';

export default function GoodCase() {
    return (
        <Card className={c.card} title="优秀案例">
            <div className={c.contentContainer}>
                {config.map((item, index) => (
                    <a key={item.bgImg}>
                        <div className={c.itemBox} data-index={index}>
                            <div className={c.contentBox}>
                                <div
                                    className={c.titleBox}
                                    style={{backgroundImage: `url(${item.bgImg})`}}
                                    data-index={index}
                                >
                                    <h1 className={c.title}>{item.title}</h1>
                                    <span className={c.secondTitle}>{item.secondTitle}</span>
                                </div>
                                <div
                                    className={c.descriptionBox}
                                    style={{backgroundImage: `url(${item.hoverImg})`}}
                                >
                                    {item.description}
                                </div>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </Card>
    );
}

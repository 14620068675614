import React from 'react';

import PrivacyTransBanner from './PrivacyTransBanner';
import PrivacyProject from './PrivacyProject';
import PrivacyPolicy from './PrivacyPolicy';
import SocialResponsibility from './SocialResponsibility';
import PrivacyManagement from './PrivacyManagement';

export default function PrivacyTransparency() {
    return (
        <>
            <PrivacyTransBanner />
            <PrivacyProject />
            <PrivacyPolicy />
            <SocialResponsibility />
            <PrivacyManagement />
        </>
    );
}

import {RouteObject, useRoutes} from 'react-router-dom';

import BaseLayout from '@/layout/BaseLayout';

import {CONSTANT_ROUTER, CONSTANT_ROUTER_ELEMENT} from '@/dicts/router';

const routes: RouteObject[] = [
    {
        path: '/',
        element: <BaseLayout />,
        children: CONSTANT_ROUTER.map(item => {
            return {...item, element: CONSTANT_ROUTER_ELEMENT[item.path]};
        }),
    },
];

const Routes = () => useRoutes(routes);

export default Routes;

import React from 'react';

import SafeManagement from './SafeManagement';
import HomeBanner from './HomeBanner';
import ReallyTrust from './ReallyTrust';
import ResourceCenter from './ResourceCenter';

export default function Home() {
    return (
        <>
            <HomeBanner />
            <ReallyTrust />
            <SafeManagement />
            <ResourceCenter />
        </>
    );
}

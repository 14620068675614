import {Suspense} from 'react';

import Routes from '@/router';

const metaView = document.querySelector('meta[name="viewport"]') as HTMLMetaElement;
metaView?.setAttribute(
    'content',
    'width=device-width,initial-scale=1'
);

export default function App() {
    return (
        <Suspense>
            <Routes />
        </Suspense>
    );
}

import React from 'react';

import ComplianceBanner from './ComplianceBanner';
import SafeApprove from './SafeApprove';
import Honor from './Honor';
import Join from './Join';
import GoodCase from './GoodCase';

export default function ComplianceEnable() {
    return (
        <>
            <ComplianceBanner />
            <SafeApprove />
            <Honor />
            <Join />
            <GoodCase />
        </>
    );
}

import img1 from '@/assets/shibingePDFImg/图片1.png';
import img2 from '@/assets/shibingePDFImg/图片2.png';
import img3 from '@/assets/shibingePDFImg/图片3.png';
import img4 from '@/assets/shibingePDFImg/图片4.png';
import img5 from '@/assets/shibingePDFImg/图片5.png';
import img6 from '@/assets/shibingePDFImg/图片15.png';
import img7 from '@/assets/shibingePDFImg/图片7.png';
import img8 from '@/assets/shibingePDFImg/图片8.png';
import img9 from '@/assets/shibingePDFImg/图片9.png';
import img10 from '@/assets/shibingePDFImg/图片10.png';
import img11 from '@/assets/shibingePDFImg/图片11.png';
import img12 from '@/assets/shibingePDFImg/图片12.png';
import img13 from '@/assets/shibingePDFImg/图片13.png';
import img14 from '@/assets/shibingePDFImg/图片14.png';
import img15 from '@/assets/shibingePDFImg/图片15.png';
import img16 from '@/assets/shibingePDFImg/图片16.png';

export default [
    {
        tags: '引言',
        key: '1.',
        content:
            '<h1 class="first">1. 引言</h1>'
            + '<div class="one"><p>随着《个人信息保护法》和《数据安全法》的出台，国家及社会各界对个人信息保护的重视程度前所未有。'
            + '自从2019年国家网信办、工信部、公安部和国家市场监管总局开展APP专项整治行动以来，'
            + 'APP违规收集和使用个人信息成为监管的重点。工信部和网信办也分别开展了大量APP检测的专项执法行动，公开通报违规APP并将拒不整改或整改不达标的APP下架处理。'
            + '在这一监管背景下，各移动应用必须符合相关的监管规范。</p><p>企业用户在面对因隐私保护不当造成的数据安全问题、监管处罚等风险时，具有很大的困难和挑战，'
            + '如对隐私合规相关的法律法规理解不到位不全面、问题定位和排查困难且成本高、对第三方SDK的相关能力不清晰等，以上都会导致应用被下架或被监管处罚风险，导致企业的品牌公信力下降。</p>'
            + '<p>为加强对个人信息安全的保护，提高App信息安全保障能力与水平，百度安全将用户个人信息保护纳入产品价值，'
            + '依据《App违法违规收集使用个人信息行为认定方法》、工信部337号文、164号文和26号文、《个人信息安全规范》（GB/T 35273）等规范性文件、'
            + '国家标准，基于百度AI技术底座，自主研发了史宾格安全及隐私合规平台，致力于企业高效、精准、低成本地开展App合规问题自查及整改工作</p></div>',
    },
    {
        tags: '产品概述',
        key: '2.',
        content: '<h1>2. 产品概述</h1>',
        children: [
            {
                tags: '产品定位',
                key: '2.1',
                content:
                    '<h2>2.1 产品定位</h2>'
                    + '<p>史宾格平台基于百度AI技术底座，深度贴合法规标准，支持多智能终端多应用平台的合规风险检测，'
                    + '通过智能助理+智能检测+智能治理全方位助力企业高效、低成本实现App隐私合规。</p>'
                    + '<p>该平台由百度史宾格团队自主研发，基于AI检测技术，提供SAAS平台、API调用、专家人工辅助等不同形式的服务，'
                    + '通过深度挖掘APP隐私合规风险产生的源头，包含APP权限过度申请与使用多种维度，'
                    + '依据《个人信息保护法》、《网络安全法》、《信息安全技术个人信息规范》、《APP违法违规收集使用个人信息自评估指南》等国家法律法规，'
                    + '生成可视化的隐私合规情况检测报告，协助监管部门高效、精准地发现APP合规问题，帮助企业APP满足监管要求。</p>',
            },
            {
                tags: '产品架构',
                key: '2.2',
                content: `<h2>2.2 产品架构</h2><img src="${img1}" alt="图片"/><p>`
                + '<p>史宾格平台围绕全生命周期风险管理，使用大模型等AI技术从风险预防、风险发现和风险治理三个方面全面赋能开发者，形成一个完整的治理闭环。</p>'
                + '<p>首先，在风险发现方面依托于百度对技术研发的巨大投入，以先进的人工智能技术为支撑，'
                + '覆盖了产品设计、开发测试、上线前安全测试和上线后安全监控等App全生命周期的隐私合规需求，'
                + '包含隐私数据采集、权限使用场景、隐私政策管理等多重纬度的深度检测能力。</p>'
                + '<p>其次，在风险预防和治理方面，基于百度 AI 和文心一言技术底座，利用史宾格平台全网APP生态治理数据底座，'
                + '依托史宾格团队积累的个保合规领域知识底座，研发基于LLM的智能问答、智能检测和智能治理三大核心能力；'
                + '通过智能助理、AI工具箱、AI全自动和AI Copilot四大AI原生功能全面赋能APP用户权益保护生态治理新范式，'
                + '解决企业合规人员匮乏、检测效率低、治理效率低等行业痛点，实现APP隐私合规领域 "懂合规、识风险、会整改" 的智能助理。</p>',
            },
            {
                tags: '性能指标说明',
                key: '2.3',
                content: '<h2>2.3 性能指标说明</h2>',
                children: [
                    {
                        tags: '系统扩展支持',
                        key: '2.3.1',
                        content:
                            '<p class="three">2.3.1 系统扩展支持</p>'
                            + '<p>支持通过API方式对接内部系统，功能包括任务新建、任务状态查询、任务停止、任务删除、任务结果下载、任务报告下载。</p>',
                    },
                    {
                        tags: '系统性能需求',
                        key: '2.3.2',
                        content:
                            '<p class="three">2.3.2 系统性能需求</p>'
                            + '<p>1. 对查询的数据详情查询时间不超过10秒返回。</p><p>2. 支持超过指定个数的App数据和日志的存储。</p>'
                            + '<p>3. 所有App档案和日志数据存储周期为12个月，每次App评估任务结束后进行备份并保存，并可根据用户需求进行删除，但保留日志。</p>'
                            + '<p>4. 产品至少支持20个用户同时在线，最大支持10个用户并发操作。</p>',
                    },
                    {
                        tags: '系统安全需求',
                        key: '2.3.3',
                        content: '<p class="three">2.3.3 系统安全需求</p><p>1. 能够抵抗来自系统外部和内部的攻击，保证系统安全、可靠地运行。</p>'
                        + '<p>2. 系统本身有严格的身份验证、访问控制、数据加密、授权管理的控制。</p>'
                        + '<p>3. 系统各个服务组件都具有审计功能，至少包括系统运行日志、系统管理日志等。</p>'
                        + `<img src="${img2}" alt="图片"/>`,
                    },
                ],
            },
        ],
    },
    {
        tags: '产品功能介绍',
        key: '3.',
        content: '<h1>3. 产品功能介绍</h1>',
        children: [
            {
                tags: 'AI自动/辅助检测',
                key: '3.1',
                content: '<h2>3.1 AI自动/辅助检测</h2>'
                + '<p>APP收集使用个人信息合规性检测是基于AI静动态检测能力及智能语义分析技术，并以国家发布的规范或标准为依据，'
                + '对APP个人信息采集行为进行逐条对应分析，判断数据采集行为的合规性，通过具有针对性的自动化检测方式，快速完成复杂的检测流程，即时生成精确的合规性检测报告。</p>'
                + `<img src="${img3}" alt="图片"/>`
                + '<p>史宾格安全及隐私合规平台的合规性检测可实现自动化动态生成检测报告，解决了传统测评业务中需要大量人工撰写检测报告的问题，'
                + '以国家规范为检测依据，为客户实现有据可依，针对于存在风险的隐私行为进行逐条对应，使违反的法规条目更加清晰明了，并可根据对应的整改建议优化产品提升效率。</p>'
                + `<img src="${img4}" alt="图片"/><img src="${img5}" alt="图片"/>`
                + '<p>史宾格安全及隐私合规平台具备完善的应用隐私风险检测与技术实践能力，拥有市场最全的第三方SDK隐私检测能力和知识库，'
                + '以及权限到API的映射关系数据库，保障应用隐私风险检测的专业性和全面性，从而促进应用隐私合规。</p>',
            },
            {
                tags: '隐私专项检测',
                key: '3.2',
                content: '<h2>3.2 隐私专项检测</h2>'
                + '<p>依据《App违法违规收集使用个人信息行为认定方法》、工信部337号文、164号文和26号文、《个人信息安全规范》（GB/T 35273）等规范性文件、国家标准，'
                + '平台支持标准专项检测，生成专项检测报告和整改建议，确保应用符合标准规范。'
                + '实时监控在APP运行过程中所存在的违法违规收集个人信息的风险行为，同时对前台交互行为和不可见的后台运行进行截图和事件流信息留存，'
                + '同时，基于大模型能力，对隐私政策文本进行合规性检测，帮助应用隐私政策符合监管要求。</p>'
                + `<img src="${img6}" alt="图片"/><img src="${img15}" alt="图片"/>`,
            },
            {
                tags: '专家模式',
                key: '3.3',
                content: '<h2>3.3 专家模式</h2><p>专家模式检测提供了对APP隐私政策的合规性检测能力，'
                + '以及在不同运行环境下敏感权限的申请告知场景和使用场景的检测能力，是判断APP隐私合规的重要依据。'
                + '史宾格安全及隐私合规平台通过云端手机模拟APP的真实使用环境，包括前台使用场景、Home后台场景、Back后台场景、'
                + '锁屏场景、解锁场景、通讯场景、设备重启场景等。在动态的模拟使用过程中，'
                + '对隐私政策文本及APP的隐私行为进行检测和分析，最终生成可视化的隐私合规检测报告。</p>'
                + `<img src="${img7}" alt="图片"/>`
                + `<img src="${img8}" alt="图片"/>`
                + '<p>为了解决敏感权限下个人信息的行为溯源问题，在动态检测场景下，对于敏感权限可以查看在不同触发场景中调用代码的触发堆栈，'
                + '统计触发次数并对触发场景截图，使敏感权限的个人信息采集行为有缘可溯。</p>'
                + '<p>为了发现个人信息的传输所存着的隐私风险，基于VPNService技术的应用流量监控方案，精确定位个人信息传输流向。'
                + '数据传输分析实现对APP传输采集的个人信息的检测，可以检测出APP向哪些域名传输个人信息，'
                + '以及是否存在跨境传输等情况，使个人信息的传输流向更加透明清晰，及时发现信息传输的潜在隐私风险。</p>'
                + '<p>为了让客户更便捷地发现隐私合规风险，我们的隐私合规检测报告以政府发布的规范为标准进行标准化输出，APP隐私合规问题清晰可寻。</p',
            },
            {
                tags: '权限及SDK分析',
                key: '3.4',
                content: '<h2>3.4 权限及SDK分析</h2>'
                + '<p>上传APK\\SDK即时检测敏感权限的申请和使用情况，及时发现过度申请权限、冗余权限与代码，'
                + '及使用不合理的敏感权限等问题，判断敏感权限使用的合理性。史宾格安全及隐私合规平台会按照涉及个人信息的敏感级别将敏感权限分为高敏、中敏和低敏权限3个等级，'
                + '并精准定位发生敏感权限使用的代码位置，并可视化地展示出来。对于集成了三方SDK的APP而言，'
                + '对于其集成的三方SDK我们也可实现自动化检测，轻松解决第三方SDK的权限使用不可控的难题。</p>'
                + `<img src="${img9}" alt="图片"/>`
                + '<p>将APP中权限的使用场景划分为自身APP使用、SDK使用和其他使用，并实现精确定位到代码的位置可视化展示代码分布。</p>'
                + `<img src="${img10}" alt="图片"/>`
                + '<p>通过静态检测自动分析出需要由用户输入或提交的个人信息，如身份证号、身份证照片、银行卡号等信息，用于后续收集合规性判断。</p>'
                + `<img src="${img14}" alt="图片"/>`,
            },
            {
                tags: '版本对比',
                key: '3.5',
                content: '<h2>3.5 版本对比</h2><p>通过对产品多个不同版本之间的对比分析，帮助产品快速定位隐私合规问题起始版本和原因，'
                + '更快解决隐私合规问题。利用市场上同类产品的差异性对比，可以从更宏观的视角了解产品的隐私情况，及时发现不必要的隐私合规问题并及早调整。</p>'
                + `<img src="${img11}" alt="图片"/>`,
            },
            {
                tags: '智能助理',
                key: '3.6',
                content: '<h2>3.6 智能助理</h2><p>为能够精确分析用户的疑惑并迅速提供专业、及时的解答与指导，史宾格智能助理依托庞大的合规知识库，'
                + '汇集丰富的法规知识、常见问题及其解答、详尽的合规指引以及最新的监管通报等资源，为用户提供智能问答功能，确保用户能够轻松应对各种合规挑战。</p>'
                + `<img src="${img16}" alt="图片"/>`,
            },
            {
                tags: 'AI工具箱',
                key: '3.7',
                content: '<h2>3.7AI工具箱</h2>',
                children: [
                    {
                        tags: '系统扩展支持',
                        key: '3.7.1',
                        content:
                            '<p class="three">3.7.1 法规伴读</p>'
                            + '<p>覆盖300+法规标准，通过智能导读和智能问答帮助用户快速把握法规标准的框架和核心要点，'
                            + '帮助企业解决法规标准较为分散且获取效率低的问题，获取合规领域知识更直观更高效。</p>',
                    },
                    {
                        tags: '系统性能需求',
                        key: '3.7.2',
                        content:
                            '<p class="three">3.7.2 监管通报洞察</p>'
                            + '<p>通过对全网App的违规通报动态追踪和监控，借助大模型技术对监管趋势进行智能分析和洞察，</p>'
                            + '<p>帮助用户及时获得监管动态，迅速响应监管变化，调整自身的合规策略，'
                            + '规避潜在的合规风险，确保其业务操作始终符合监管要求。</p>',
                    },
                    {
                        tags: '系统安全需求',
                        key: '3.7.3',
                        content: '<p class="three">3.7.3 隐私政策智能解析</p>'
                        + '<p>基于大模型的强大计算和分析能力，对隐私政策进行深度解析和评估，具体包括隐私政策透明度评估、'
                        + '个人信息抽取、设备权限信息抽取、第三方SDK列表抽取以及业务功能抽取等，提高了隐私政策分析的效率和准确性，助力隐私政策更合规。</p>',
                    },
                ],
            },
        ],
    },
    {
        tags: '产品优势',
        key: '4.',
        content: '<h1>4. 产品优势</h1>',
        children: [
            {
                tags: '标准优势',
                key: '4.1',
                content:
                    '<h2>4.1 标准优势</h2>'
                    + '<p>专业的法务团队紧跟国家政策法规，实现深度对齐《App违法违规收集使用个人信息行为认定方法》、国家标准GB/T35273'
                    + '《信息安全技术 个人信息安全规范》、《中华人民共和国网络安全法》等相关法律法规，对App收集使用用户个人信息的行为进行逐条对应分析。</p>',
            },
            {
                tags: '技术优势',
                key: '4.2',
                content:
                    '<h2>4.2 技术优势</h2>'
                    + '<p>AI深度检测技术。依托于百度对技术研发的巨大投入，以先进的人工智能技术为支撑，覆盖了产品设计、开发测试、'
                    + '上线前安全测试和上线后安全监控等App全生命周期的隐私合规需求。'
                    + '包含隐私数据采集、权限使用场景、 隐私政策管理等多重纬度的深度检测能力。</p>',
            },
            {
                tags: '产品优势',
                key: '4.3',
                content:
                    '<h2>4.3 产品优势</h2>'
                    + '<p>高效的自动化深度检测。基于百度云手机集群的AI全自动化隐私风险发现与检测能力，提供隐私专项检测、隐私合规检测、权限分析、专家模式'
                    + '以及病毒与漏洞检测等服务。深度挖掘隐私合规风险点、快速处理大批量App，替代人工翻查代码，降低时间与人力成本，显著提升检测效率。</p>',
            },
            {
                tags: '合作优势',
                key: '4.4',
                content: '<h2>4.4 合作优势</h2>'
                + '<p>全面支撑监管治理。经过长期的实践与广泛应用，凭借在个人信息安全国家标准实践方面的出色表现，'
                + '已先后获得多家监管部门认可并达成技术合作 。基于一系列底层技术能力，为其提供App隐私合规检测技术支撑，'
                + '全面支持监管部门在App过度收集个人信息专项治理、建立联动监管平台方面等具体工作。</p>',
            },
            {
                tags: '服务优势',
                key: '4.5',
                content:
                    '<h2>4.5 服务优势</h2>'
                    + '<p>资深专家团队一对一服务。深耕隐私合规领域，凝聚百度隐私合规法务团队与百度安全管理团队的智慧与经验。'
                    + '由资深安全专家与法务精英，共同为您提供包含安全专家解读、技术专家分析等合规辅导的增值服务。</p>',
            },
        ],
    },
    {
        tags: '应用场景',
        key: '5.',
        content: '<h1>5. 应用场景</h1>',
        children: [
            {
                tags: '监管机构合规抽查',
                key: '5.1',
                content:
                    '<h2>5.1 监管机构合规抽查</h2>'
                    + '<p>深度支撑工信部、网信办等多家监管机构的隐私合规检测。可满足高并发量的检测需求，'
                    + '自动化的标准化检测替代传统的人工筛查，从而解决人工筛查任务重、标准不统一、漏检率高等问题。'
                    + '最大化降低人力与时间成本，可精准全面地发现App隐私违规问题。</p>',
            },
            {
                tags: '企业应用合规检测',
                key: '5.2',
                content:
                    '<h2>5.2 企业应用合规检测</h2>'
                    + '<p>结合企业特色及全行业的监管要求，以SAAS服务方式为企业旗下产品矩阵提供必要的安全保护包括不限于应用上架、应用通报整改等场景，'
                    + '建立全面高效的的隐私合规监控和管理体系。平台支持Android、iOS、小程序、SDK等多平台应用检测，'
                    + '一站式解决企业 App 矩阵隐私风险治理问题，避免因问题产品流向市场导致的舆论或合规风险，'
                    + '为企业良好口碑与健康发展提供有力的保障。</p>',
            },
            {
                tags: '应用分发平台上架检测',
                key: '5.3',
                content:
                    '<h2>5.3 应用分发平台上架检测</h2>'
                    + '<p>提供拥有高效并行处理任务集群的定制化接口，在不破坏原有运营框架和平台体量的情况下精准定位风险。'
                    + '帮助应用分发平台对平台内上架的App落实管理责任，严格上架审核流程，发现违法违规App并及时处理。</p>',
            },
            {
                tags: '智能终端合规检测',
                key: '5.4',
                content:
                    '<h2>5.4 智能终端合规检测</h2>'
                    + '<p>支持多智能终端的移动应用软件检测，除智能手机之外，还包支持智能电视、智能手表、车载终端等多终端的应用合规检测。'
                    + '平台已有落地的终端应用解决方案，可通过saas或者私有化部署的方式对智能车机、智能电视应用进行合规检测，'
                    + '确保所载应用符合监管标准规范。</p>',
            },
        ],
    },
    {
        tags: '荣誉及证书',
        key: '6.',
        content: '<h1>6. 荣誉及证书</h1>',
        children: [
            {
                tags: '荣誉获得',
                key: '6.1',
                content: '<h2>6.1 荣誉获得</h2><p>- 2019年，史宾格支撑国家计算机病毒应急处理中心移动APP管控工作，并被授予优秀技术支持单位。</p>'
                + '<p>- 2020年，史宾格于2020年成功通过公安三所基于《App安全检查工具技术规范》 开展的评测，符合公安部门App检测工具的技术要求，有资格作为全国公安系统的警用工具使用。</p>'
                + '<p>- 2020年，史宾格获得全国信息安全标准化委员会颁发的2020年网络安全国家标准优秀实践案例二等奖。'
                + '利用技术实践国家标准《信息安全技术 个人信息安全规范》（GB/T 35273），是国内唯一入围该奖项的隐私合规平台。</p>'
                + '<p>- 2021年，获得中国信息通信研究院颁发的2021年企业数字化治理先锋实践案例，史宾格平台被评为智能安全与隐私合规类“最具价值应用案例”。</p>'
                + '<p>- 2022年，连续两次通过工信部信通院泰尔实验室组织的『移动互联网应用程序（APP）用户权益保护测试』能力验证。</p>'
                + '<p>- 2023年，通过CCRC中国网络安全审查技术与认证中心『APP个人信息安全测试』能力验证。</p>'
                + `<img src="${img12}" alt="图片"/>`,
            },
            {
                tags: '外部认可',
                key: '6.2',
                content: `<h2>6.2 外部认可</h2>
                <p>- 史宾格多次获得工信部、信通院、中国电子技术标准化研究院等相关部门的感谢信。</p>
                <img src="${img13}" alt="图片"/>`,
            },
            {
                tags: '专利获得',
                key: '6.3',
                content:
                    '<h2>6.3 专利获得</h2><p>- 国内领先隐私技术专利，基于史宾格团队核心安全能力沉淀及已获取20+项专利的AI检测能力，为客户提供专业隐私合规服务。</p>',
            },
        ],
    },
];

import imgbiao1 from '@/assets/xiaoduanquanPDFImg/xiaoduanquantable1.png';
import img1 from '@/assets/xiaoduanquanPDFImg/xiaoduanquanimg1.png';
import img2 from '@/assets/xiaoduanquanPDFImg/xiaoduanquanimg2.png';
import img3 from '@/assets/xiaoduanquanPDFImg/xiaoduanquanimg3.png';
import img4 from '@/assets/xiaoduanquanPDFImg/xiaoduanquanimg4.png';
import img5 from '@/assets/xiaoduanquanPDFImg/xiaoduanquanimg5.png';
import img6 from '@/assets/xiaoduanquanPDFImg/xiaoduanquanimg6.png';
import img7 from '@/assets/xiaoduanquanPDFImg/xiaoduanquanimg7.png';
import img8 from '@/assets/xiaoduanquanPDFImg/xiaoduanquanimg8.png';
import img9 from '@/assets/xiaoduanquanPDFImg/xiaoduanquanimg9.png';
import img10 from '@/assets/xiaoduanquanPDFImg/xiaoduanquanimg10.png';
import img11 from '@/assets//xiaoduanquanPDFImg/xiaoduanquanimg11.png';

export default [
    {
        tags: '',
        key: '0.',
        content: '<h1 class="first">声明</h1>'
        + '<div class="one"><p>本白皮书仅为用户更好地了解小度在保障产品安全和用户隐私过程中使用的安全技术。'
        + '随着小度技术升级和产品迭代，小度会持续完善有关保护用户隐私的相关功能、机制和流程，'
        + '致力于在为用户提供优质产品服务的同时，持续加强对用户隐私的保护。本白皮书不应被视为法律建议，'
        + '也不得被视为对小度现行任何合同安排的更改。</p><p>若发现白皮书中存在任何错误或对内容存在任何疑问，'
        + '请通过Duer_security@baidu.com联系我们。</p>'
        + '<p>小度公司(本白皮书中指上海小度人工智能有限公司及其关联公司，'
        + '以下亦称“小度”)依法对本白皮书内所有原创图片、架构设计、文字描述等内容享有知识产权，未经小度事先书面许可，'
        + '任何单位、公司或个人不得摘抄、翻译、复制本文档内容的部分或全部，否则将追究其相关法律责任。</p>'
        + '<p>关联公司是指DuerOS的经营者上海小度人工智能有限公司及其他存在关联关系的公司的单称或合称，'
        + '包括但不限于上海小度技术有限公司。“关联关系”是指对于任何主体(包括个人、公司、合伙企业、组织或其他任何实体)而言，'
        + '即其直接或间接控制的主体，或直接或间接控制其的主体，或直接或间接与其受同一主体控制的主体。'
        + '前述“控制”指，通过持有表决权、合约或其他方式，直接或间接地拥有对相关主体的管理和决策作出指示'
        + '或责成他人作出指示的权力或事实上构成实际控制的其他关系。</p></div>',
    },
    {
        tags: '概述',
        key: '1.',
        content: '<h1>1. 概述</h1><div class="one"><p>小度公司是全球领先的智能科技企业，致力于以小度助手(DuerOS)为核心,以硬件为重要载体，'
        + '为大众用户提供不同场景下，更加精准、便捷、多元的智能服务。</p><p>DuerOS依赖海量数据，'
        + '通过自然语言完成对硬件的操作与对话交流，为用户提供完整的服务链条。保障。'
        + '小度将DuerOS系统能力搭建在小度有屏端、小度无屏端、小度耳机、学习平板、健身镜、小度电视、车载支架等硬件产品上，'
        + '让更多用户通过语音对话式的交互提高获取信息的效率，享受便利的生活。尊重用户的个人信息，'
        + '保护用户的设备安全，坚守数据合规红线，是小度在产品设计、实现、运营的基础理念。</p>'
        + '<p>《小度安全技术白皮书》将公开展示小度在产品安全和用户数据保护所做的持续努力，帮助用户更好地了解小度的产品和服务。</p>'
        + '<p>后续章节如下展开：</p><p>1. 第2章，介绍小度安全管理流程，阐述小度安全风险管理措施。</p>'
        + '<p>2. 第3章，介绍小度全生命周期保障框架，阐述在产品生命周期和数据生命周期的基本保护原则。</p>'
        + '<p>3. 第4章，介绍围绕智能设备建立的“端-管-云”的纵深防御方案，阐述安全最佳实践</p>'
        + '<p>4. 第5章，介绍小度为保护用户数据，建立的端云一体数据安全保护方案。</p>'
        + '<p>5. 第6章，介绍小度获得的安全隐私认证。</p></div>',
    },
    {
        tags: '安全管理流程',
        key: '2.',
        content: '<h1>2. 安全管理流程</h1>',
        children: [
            {
                tags: '明确安全要求',
                key: '2.1',
                content: '<h2>2.1 明确安全要求</h2>'
                + '<p>小度在通用安全规范方面沿用百度集团安全规范，'
                + '其中包括安全红线、办公安全、基础安全、数据安全与隐私、产品安全、第三方合作安全以及安全管理等。</p>'
                + '<p>针对特定场景下的安全问题，小度根据其自身情形制定如下规范：</p>'
                + '<p>1.<b>《智能硬件安全准出规范》</b>：定义了智能硬件安全分级制度，'
                + '并规定了设备安全等级、安全问题修复、安全问题响应的明确要求。</p>'
                + '<p>2.<b>《智能设备三方应用安全&隐私准入规范》</b>'
                + '：定义了在引入第三方应用场景下，需满足及遵循的安全&隐私规范。</p>',
            },
            {
                tags: '人员安全培训',
                key: '2.2',
                content: '<h2>2.2 人员安全培训</h2>'
                + '<p>小度会定期组织人员安全培训，主要包括如下两类：</p>'
                + '<p><b>1. 人员意识方向</b>：针对员工会进行统一的安全意识培训，包括但不限于：安全红线讲解，基本安全准则介绍，反欺诈钓鱼应对等，并配套安全意识考核。</p>'
                + '<p><b>2. 安全研发方向</b>：针对研发过程常见的安全问题进行业务安全培训，包括但不限于：移动安全编码，嵌入式安全编码，Web安全编码等，并配套安全意识考核。</p>',
            },
            {
                tags: '安全风险管理',
                key: '2.3',
                content: '<h2>2.3 安全风险管理</h2><h2>漏洞分类</h2><p>小度根据漏洞的来源，将漏洞分为如下两类：</p><p><b>1. 自身产品安全漏洞</b>'
                + '：由小度在设计和实现过程中产生的安全问题，小度依托的百度基础服务产生的安全问题也归为该类问题，问题归属方为小度或百度集团。</p>'
                + '<p><b>2. 供应链安全漏洞</b>：由小度上游供应链厂商（包括OEM、ODM、芯片厂商）在设计和实现过程中的安全问题，'
                + '问题归属方为上游供应链厂商，小度是受其影响的下游产品之一。</p><h2>漏洞管理</h2>'
                + '<p>针对产品安全漏洞，小度采用安全工单的形式进行管理，安全漏洞修复要求如表1所示。</p>'
                + `<img src="${imgbiao1}" alt="表一" />`
                + '<p class="centerText">表1 小度产品安全漏洞修复要求</p>'
                + '<p>针对供应链安全漏洞，小度会在漏洞正式披露前2个月获得安全补丁，并进行严格筛选，评估对系统的影响。'
                + '由于系统为小度深度定制，考虑到补丁对系统的影响，不会立即合入所有安全补丁，'
                + '会优先合入对系统机密性、完整性和可用性危害较高的安全补丁，并保证正式披露前通过更新渠道推送升级包。</p>'
                + '<p>关于外部安全报告以及舆论风险，小度遵循百度集团的安全应急响应流程，在保护用户个人数据前提下，进行止损和修复处理。</p>',
            },
        ],
    },
    {
        tags: '全生命周期保障框架',
        key: '3.',
        content: '<h1>3. 全生命周期保障框架</h1>'
        + '<p>小度以智能设备为核心，给用户提供了丰富的内容体验。同样，小度围绕产品生命周期和数据生命周期，建立了一套覆盖“端-管-云”的安全保障框架如图1所示。</p>'
        + `<img src="${img1}" alt="1" />`
        + '<p class="centerText">图 1 全生命周期保障框架</p>',
        children: [
            {
                tags: '产品生命周期',
                key: '3.1',
                content: '<h2>3.1 产品生命周期</h2>'
                + '<p>在产品生命周期中引入安全来深度参与，保障从产品设计到运营中各个环节的风险可控。</p>'
                + `<img src="${img2}" alt="2" />`
                + '<p class="centerText">图 2 产品生命周期</p>'
                + '<p><b>1. 设计阶段</b>：遵循产品安全基线，方案的设计需引入安全和隐私的评审，关键身份认证方案如设备配网、绑定等由安全进行深度设计参与。</p>'
                + '<p><b>2. 研发阶段</b>：集成安全基础组件来规避常规安全问题，在代码合入前需进行代码安全扫描，三方SDK/应用的引入需严格遵循安全规范。</p>'
                + '<p><b>3. 测试阶段</b>：对打包完成的应用、固件进行安全扫描，针对敏感数据流转进行安全审计。设备上线前引入安全专家进行渗透测试，保障设备外发时不存在严重及高危漏洞。</p>'
                + '<p><b>4. 运营阶段</b>：设备部署安全防护能力，保障设备运行时安全。持续关注设备异常状态，'
                + '针对不安全设备进行威胁预警，必要时采用屏蔽策略。建立应急响应及安全补丁合入机制，持续提升系统的安全等级。</p>'
                + '<p><b>5. 监管阶段</b>：持续与监管机构保持沟通与交流，输出内部最佳实践，积极参与安全隐私国标试点计划。</p>',
            },
            {
                tags: '数据生命周期',
                key: '3.2',
                content: '<h2>4.2 数据生命周期</h2>'
                + '<p>小度重视用户个人数据的保护，安全能力覆盖数据生命周期各个环节。</p>'
                + `<img src="${img3}" alt="3" />`
                + '<p class="centerText">图 3 数据生命周期</p>'
                + '<p><b>1. 隐私保护原则</b>：小度在个人信息处理中，坚持“公开透明、知情同意、最少够用、确保安全、数据质量、用户体验”的原则。</p>'
                + '<p><b>2. 隐私管理制度</b>：小度建立了完善的个人信息保护合规制度，包括个人信息保护合规制度、个人信息分类管理制度和个人信息安全事件管理制度。</p>'
                + '<p><b>3. 采集阶段</b>：小度遵循公开透明、知情同意、最少够用的原则，对业务必要的数据进行采集，并且采集过程中会明确征得用户同意。</p>'
                + '<p><b>4. 传输阶段</b>：小度采用加密传输套件来保护传输过程，无法针对关键请求进行劫持还原。</p>'
                + '<p><b>5. 存储阶段</b>：敏感数据存储在数据安全沙箱中，并结合可信硬件的技术来加密保护敏感数据。</p>'
                + '<p><b>6. 使用阶段</b>：小度对数据进行分类分级处理，使用时需采用审批授权机制，并对数据的获取行为采用严格的安全审计。</p>'
                + '<p><b>7. 共享阶段</b>：原则上小度不会与第三方共享用户信息。</p>'
                + '<p><b>8. 销毁阶段</b>：用户可通过解绑账号来销毁用户在设备上的数据，由于部分数据是云端存储，用户可通过注销百度账号，彻底销毁用户数据。</p>',
            },
        ],
    },
    {
        tags: '基于设备的纵深防御方案',
        key: '4.',
        content: '<h1>4. 围绕设备的纵深防护体系</h1><p>小度利用可信技术从底层硬件到接入层，构建一套纵深防护体系，能够对设备进行全方位的实时安全防护。</p>',
        children: [
            {
                tags: '硬件安全',
                key: '4.1',
                content: '<h2>4.1 硬件安全</h2>'
                + '<p>硬件安全是整个设备安全的基础设施，为设备提供底层安全能力支撑。'
                + '通过可信执行环境（TEE），设备指纹，安全启动，能够为设备提供基于硬件的加解密服务，敏感信息存储，设备身份验证，启动安全防护等能力。</p>'
                + '<h2>可信执行环境</h2>'
                + '<p>可行执行环境全称为TrustedExecutionEnvironment,是基于ARMTrustzone的保护措施，能够为设备提供可信的运行环境，来保证敏感信息的完整性、机密性和可用性。</p>'
                + '<p>小度基于硬件可信技术提供了两个环境，分别是DuerOS环境和TEE环境。两个环境严格隔离，仅能通过受限制的访问控制API进行通信，如图4所示。</p>'
                + `<img src="${img4}" alt="4" />`
                + '<p class="centerText">图 4 小度可信安全环境</p>'
                + '<p>其中DuerOS环境负责对外的业务逻辑处理，'
                + 'TEE环境负责提供关键资产的保护措施如生物特征、关键认证算法的保护。当DuerOS环境中的关键业务逻辑涉及到用户个人敏感信息的处理时，'
                + '会通过访问控制API访问TEE环境中的可信应用来进行关键信息的处理操作，如人脸识别的验证，个人敏感信息的加解密等。'
                + '由于设备关键的认证参数及生物特征是存储在硬件的安全区域，DuerOS环境是无法直接获取，当设备被破坏或被攻击也能够保障设备上的用户敏感数据无法被解密和还原。</p>'
                + '<h2>安全启动</h2>'
                + '<p>安全启动是保证系统启动安全的机制。在启动的各个阶段，'
                + '能够基于设备硬件唯一密钥（HardwareUniqueKey）来验证各个分区的完整性。设备硬件唯一密钥是在设备出厂前烧写在芯片安全存储区域的密钥，'
                + '在出厂后无法被篡改。设备在使用了安全启动后能够防止对设备刷写未经授权的固件或篡改过的固件。</p>'
                + '<h2>设备指纹</h2>'
                + '<p>设备指纹是基于硬件唯一密钥（HUK）生成的设备唯一身份验证，能够标识设备的唯一性，'
                + '具备不可伪造，不可篡改的特点。将设备指纹和可信环境相结合，可保证部分关键请求的来源可信，无法模拟重放关键请求。</p>',
            },
            {
                tags: '系统安全',
                key: '4.2',
                content: '<h2>4.2 系统安全</h2>'
                + '<p>系统安全防护的目标是保护设备自身组件的安全，通过调试权限管理严格限制设备的调试授权，'
                + '使用可靠的更新服务进行安全和功能的迭代升级，系统集成的热修复能力能够对线上安全问题进行紧急止损。'
                + '异常监测能力，能够对设备入侵风险进行感知和响应。</p>'
                + '<h2>设备调试</h2>'
                + '<p>小度允许开发者申请设备的调试密钥，但制定了严格的设备调试管理方案，'
                + '能够保障设备调试权限被正确的授予、管理及回收。</p>'
                + '<p><b>1. 调试申请</b>：用户需在开发者平台上进行注册，并申请设备调试，'
                + '用户只能申请其百度账号绑定的设备进行调试。</p>'
                + '<p><b>2. 调试授权</b>：开发者平台会针对用户及设备生成调试密钥，'
                + '并设置密钥过期时间，确保只有在过期时间内的调试密钥才有效，该密钥在每个调试周期内均保证一机一密。</p>'
                + '<p><b>3. 权限回收</b>：当设备调试密钥过期后，开发者需重新申请调试密钥。'
                + '一旦发现设备存在严重安全风险，小度能够随时撤销设备的调试权限，并禁用设备相关服务。</p>'
                + '<h2>访问控制</h2>'
                + '<p>SELinux（安全增强型Linux）能够对设备上的进程采取强访问控制措施，'
                + '小度设备支持SElinux能力，并针对进程间通信，文件访问等建立了严格的访问控制模型，'
                + '可以更好地保护系统组件，降低恶意软件的影响。</p>'
                + '<h2>设备更新</h2>'
                + '<p>小度会定期对设备进行更新，为了保障更新过程安全与稳定，'
                + '小度采用定制化的更新方案。主要体现在如下三方面：</p>'
                + '<p><b>1. 更新源安全</b>：针对设备的更新包原始文件进行加密，'
                + '设备端进行解密，且采用一机一密。即使更新包被外部获取也无法解密获得其中的信息。</p>'
                + '<p><b>2. 更新过程保护</b>：更新过程采用独立的签名校验方案，并验证升级版本，无法对设备进行降级更新。</p>'
                + '<p><b>3. 更新链路安全</b>：更新过程采用双向认证，且采用HTTPS进行传输，'
                + '无法通过中间人劫持的方式获得更新内容。</p>'
                + '<h2>热修复</h2>'
                + '<p>当设备处于紧急状态，如面临大规模攻击风险或数据泄露场景等，'
                + '小度集成的系统热修复能力便可发挥作用。该能力能够在系统不重启的条件下，'
                + '实时下发安全补丁，完成紧急止损。</p>'
                + `<img src="${img5}" alt="5" />`
                + '<p class="centerText">图 5 自适应热修复</p>'
                + '<h2>异常监测</h2>'
                + '<p>当设备发生异常状态时如设备破解、恶意ROOT、自定义证书安装等，'
                + '小度会进行感知，当判断发生危害用户个人信息安全的情形时，'
                + '小度会通知用户，并对设备的隐私相关功能进行禁用。</p>',
            },
            {
                tags: '应用安全',
                key: '4.3',
                content: '<h2>4.3 应用安全</h2>'
                + '<p>小度为了给用户带来更丰富的体验，引入三方应用来丰富内容生态。但小度同样对应用进行严格的管理与监测，一旦发现应用存在违规行为，则会采用干预措施，并将风险提示给用户。</p>'
                + '<h2>应用权限管理</h2>'
                + '<p>小度设备提供了3类关键权限的管理（相机、麦克风、位置信息），用户可通过“设置”->“隐私设置”->“应用权限管理”来进行权限的授予与撤销。默认不授予关键权限，应用使用时需动态申请。</p>'
                + `<img src="${img6}" alt="6" />`
                + '<p class="centerText">图 6 三方应用权限管理</p>'
                + '<h2>恶意应用扫描</h2>'
                + '<p>当用户通过应用市场或浏览器安装外部应用时（该能力只有部分型号设备支持），设备会对待安装的应用进行安全扫描。一旦发现安全风险，会将风险反馈到用户侧来强提示，如图7所示。</p>'
                + `<img src="${img7}" alt="7" />`
                + '<p class="centerText">图 7 三方应用风险示意</p>'
                + '<h2>隐私行为展示</h2>'
                + '<p>设备能够展示三方应用使用隐私权限的行为，用户可在”隐私设置->应用行为记录”中进行查看。小度充分尊重用户的选择，提供用户管理权限的通道，如图8所示。</p>'
                + `<img src="${img8}" alt="8" />`
                + '<p class="centerText">图 8 应用行为记录</p>',
            },
            {
                tags: '链路安全',
                key: '4.4',
                content: '<h2>4.4 链路安全</h2>'
                + '<p><b>1. 加密传输套件</b>：涉及用户敏感信息及设备控制的关键的请求采用HTTPS加密传输，保证无法通过中间人的方式对通信内容进行破解和篡改。</p>'
                + '<p><b>2. 安全交互控制协议</b>：当设备要绑定及配网配对，采用百度统一安全交互控制协议，保障设备、云端、用户三方认证，并保障整个交互过程的安全控制。</p>'
                + '<p><b>3. 安全DNS服务</b>：设备在进行域名解析时，采用百度自建的安全DNS服务，能够对抗恶意广告、恶意网址、DNS劫持等风险。</p>',
            },
            {
                tags: '接入安全',
                key: '4.5',
                content: '<h2>4.5 接入安全</h2>'
                + '<p>接入安全防护目标是保证云端能正确识别用户侧和设备侧的请求，能严格验证用户和设备之间的绑定关系，确保用户仅能控制其账号下的设备。</p>'
                + '<p><b>1. 身份认证</b>：用户与设备在云端具有严格的绑定关系，且均基于百度账号派生，所有控制相关请求均需经过百度账号体系的严格验证，在验证所属权后，方可进行控制。</p>'
                + '<p><b>2. 安全风控</b>：百度账号采用安全风控体系，能够对异常账号进行识别，并对风险账号进行风险预警及封禁措施。</p>'
                + '<p><b>3. 签名校验</b>：设备及应用的关键请求具有签名校验机制，能有效应对重放攻击。</p>',
            },
        ],
    },
    {
        tags: '端云一体数据安全保护',
        key: '5.',
        content: '<h1>5. 端云一体数据安全方案</h1><div class="one">'
        + '<p>涉及敏感信息的采集、处理时，小度充分尊重用户的知情同意权。采集敏感信息时，小度会明确告知用户数据采集的用途及目的。'
        + '处理敏感数据时，小度会采用端云一体数据安全方案。如图9所示，端云一体的安全方案在敏感数据处理的端、边、云等环节均引入机密'
        + '计算技术，实现端、边、云一体化的端到端安全可信。敏感数据在传输、存储和使用的每一个环节均处于加密状态，'
        + '只有授权程序才能处理敏感数据。端云一体的数据安全方案保护敏感数据的同时，也为数据处理者提供“自证清白”的能力。</p>'
        + `<img src="${img9}" alt="9" />`
        + '<p class="centerText">图 9 端云一体的数据安全方案</p>'
        + '<p>本章以“帮助小度改进图像识别”为案例介绍端云一体数据安全方案。如图所示，如果用户愿意帮助小度提升AI算法，'
        + '可在“设置｜隐私设置”中开启“帮助小度改进图像识别”选项开关。当用户开启该能力后，小度设备端可采集图像特征值，'
        + '采用端云一体数据安全方案处理图像特征数据，在小度客户端对数据进行加密，加密后的数据传送至云端。'
        + '在云端采用机密计算技术对密文数据进行模型训练，从而提升小度的AI算法。在本案例中，由于端云一体的数据安全方案，'
        + '图像特征数据在传输、存储和计算等环节均加密状态，最大程度防范敏感数据泄漏风险。</p>'
        + `<img src="${img10}" alt="10" />`
        + '<p class="centerText">图 10 帮助小度改进图像识别</p><p>小度采用端云一体数据安全方案，充分保证用户数据主体权利，'
        + '保证用户数据处理流程的安全合规。端云一体数据安全方案的运行机制如图11所示，核心原理描述如下。</p>'
        + '<p><b>1. 知情同意</b>：用户在设备端的知情同意选择开关会转换为当前设备的数据使用策略。例如，'
        + '如果用户开启了“帮助小度改进图像识别”选项开关，云端管理中心会允许图像识别的AI模型训练程序使用当前设备采集的脱敏特征数据进行模型训练。'
        + '如果用户关闭“帮助小度改进图像识别”选项开关，则云端管理中心将不再允许图像识别的AI模型训练程序访问当前设备采集的脱敏特征数据。</p>'
        + '<p><b>2.PIA评估</b>：用户在设备端的知情同意选择开关会转换为当前设备的数据使用策略。例如，'
        + '如果用户开启了“帮助小度改进图像识别”选项开关，云端管理中心会允许图像识别的AI模型训练程序使用当前设备采集的脱敏特征数据进行模型训练。'
        + '如果用户关闭“帮助小度改进图像识别”选项开关，则云端管理中心将不再允许图像识别的AI模型训练程序访问当前设备采集的脱敏特征数据。</p>'
        + '<p><b>3. 数据采集</b>：小度采集数据后在本地实施脱敏脱密、特征提取等处理操作，然后对脱敏数据进行加密，'
        + '将加密后的数据上传至云端。需要注意的是，小度不会存储和上次来自设备端原始图片。</p><p><b>4. 数据传输</b>：'
        + '传输数据时会采用数字证书、HTTPS等技术实现数据加密传输，极大降低数据传输过程的数据泄漏风险。</p><p><b>5. 数据存储</b>：'
        + '设备端数据传送至云端后，数据始终处于加密状态，只有授权的可信数据处理程序才可解密并处理这些加密的数据。</p><p><b>6. 数据使用</b>：'
        + '小度在云端处理来自设备端的数据时，会采用TEE（可信执行环境）等机密计算技术，经PIA评估的可信数据处理程序会被加载到TEE中运行，'
        + '敏感数据在TEE可信执行环境中被处理。该机制保证数据处理流程的安全合规，同时采用机密计算技术也可降低云端数据泄漏风险。</p>'
        + '<p><b>7. 数据共享</b>：小度不会与第三方共享原始数据，任何未经用户授权的程序均解密云端存储中的加密数据。</p>'
        + '<p><b>8. 数据销毁</b>：数据设置过期时间，超过过期时间后便会自动销毁。</p>'
        + `<img src="${img11}" alt="11" />`
        + '<p class="centerText">图 11 端云一体数据安全方案的运行机制</p><p>通过以上过程，使用端云一体的数据安全方案，'
        + '用户敏感数据在整个生命周期得到了安全有效的保护，同时小度也充分保障用户数据主体权利。希望小度的端云一体数据安全方案在业界推广应用，'
        + '为智能设备的数据安全和隐私保护提供新的解决方案。</p></div>',
    },
    {
        tags: '安全隐私认证',
        key: '6.',
        content: '<h1>6. 安全隐私认证</h1>'
        + '<div class="one"><p>小度持续完善产品和服务的安全和隐私保护功能，优化安全和隐私管理体系。</p>'
        + '<p>隐私保护能力和措施也得到了业界权威的隐私保护认证和社会的认可，体现了专业机构对小度产品和服务的信心，用户可以更加安心。</p>'
        + '<p>1. 2022年6月，通过等级保护三级</p>'
        + '<p>2. 2022年5月，小度智能音箱红外智控版在中国软件评测中心2022年智能音箱安全测评活动中，通过终端、App、通信及用户个人信息4大维度近50项安全检测，荣获“智能音箱安全测评优秀产品证书”</P>'
        + '<p>3. 2022年5月，小度获得由国际权威认证机构BSI授予的ISO/IEC27001信息安全管理体系认证</p>'
        + '<p>4. 2022年5月，小度获得由国际权威认证机构BSI授予的ISO/IEC27701隐私信息管理体系认证</p>'
        + '<p>5. 2022年5月，小度获得由国际权威认证机构BSI授予的ISO/IEC27018公有云个人信息保护管理体系认证</p>'
        + '<p>6. 2021年10月，参与《信息安全技术人脸识别数据安全要求》试点工作</p>'
        + '<p>7. 2021年4月，参与《个人信息安全规范》试点工作</p>'
        + '<p>8. 2018年获得南方都市报个人信息保护研究中心、泰尔实验室等业界权威评测机构的年度隐私保护奖项</p></div>',
    },
    {
        tags: '结语',
        key: ' ',
        content: '<h1>结语</h1>'
        + '<div class="one"><p>小度作为智能设备的领跑者，非常重视用户的安全及隐私保护。从安全管理流'
        + '程到全生命周期的保护框架，都体现了小度遵循“设计入手保护安全”和“默认安全保护”的原则，'
        + '并将该原则融入到产品的研发流程中。同样，基于设备建立了纵深防御方案，实现风险可感知，可'
        + '管理。针对用户敏感数据的处理，提出并实现端云一体的数据安全保护方案，实现“自证清白”的安'
        + '全合规。在未来，小度将持续深耕安全隐私领域，投入并尝试使用更多创新技术和方法来保障产'
        + '品及数据的安全。</p></div>',
    },
];

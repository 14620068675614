import shibingeImg from '@/assets/safeTrust/img_shijianshibinge_1200.png';
import baidudianshiImg from '@/assets/safeTrust/img_shijiandianshi_1200.png';
import shujuguanliImg from '@/assets/safeTrust/img_shijianshuguan_1200.png';
import shuangqindanImg from '@/assets/safeTrust/img_shijianshuangqingdan_1200.png';
import yinsiheguiImg from '@/assets/safeTrust/img_shijianyinsi_1200.png';
import dashujuImg from '@/assets/safeTrust/img_dashuju_1200.png';
import shujubaoxianxiangImg from '@/assets/safeTrust/img_shujubaoxianxiang_1200.png';
import zhinengshujuImg from '@/assets/safeTrust/img_shijianzhineng_1200.png';

export default [
    {
        tags: '史宾格安全及隐私合规平台',
        content: {
            title: '史宾格安全及隐私合规平台',
            text: '基于AI能力的安全/隐私问题检测及动态分析平台。检测内容覆盖隐私政策检测、个人信息收集与使用检测、'
            + '用户权利保障等多重维度，可精准识别App违规风险点、深度挖掘风险产生的源头。助力企业高效低成本地完成App隐私合规自查，提前预警隐私违规风险。',
            morePath: 'https://cloud.baidu.com/product/springer.html',
        },
        img: shibingeImg,
    },
    {
        tags: '双清单服务',
        content: {
            title: '双清单服务',
            text: '双清单服务是一种高效、灵活、可定制的合规义务相关服务，通过后台进行配置管理，'
            + '提供前端展示收集个人信息以及第三方共享个人信息页面，帮助APP开发者遵守关于个人信息保护的法规。',
        },
        img: shuangqindanImg,
    },
    {
        tags: 'APP合规防火墙',
        content: {
            title: 'APP合规防火墙',
            text: 'APP合规防火墙可协助APP开发者确保其应用程序在收集、使用和共享用户隐私数据时符合相关法律法规，'
            + '能够帮助开发者对第三方SDK进行告警和管控，降低合规风险，并保护用户隐私。',
        },
        img: yinsiheguiImg,
    },
    {
        tags: '智能数据合规平台',
        content: {
            title: '智能数据合规平台',
            text: '基于文心一言大模型能力，沉淀百度安全合规建设经验，形成数据安全领域多维智能体。通过构建合规证据链、安全自查/评估、合规风险池等，'
            + '在实现端到端的合规风险治理同时，也让企业具备合规自证能力；有效解决企业在专项合规、例行迎检等场景下响应不及时、合规成本高等问题。'
            + '百度将丰富的甲方安全经验，以“咨询+产品/服务+认证”等方式向合作伙伴输出数据安全合规解决方案，'
            + '结合文心大模型能力，探索在AI时代的数据安全新实践、新范式。',
        },
        img: zhinengshujuImg,
    },
    {
        tags: '数据管理平台',
        content: {
            title: '数据管理平台',
            text: '以数据管理与安全治理为核心，涵盖元数据接入、访问控制、发布、申请审批、智能分类分级等功能，'
            + '实现统一数据目录、权限、流通与评估。支持全面的数据管理与安全合规，实现数据管理「看得见、管得住、理得清」。'
            + '同时，为合作伙伴提供一体化数据安全解决方案，结合文心大模型能力，'
            + '满足政务、金融、广告等行业数据管理需求，探索AI时代数据管理与安全治理的新实践和新范式。',
        },
        img: shujuguanliImg,
    },
    {
        tags: '数据保险箱',
        content: {
            title: '数据保险箱',
            text: '在数据要素市场化背景下，针对拥有敏感数据、重要数据、AI模型等高价值数据资产的企事业单位客户，'
            + '百度数据保险箱（Baidu AI Realm）遵循“数据可见不可用，数据主权可控”原则，提供一体化安全管理系统。'
            + '该系统以“零信任、零改造、全流程”为理念，建立密态数据围栏，确保数据在围栏中密文流通，并基于最细粒度数据权限管控，'
            + '实现进程级动态访问控制，带来“环境可信、数据可动、使用可控”的数据安全新体验。',
        },
        img: shujubaoxianxiangImg,
    },
    {
        tags: '大模型数据安全与隐私保护',
        content: {
            title: '大模型数据安全与隐私保护',
            text: '百度将前沿的数据安全与隐私保护技术与大模型生态深度融合，创新性地构建了Baidu Ai Realm大模型安全技术框架。'
            + '这一框架为百度大模型生态提供了端到端的密态数据管控与安全合规能力，全面覆盖了大模型语料数据的安全管理、'
            + '训练数据的安全管控、推理服务的安全保障、微调数据的安全管理，以及私有化数据资产的保护，贯穿了大模型生命周期的每一个环节。',
        },
        img: dashujuImg,
    },
    {
        tags: '百度隐私计算平台',
        content: {
            title: '百度隐私计算平台',
            text: '百度点石基于联邦学习、多方安全计算、机密计算、数据沙箱等主流隐私计算技术，提供数据安全融合与应用服务，高效实现数据安全流通与数据赋能。',
            morePath: 'https://cloud.baidu.com/product/dsad.html',
        },
        img: baidudianshiImg,
    },
];

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-root-27807{background-color:transparent;height:.6rem;justify-content:space-between;left:0;min-width:825px;padding:0 .3rem;position:fixed;top:0;width:100%;z-index:999}.header-root-27807,.header-root-27807 .header-leftLogo-487a2{align-items:center;display:flex}.header-root-27807 .header-leftLogo-487a2{justify-content:center}.header-root-27807 .header-leftLogo-487a2 img{min-width:115px;width:2.48rem}.header-root-27807 .header-rightMenu-b1b3a{min-width:300px}.header-root-27807 .header-rightMenu-b1b3a li{display:inline-block;line-height:20px;text-align:center;width:.9rem}.header-root-27807 .header-rightMenu-b1b3a li:not(:last-child){margin-right:.3rem}.header-root-27807 .header-rightMenu-b1b3a li a{color:#8f96a9}.header-root-27807 .header-rightMenu-b1b3a li a:hover{color:#d7dbe5}.header-root-27807 .header-rightMenu-b1b3a li a.active{color:#fff!important}.header-root-27807.header-bgcBlack-66d02{background-color:#000!important}.header-banner-common-text-6b85d{color:#fff}.header-banner-common-text-6b85d h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.header-banner-common-text-6b85d p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "header-root-27807",
	"leftLogo": "header-leftLogo-487a2",
	"rightMenu": "header-rightMenu-b1b3a",
	"bgcBlack": "header-bgcBlack-66d02",
	"banner-common-text": "header-banner-common-text-6b85d",
	"bannerCommonText": "header-banner-common-text-6b85d"
};
export default ___CSS_LOADER_EXPORT___;

import React, {MouseEventHandler} from 'react';

interface Props {
    contentConfig: $tagsConfigType;
    selectClassNameFn: (params: number) => string;
    className?: string;
    children?: React.ReactNode | ((tags: string) => React.ReactNode);
    activeIndex?: number;
    hoverEventHandler?: MouseEventHandler;
}

function TagsButton({
    contentConfig,
    selectClassNameFn,
    className = '',
    children,
    activeIndex,
    hoverEventHandler,
}: Props) {
    return (
        <>
            {contentConfig.map(({tags, icon, pressedIcon}, index) => {
                const selectedClassName = selectClassNameFn(index);
                const key = tags + '_' + index.toString();
                return (
                    tags && (
                        <div
                            key={key}
                            data-index={index}
                            className={`tags${index} ${className} ${selectedClassName}`}
                            style={{borderRadius: '2px', cursor: 'pointer'}}
                            onMouseEnter={hoverEventHandler}
                            onMouseLeave={hoverEventHandler}
                        >
                            {typeof children === 'function' ? (
                                children(tags)
                            ) : (
                                <>
                                    <i
                                        className="icon"
                                        style={{
                                            backgroundImage: `url(${
                                                selectedClassName || activeIndex === index
                                                    ? pressedIcon
                                                    : icon
                                            })`,
                                        }}
                                    />
                                    <span className="text">
                                        {tags}
                                    </span>
                                </>
                            )}
                        </div>
                    )
                );
            })}
        </>
    );
}
export default React.memo(TagsButton);

import React from 'react';

import Card from '@/components/Card';

import c from './index.less';

const contentConfig = [
    {
        title: '《小度安全技术白皮书》',
        text: '规范安全管理要求，建立覆盖“端-管-云”的全生命周期安全保障框架，建设围绕设备的纵深防护体系，结合端云一体数据安全方案及安全隐私认证，保障设备安全与用户数据安全。',
    },
    {
        title: '《小度隐私保护白皮书》',
        text: '展示小度在隐私保护所做的持续努力，解读支撑隐私保护的安全能力及实践积累，为语音助手生态各参与方提供参考，为用户提供更安全放心的产品和服务。',
    },
    {
        title: '《百度移动应用平台年度隐私安全报告》',
        text: '为用户提供全生命周期安全运营机制，提供安全可信赖的分发服务；为开发者搭建绿色安全交互枢纽，促进各方协力共治、合作共赢。',
    },
    {
        title: '《史宾格产品技术白皮书》',
        text: '史宾格基于AI能力为App提供隐私合规风险检测服务，精准识别App违规风险，助力企业高效低成本的完成App隐私合规自查，满足监管要求。',
    },
];

function ResourceCenter() {
    return (
        <Card title="资源中心" className={c.card} whiteTitle>
            <div className={c.contentContainer}>
                {contentConfig.map((item, index) => (
                    <a href={`/resourceCenter/whiteBook?id=${index}`} key={item.title} target="_blank" rel="noreferrer">
                        <div className={c.contentItemBox}>
                            <h2 className={c.title}>{item.title}</h2>
                            <div className={c.content}>
                                <p className={c.text}>{item.text}</p>
                                <div className={c.lookDetail}>点击查看</div>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </Card>
    );
}
export default React.memo(ResourceCenter);

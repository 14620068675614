import React from 'react';

import Banner from '@/components/Banner';

import bannerImg from '@/assets/img/banner_anquankexin_1920.png';

export default function SafeBanner() {
    return (
        <Banner
            imgSrc={bannerImg}
            title="安全"
            text={
                <>
                    以保障数据始终处于安全可信的状态为目标、构建了基于实战攻防验证、
                    <br />
                    7*24小时闭环运营的纵深防护安全体系。
                </>
            }
        />
    );
}

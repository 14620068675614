import React from 'react';

import Banner from '@/components/Banner';

import lianxiwomenImg from '@/assets/img/banner_lianxiwomen_1920.png';

import c from './index.less';

export default function ContactBanner() {
    return (
        <Banner
            className={c.banner}
            imgSrc={lianxiwomenImg}
            title="联系我们"
            text={
                <>
                    如果您对百度有任何建议，通过以下方式与我们反馈，
                    <br />
                    期待您的声音。
                </>
            }
            cardStyle={{paddingTop: '1.2rem', paddingBottom: '1rem'}}
        />
    );
}

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contact-banner-banner-31bc0{height:3.6rem}.contact-banner-banner-common-text-e4e52{color:#fff}.contact-banner-banner-common-text-e4e52 h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.contact-banner-banner-common-text-e4e52 p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "contact-banner-banner-31bc0",
	"banner-common-text": "contact-banner-banner-common-text-e4e52",
	"bannerCommonText": "contact-banner-banner-common-text-e4e52"
};
export default ___CSS_LOADER_EXPORT___;

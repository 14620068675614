import chanyelianmengImg from '@/assets/img/chanyelianmeng.png';
import youxiuhuiyuanImg from '@/assets/img/youxiuhuiyuan.png';
import tuchugongxianImg from '@/assets/img/tuchugongxian.png';
import youxiuchengyuanImg from '@/assets/img/youxiuchengyuan.png';
import shubohuiImg from '@/assets/complianceEnable/pic1.png';
import jinglingguangbeiImg from '@/assets/complianceEnable/pic2.png';
import shanghaishiweiImg from '@/assets/complianceEnable/pic3.png';
import shujuguanlishidaImg from '@/assets/complianceEnable/pic4.png';
import shujuanquanhegerenxinxiImg from '@/assets/complianceEnable/pic5.png';

export default [
    {
        title: (<p className="margin16">2024年数博会<br />优秀科技成果</p>),
        description: '中国互联网协会',
        descriptionClass: 'paddingBottom28',
        img: shubohuiImg,
        height: '6rem',
    },
    {
        title: (<p className="margin16">首届“金灵光杯”中国互联网<br />创新大赛数据安全赛道二等奖</p>),
        description: '中国互联网协会',
        descriptionClass: 'paddingBottom28',
        img: jinglingguangbeiImg,
        height: '6rem',
    },
    {
        title: (<p className="margin16">2023年上海市委网信办<br />网络安全技术支撑单位</p>),
        description: '中共上海市委网络安全和信息化委员会办公室',
        descriptionClass: 'paddingBottom28',
        img: shanghaishiweiImg,
        height: '6rem',
    },
    {
        title: (<p className="margin16">2023年数据管理<br />十大名牌企业</p>),
        description: '中国电子信息行业联合会',
        descriptionClass: 'paddingBottom28',
        img: shujuguanlishidaImg,
        height: '6rem',
    },
    {
        title: (<p className="margin16">数据安全和个人信息保护<br />社会责任评价三星</p>),
        description: 'CCIA数据安全工作委员会',
        descriptionClass: 'paddingBottom28',
        img: shujuanquanhegerenxinxiImg,
        height: '6rem',
    },
    {
        title: <p className="margin30">技术支持单位</p>,
        description: '中国网络安全产业联盟',
        img: chanyelianmengImg,
        width: '6.8rem',
    },
    {
        title: <p className="margin30">2022年度优秀会员单位</p>,
        description: '中国网络安全产业联盟',
        img: youxiuhuiyuanImg,
        width: '6.8rem',
    },
    {
        title: <p className="margin30">2022年度突出贡献奖</p>,
        description: '中国网络安全产业联盟数安委',
        img: tuchugongxianImg,
        height: '6rem',
    },
    {
        title: <p className="margin16">2022年度优秀<br />成员单位</p>,
        description: '大数据技术标准推进委员会',
        descriptionClass: 'paddingBottom28',
        img: youxiuchengyuanImg,
        width: '6.8rem',
    },
];

import baiduAppImg from '@/assets/img/img_baiduapp.png';
import baidudituImg from '@/assets/img/img_baidudituapp.png';
import baiduwangpanImg from '@/assets/img/img_baiduwangpanapp.png';
import baidutiebaImg from '@/assets/img/img_baidutiebaapp.png';
import xiaoduImg from '@/assets/img/img_xiaoduapp.png';
import haokanImg from '@/assets/img/img_haokanshipinapp.png';
import baidushurufaImg from '@/assets/img/img_baidushurufaapp.png';

export default [
    {
        title: '百度APP',
        icon: baiduAppImg,
        link: 'https://s.bdstatic.com/common/agreement/privacy.html',
    },
    {
        title: '百度地图',
        icon: baidudituImg,
        link: 'https://map.baidu.com/zt/client/privacy/index.html',
    },
    {
        title: '百度网盘',
        icon: baiduwangpanImg,
        link: 'https://pan.baidu.com/disk/main?_at_=1691485011738#/protocol/privacy_policy',
    },
    {
        title: '百度贴吧',
        icon: baidutiebaImg,
        link: 'https://tieba.baidu.com/tb/cms/tieba-fe/tieba_promise.html',
    },
    {
        title: '小度',
        icon: xiaoduImg,
        link: 'https://xiaodu.baidu.com/webssr/protocol?id=e02b4c0b-79f8-4cf1-a2ff-1b2aca3c40a2',
    },
    {
        title: '好看视频',
        icon: haokanImg,
        link: 'https://haokan.baidu.com/videoui/page/about#privacy',
    },

    {
        title: '百度输入法',
        icon: baidushurufaImg,
        link: 'https://srf.baidu.com/privacy/index.html',
    },
];

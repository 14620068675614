import ISOIEC27017Img from '@/assets/img/img_ISOIEC 27017.png';
import ISOIEC27018Img from '@/assets/img/img_ISOIEC 27018.png';
import ISOIEC27701Img from '@/assets/img/img_ISOIEC 27701.png';
import ISOIEC29151Img from '@/assets/img/img_ISOIEC 29151.png';
import BSImg from '@/assets/img/img_BS 10012.png';
import PCIImg from '@/assets/img/img_PCI DSS.png';
import SOCImg from '@/assets/img/img_SOC.png';
import kexinyunImg from '@/assets/img/img_kexinyun.png';
import dsmmImg from '@/assets/img/img_DSMM.png';
import anquandengjiImg from '@/assets/img/img_anquandengji.png';
import gerenxinxiImg from '@/assets/img/img_gerenxinxi.png';
import shujuanquanguanliImg from '@/assets/complianceEnable/pic_DSM.png';

export default [
    {
        tags: 'ISO/IEC 27017',
        icon: ISOIEC27017Img,
        showApproveIcon: true,
        content: (
            <>
                ISO/IEC
                27017作为一项国际标准，为云服务提供商和云服务用户提供了关于信息安全控制的指导。
                <br />
                百度获得该证书，证明了百度建立完善的信息安全管理控制，具备信息资产的保护高水平，增强数据保护和隐私保护，确保云环境中的合规性。
            </>
        ),
    },
    {
        tags: 'ISO/IEC 27018',
        icon: ISOIEC27018Img,
        showApproveIcon: true,
        content: (
            <>
                ISO/IEC
                27018是一项专注于云环境下个人数据保护的国际行为准则。该标准针对云服务提供商制定了具体的要求和措施，以保护用户的个人数据免受滥用、未经授权使用或未经同意的用途。
                <br />
                百度获得该证书，证明了百度在云服务提供和个人数据保护方面具备了可靠的安全保障能力。
            </>
        ),
    },
    {
        tags: 'ISO/IEC 27701',
        icon: ISOIEC27701Img,
        showApproveIcon: true,
        content: (
            <>
                ISO/IEC
                27701是一项国际管理系统标准体系，专注于个人信息管理体系和隐私信息管理体系，为组织提供管理个人信息和保护隐私的指导。
                <br />
                百度获得该证书，证明百度在隐私保护方面的流程、技术和人员管理等方面符合了该国际标准的要求，进一步证明百度个人隐私和数据安全保护能力处于全球领先地位。
            </>
        ),
    },
    {
        tags: 'ISO/IEC 29151',
        icon: ISOIEC29151Img,
        showApproveIcon: true,
        content: (
            <>
                ISO/IEC
                29151是针对保护个人可识别信息（PII）风险和影响评估制定的国际标准，旨在确保个人信息得到妥善保护并减轻相关风险和影响。
                <br />
                百度获得该证书，证明百度在个人可识别信息保护方面已经具备了必要的措施和管理体系，确保为用户提供一个可信赖的安全环境，保障个人信息隐私和数据安全。
            </>
        ),
    },
    {
        tags: 'BS 10012',
        icon: BSImg,
        showApproveIcon: true,
        content: (
            <>
                BS
                10012是由英国标准协会（BSI）发布的个人信息管理体系标准，标准为个人信息管理提供了一个符合欧盟通用数据保护条例（GDPR）原则的最佳实践框架。
                <br />
                百度获得该证书，证明百度已建立完善的个人信息保护体系，并在采用符合国际标准的最佳实践确保用户个人隐私和数据的安全性。
            </>
        ),
    },
    {
        tags: 'PCI DSS',
        icon: PCIImg,
        showApproveIcon: true,
        content: (
            <>
                PCI
                DSS是由全球支付卡行业的主要品牌组织共同制定的一项针对支付卡行业制定的数据安全国际标准，旨在保护持卡人数据的安全性，并确保商家在处理支付卡数据时采取适当的安全措施。
                <br />
                百度获得该证书，证明百度在保护持卡人数据和敏感验证信息方面符合全球标准，可保障持卡人数据的机密性、完整性、可用性。
            </>
        ),
    },
    {
        tags: 'SOC',
        icon: SOCImg,
        showApproveIcon: true,
        content: (
            <>
                SOC审计是由第三方审计机构根据美国注册会计师协会（AICPA）制定的标准进行的独立审计，旨在评估服务机构的信息安全管理和内部控制情况。SOC证书分为三个类型：SOC
                1、SOC 2和SOC 3。
                <br />
                百度已获得SOC三项审计报告，证明百度在信息安全管理方面的安全能力，可确保用户数据得到妥善的安全保护和处理。
            </>
        ),
    },
    {
        tags: '网络安全等级保护',
        icon: anquandengjiImg,
        content: (
            <>
                网络安全等级保护是中国国家标准化管理委员会发布的一项重要安全标准，旨在规范和保护网络信息系统的安全，防范网络安全威胁和风险。
                <br />
                百度多个业务系统通过了等级保护备案和测评，证明百度履行《网络安全法》要求的企业责任以及百度在网络安全等级保护方面的实践和努力。
            </>
        ),
    },
    {
        tags: '个人信息保护影响评估（PIA）',
        icon: gerenxinxiImg,
        content: (
            <>
                根据《中华人民共和国个人信息保护法》规定，个人信息处理者应落实并记录个人信息保护影响评估。个人信息保护影响评估不仅是个人信息处理者履行合规义务的核心内容，也有助于提高社会对个人信息处理活动的信任度。
                <br />
                百度通过了CCIA的PIA评估，意味着百度在个人信息保护方面遵守了国家相关要求，切实保护了用户的合法权益，为用户提供了更可靠的数据保护和隐私安全保障。
            </>
        ),
    },
    {
        tags: '数据安全管理认证（DSM）',
        icon: shujuanquanguanliImg,
        content: (
            <>
                数据安全管理认证是根据《中华人民共和国网络安全法》《中华人民共和国数据安全法》《中华人民共和国个人信息保护法》《中华人民共和国认证认可条例》有关规定，
                国家市场监督管理总局、国家互联网信息办公室开展的国推认证，鼓励网络运营者通过认证方式规范网络数据处理活动，加强网络数据安全保护。
                <br />
                百度通过了数据安全管理认证，证明百度在数据安全能力方面具备了相当高的成熟度水平。
            </>
        ),
    },
    {
        tags: '数据安全能力成熟度认证（DSMM）',
        icon: dsmmImg,
        content: (
            <>
                数据安全能力成熟度模型（Data Security Capability Maturity
                Mode,简称DSMM）是一种衡量组织数据安全能力成熟度水平的国内标准。该模型将数据安全能力划分为五个级别，从一级到五级，用于评估组织在数据安全方面的成熟度。
                <br />
                百度通过了DSMM第四级认证，证明百度在数据安全能力方面具备了相当高的成熟度水平。作为国内唯一通过DSMM四级认证的企业，百度在数据安全管理方面展示了其卓越的实践和措施。
            </>
        ),
    },
    {
        tags: '移动互联网应用程序（APP）用户权益保护测试能力验证结果证书',
        icon: null,
        content: (
            <>
                史宾格安全及隐私合规检测平台通过了中国信息通信研究院组织的2022年度移动互联网应用程序（APP）用户权益保护测试能力验证项目。
                <br />
                自2021年创办此项考核起，史宾格已连续两年获得该能力验证结果证书。证明了史宾格在用户权益保护方面的优秀实践和能力，为用户提供可信赖的安全及隐私合规检测服务。
            </>
        ),
    },
    {
        tags: '可信云服务用户数据保护能力评估',
        icon: kexinyunImg,
        content: (
            <>
                云服务数据保护能力评估是一项国内的可信云安全类专项评估，旨在评估云服务提供商对用户数据安全的保护能力。该评估机制涵盖了事前防范、事中保护和事后追溯三个层面的关键指标范围。
                <br />
                百度已通过可信云用户数据保护能力评估认证，证明了百度可为用户的数据安全提供可靠保障，确保用户的数据得到安全保护和处理。
            </>
        ),
    },
];

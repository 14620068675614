import React from 'react';

import Card from '@/components/Card';
import ditu from '@/assets/img/bg_ditu_1200.png';

import c from './index.less';

export default function Address() {
    return (
        <Card className={c.card}>
            <div className={c.contentContainer}>
                <h1 className={c.title}>北京百度网讯科技有限公司</h1>
                <div className={c.contentBox}>
                    <div className={c.flexBox}>
                        <div>
                            <p>地址：北京市海淀区上地十街10号百度大厦</p>
                            <p>邮 编：100085</p>
                        </div>
                        <div>
                            <p>总机：(+86 10)5992 8888</p>
                            <p>传真：(+86 10)5992 0000</p>
                        </div>
                    </div>
                    <div className={c.flexBox}>
                        <div className={c.address}>
                            <p>
                                Address: Baidu Campus, No. 10 Shangdi 10th Street, Haidian District,
                                Beijing, China
                            </p>
                            <p>Post Code：100085</p>
                        </div>
                        <div>
                            <p>TEL: (+86 10)5992 8888</p>
                            <p>FAX: (+86 10)5992 0000</p>
                        </div>
                    </div>
                </div>
                <img className={c.img} src={ditu} alt="地图位置" />
            </div>
        </Card>
    );
}

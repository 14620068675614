/* eslint-disable react/jsx-no-bind */
import React from 'react';

import {Link} from 'react-router-dom';

import safeTrustImg from '@/assets/img/img_anquankexin_386.png';
import privateImg from '@/assets/img/img_yinsitouming_386.png';
import complianceImg from '@/assets/img/img_heguifuneng_386.png';

import Card from '@/components/Card';

import c from './index.less';

const Content = ({
    title,
    text,
    path,
    img,
}: {
    title: string;
    text: string;
    path: string;
    img: string;
}) => {
    return (
        <Link to={path} className={c.link}>
            <div className={c.contentBox} style={{backgroundImage: `url(${img})`}}>
                <div className={`hoverBox ${c.textBox}`}>
                    <h2 className={c.title}>{title}</h2>
                    <p className={c.text}>{text}</p>
                    <span className={c.lookMore}>
                        查看更多
                    </span>
                </div>
            </div>
        </Link>
    );
};

function ReallyTrust() {
    return (
        <Card title="数据隐私保护价值观" className={c.card}>
            <div className={c.contentContainer}>
                <Content
                    title="安全"
                    text="以保障数据始终处于安全可信的状态为目标、构建了基于实战攻防验证、7*24小时闭环运营的纵深防护安全体系。"
                    path="/organization"
                    img={safeTrustImg}
                />
                <Content
                    title="隐私"
                    text="以用户可理解的方式明确个人信息处理规则，承担企业责任，同时为用户提供简单实用的隐私实现方案。"
                    path="/policy"
                    img={privateImg}
                />
                <Content
                    title="合规"
                    text="主动遵从法律法规及权威标准认证，打造适用行业及客户需求的最佳实践，并积极加强合作赋能，共同应对安全及隐私保护挑战。"
                    path="/control"
                    img={complianceImg}
                />
            </div>
        </Card>
    );
}
export default React.memo(ReallyTrust);

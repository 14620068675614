import React, {useMemo} from 'react';

import {useSearchParams} from 'react-router-dom';
import Card from '@/components/Card';

import WhiteBook from '@/components/WhiteBook';
import xiaoduyinsiConfig from './xiaoduyinsi';
import xiaoduanquanConfig from './xiaoduanquan';
import yidongyingyongConfig from './yidongyingyong';

import shibingeConfig from './shibinge';
import c from './index.less';

const configMap: Record<string, any> = {
    '0': {
        title: '小度安全技术白皮书',
        date: '2022 年 6 月',
        configs: xiaoduanquanConfig,
    },
    '1': {
        title: '小度隐私保护白皮书',
        date: '2022 年 9 月',
        configs: xiaoduyinsiConfig,
    },
    '2': {
        title: '百度移动应用平台年度隐私安全报告',
        date: '2022 年',
        configs: yidongyingyongConfig,
    },
    '3': {
        title: '史宾格产品技术白皮书',
        date: '2024 年 8 月',
        configs: shibingeConfig,
    },
};

export default function BaiPiShuPage() {
    const [params] = useSearchParams();
    const treeData = useMemo(
        () => {
            const id = params.get('id');
            return configMap[id as any] || configMap['0'];
        },
        [params]
    );

    return (
        <Card
            className={c('card', {shiBinGe: params.get('id') === '3'})}
            title={treeData.title}
            description={treeData.date}
        >
            <WhiteBook treeData={treeData} />
        </Card>
    );
}

import React from 'react';

import Card from '@/components/Card';

import certificateConfig from './certificate';

import c from './index.less';

export default function Honor() {
    const [currentItem, setCurrentItem] = React.useState<(typeof certificateConfig)[0] | null>(
        null
    );

    const onClick = (item: (typeof certificateConfig)[0]) => {
        setCurrentItem(item);
    };

    return (
        <Card
            className={c.card}
            description="百度多个产品和服务通过国家权威机构评测，获得星级、优秀会员、突出贡献单位等安全领域荣誉奖项"
            title={<span style={{color: '#fff'}}>荣誉奖项</span>}
        >
            <div className={c.contentContainer}>
                {certificateConfig.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className={c.itemContainer} onClick={() => onClick(item)}>
                        <div className={c('descriptionBox', item.descriptionClass)}>
                            <h1 className={c.title}>{item.title}</h1>
                            <span className={c.description}>{item.description}</span>
                        </div>
                        <button className={c.lookBtn}>
                            查看证书<i className={c.icon}></i>
                        </button>
                    </div>
                ))}
            </div>
            {currentItem ? (
                <div className={c.mask} onClick={() => setCurrentItem(null)}>
                    <img
                        style={{
                            width: currentItem.width || 'auto',
                            height: currentItem.height || 'auto',
                        }}
                        src={currentItem.img}
                        alt="证书图片"
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            ) : null}
        </Card>
    );
}

import React from 'react';

import ScrollTab from '@/components/ScrollTab';

import Card from '@/components/Card';
import contentConfig from './contentConfig';

import c from './index.less';

export default function PrivacyPolicyContent() {
    return (
        <Card title="百度隐私政策总则" className={c.card}>
            <ScrollTab contentConfig={contentConfig} />
        </Card>
    );
}

import React from 'react';

import {aboutLink, commonLink} from '@/links/footer';

import c from './index.less';

export default function Footer() {
    return (
        <div className={c.root}>
            <div className={c.contentContainer}>
                <div>
                    {aboutLink.map(item => (
                        <a key={item.label} href={item.link} target="_blank" rel="noreferrer">
                            {item.label}
                        </a>
                    ))}
                </div>
                <div>
                    {commonLink.map(item => (
                        <a key={item.label} href={item.link} target="_blank" rel="noreferrer">
                            {item.label}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

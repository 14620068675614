
        // class-names-loader generated code for /Users/baidu/Desktop/project/trust-center/src/modules/PrivacyPolicy/AppPrivacyPolicy/index.less
        import classNames from "!../../../../node_modules/classnames/bind";
        import locals from "!!../../../../node_modules/style-loader/dist/cjs.js!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[1].use[2]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[1].use[3]!../../../../node_modules/less-loader/dist/cjs.js??ruleSet[1].rules[3].oneOf[1].use[4]!./index.less";
        import assign from "!../../../../node_modules/@ecomfe/class-names-loader/dist/assign.js";

        var css = classNames.bind(locals);
        assign(locals, css);

        export default css;
    
import React from 'react';

import Card from '@/components/Card';
import useTagsCheck from '@/hook/useTagsCheck';
import TagsButton from '@/components/TagsButton';

import tagsConfig from './tags';

import c from './index.less';

export default function PrivacyProject() {
    const [currentTags, onMouseEventHandler, selectClassNameFn, hoverIndex] = useTagsCheck(
        tagsConfig,
        c.selected
    );
    return (
        <Card className={c.card} title="隐私保护原则">
            <div className={c.contentContainer}>
                <div
                    className={c.tagsBtnContainer}
                    onMouseDown={onMouseEventHandler}
                >
                    <TagsButton
                        className={c.tagsBtn}
                        contentConfig={tagsConfig}
                        selectClassNameFn={selectClassNameFn}
                        hoverEventHandler={onMouseEventHandler}
                        activeIndex={hoverIndex}
                    />
                </div>
                <div
                    className={c.tagsContentContainer}
                    style={{backgroundImage: `url(${currentTags.content.img})`}}
                >
                    <div className={c.textContainer}>
                        <h1 className={c.title}>{currentTags.content.title}</h1>
                        <p className={c.text}>{currentTags.content.text}</p>
                    </div>
                </div>
            </div>
        </Card>
    );
}

import {
    useState,
    MouseEvent,
    MouseEventHandler,
    useCallback,
    useEffect,
    useMemo,
    SetStateAction,
    Dispatch,
} from 'react';

const useTagsCheck = (
    contentConfig: $tagsConfigType,
    selectedClassName: string
): [
    state: (typeof contentConfig)[0],
    tagButtonClick: MouseEventHandler,
    onSelectCall: (index: number) => string,
    hoverIndex: number,
    contentIndex: number,
    setContentIndex: Dispatch<SetStateAction<number>>
] => {
    // 需要展示的内容的下标
    const [currentIndex, setContentIndex] = useState(0);
    // 下标
    const [hoverIndex, setHoverIndex] = useState(-1);

    // 取到下标找到对应该展示的内容
    const tagButtonEventHandler = (e: MouseEvent<HTMLDivElement>) => {
        let index = parseFloat((e.target as HTMLDivElement).getAttribute('data-index')!);
        if (!index && index !== 0) {
            index = parseFloat(
                ((e.target as HTMLDivElement).parentNode as HTMLDivElement).getAttribute(
                    'data-index'
                )!
            );
        }
        if (e.type === 'mouseenter') {
            setHoverIndex(index);
        } else if (e.type === 'mouseleave') {
            setHoverIndex(-1);
        } else {
            setContentIndex(index);
        }
        return index;
    };

    // 是否是选中状态，由于各模块选中状态样式不同，所以使用父定义的className。
    const onSelectCall = useCallback(
        (index: number) => {
            const isSelect = currentIndex === index;
            if (isSelect) {
                return selectedClassName;
            } else {
                return '';
            }
        },
        [currentIndex, selectedClassName]
    );

    useEffect(
        () => {
            setContentIndex(0);
        },
        [contentConfig]
    );

    const currentTags = useMemo(
        () => {
            if (contentConfig[currentIndex]) {
                return contentConfig[currentIndex];
            } else {
                setContentIndex(0);
                return contentConfig[0];
            }
        },
        [contentConfig, currentIndex]
    );

    return [
        currentTags,
        tagButtonEventHandler,
        onSelectCall,
        hoverIndex,
        currentIndex,
        setContentIndex,
    ];
};

export default useTagsCheck;

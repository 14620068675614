import React from 'react';

import HomeBannerImg from '@/assets/home/banner_shouye_1920.png';
import TextSvg from '@/assets/svg/pic_wording.svg?react';

import Banner from '@/components/Banner';

import c from './index.less';

function HomeBanner() {
    return (
        <Banner imgSrc={HomeBannerImg} cardStyle={{paddingTop: '1.6rem', paddingBottom: '2.2rem'}}>
            <div className={c.textContainer}>
                <TextSvg />
            </div>
        </Banner>
    );
}
export default React.memo(HomeBanner);

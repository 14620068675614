import {ReactNode} from 'react';

import Home from '@/modules/Home';

import SafeTrust from '@/modules/SafeTrust';
import PrivacyTransparency from '@/modules/PrivacyTransparency';
import ComplianceEnable from '@/modules/ComplianceEnable';
import ContactUs from '@/modules/ContactUs';
import ChildInformationSafe from '@/modules/ChildInformationSafe';
import PrivacyPolicy from '@/modules/PrivacyPolicy';
import ResourceCenterBaiPiShu from '@/modules/BaiPishu';
import SocialResponsibilityReport from '@/modules/SocialResponsibilityReport';

const CONSTANT_ROUTER: $routerConfigsType = [
    {
        index: true,
        title: '首页',
        path: '/',
        exact: true,
    },
    {
        title: '安全',
        path: '/organization',
    },
    {
        title: '隐私',
        path: '/policy',
    },
    {
        title: '合规',
        path: '/control',
    },
    {
        title: '联系我们',
        path: '/faq',
    },
    {
        title: '隐私政策总则',
        path: '/policy/PrivacyPolicy',
        isNotNavItem: true,
    },
    {
        title: '儿童个人信息保护规则',
        path: '/policy/children-privacy-policy/child',
        isNotNavItem: true,
    },
    {
        title: '百度个人信息保护社会责任报告',
        path: '/organization/reports',
        isNotNavItem: true,
    },
    {
        title: '白皮书页面',
        path: '/resourceCenter/whiteBook',
        isNotNavItem: true,
    },
];

const CONSTANT_ROUTER_ELEMENT: $objPropertyType<ReactNode> = {
    '/': <Home />,
    '/organization': <SafeTrust />,
    '/policy': <PrivacyTransparency />,
    '/control': <ComplianceEnable />,
    '/faq': <ContactUs />,
    '/policy/PrivacyPolicy': <PrivacyPolicy />,
    '/policy/children-privacy-policy/child': <ChildInformationSafe />,
    '/resourceCenter/whiteBook': <ResourceCenterBaiPiShu />,
    '/organization/reports': <SocialResponsibilityReport />,
};

export {CONSTANT_ROUTER, CONSTANT_ROUTER_ELEMENT};

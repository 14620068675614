import {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from '@/modules/App';
import '@/styles';

const root = document.body.appendChild(document.createElement('div'));
root.style.height = '100vh';

ReactDOM.createRoot(root).render(
    <StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </StrictMode>
);

import React from 'react';

import ReportContent from './ReportContent';

export default function SocialResponsibilityReport() {
    return (
        <>
            <ReportContent />
        </>
    );
}

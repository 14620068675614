import React from 'react';

import c from './index.less';

interface Props {
    text: string;
    topImg: string;
    bottomImg: string;
    className?: string;
}

function QuotesBgCard({text, topImg, bottomImg, className = ''}: Props) {
    return (
        <div className={`${c.textBox} ${className}`}>
            <div
                className={c.backgroundImgBox}
                style={{
                    backgroundImage: `url(${topImg}),
            url(${bottomImg})`,
                }}
            >
                <p className={c.text}>{text}</p>
            </div>
        </div>
    );
}
export default React.memo(QuotesBgCard);

import React from 'react';

import Card from '@/components/Card';
import TagsButton from '@/components/TagsButton';

import useTagsCheck from '@/hook/useTagsCheck';

import tagsConfig from './tags';

import c from './index.less';

export default function BestPractices() {
    const [currentTags, tagsOnClick, selectClassNameFn] = useTagsCheck(tagsConfig, c.selected);

    return (
        <Card className={c.card} title={<span style={{color: '#fff'}}>最佳实践</span>}>
            <div
                className={c.contentContainer}
                style={{backgroundImage: `url(${currentTags.img})`}}
            >
                <div className={c.tagsButtonContainer} onMouseDown={tagsOnClick}>
                    <TagsButton
                        className={c.tagsButton}
                        contentConfig={tagsConfig}
                        selectClassNameFn={selectClassNameFn}
                    />
                </div>
                <div className={c.tagsContentContainer}>
                    <div className={c.tagsContentBox}>
                        <h1 className={c.title}>{currentTags.content.title}</h1>
                        <p className={c.text}>{currentTags.content.text}</p>
                        {currentTags.content.morePath ? (
                            <a
                                className={c.more}
                                href={currentTags.content.morePath}
                                target="_blank"
                                rel="noreferrer"
                            >
                                了解更多
                            </a>
                        ) : null}
                    </div>
                </div>
            </div>
        </Card>
    );
}

import React from 'react';

import Card from '@/components/Card';

import config from './config';

import c from './index.less';

export default function AppPrivacyPolicy() {
    return (
        <Card title="产品隐私政策" className={c.card}>
            <div className={c.contentContainer}>
                {config.map(item => (
                    <a key={item.title} href={item.link} target="_blank" rel="noreferrer">
                        <div className={c.itemBox}>
                            <img className={c.img} src={item.icon} alt={item.title} />
                            <h1 className={c.title}>{item.title}</h1>
                        </div>
                    </a>
                ))}
            </div>
        </Card>
    );
}

import React from 'react';

import Card from '@/components/Card';

import config from './config';

import c from './index.less';

export default function FeedBack() {
    return (
        <Card className={c.card}>
            <div className={c.contentContainer}>
                {config.map(item => (
                    <div key={item.title} className={c.itemContainer}>
                        <div className={c.contentBox}>
                            <div className={c.titleContainer}>
                                <div className={c.titleBox}>
                                    <img
                                        className={c[item.iconClassName || 'icon']}
                                        src={item.icon}
                                        alt="图片"
                                    />
                                    <h1 className={c.title}>{item.title}</h1>
                                </div>
                            </div>
                            <div className={c.descriptionBox}>
                                <p className={c.description}>{item.description}</p>
                                <a className={c.btn} href={item.buttonLink} target="_blank" rel="noreferrer">
                                    {item.buttonText}
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Card>
    );
}

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".safe-response-card-e9e49{background-color:#f1f7ff}.safe-response-card-e9e49 .safe-response-contentContainer-cca0a{align-items:auto;display:flex;justify-content:auto}.safe-response-card-e9e49 .safe-response-contentContainer-cca0a .safe-response-img-f295e{height:4.27rem}.safe-response-card-e9e49 .safe-response-contentContainer-cca0a .safe-response-textBox-93add{padding-left:1rem;padding-right:0}.safe-response-card-e9e49 .safe-response-contentContainer-cca0a .safe-response-textBox-93add>div{color:#000;padding-top:.84rem}.safe-response-banner-common-text-1534a{color:#fff}.safe-response-banner-common-text-1534a h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.safe-response-banner-common-text-1534a p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "safe-response-card-e9e49",
	"contentContainer": "safe-response-contentContainer-cca0a",
	"img": "safe-response-img-f295e",
	"textBox": "safe-response-textBox-93add",
	"banner-common-text": "safe-response-banner-common-text-1534a",
	"bannerCommonText": "safe-response-banner-common-text-1534a"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".address-card-856e4{background-color:#f1f7ff;color:#000;padding-bottom:.8rem;padding-top:.6rem}.address-card-856e4 .address-contentContainer-25ecd .address-title-ac700{font-size:32px;font-weight:500;line-height:40px;margin-bottom:.4rem}.address-card-856e4 .address-contentContainer-25ecd .address-contentBox-5cbe3{align-items:flex-start;display:flex;justify-content:space-between;margin-bottom:.4rem}.address-card-856e4 .address-contentContainer-25ecd .address-contentBox-5cbe3 .address-flexBox-47102{align-items:flex-start;display:flex;flex-direction:column;height:1.4rem;justify-content:space-between}.address-card-856e4 .address-contentContainer-25ecd .address-contentBox-5cbe3 .address-flexBox-47102:last-child{width:61%}.address-card-856e4 .address-contentContainer-25ecd .address-contentBox-5cbe3 .address-flexBox-47102 div p{font-size:14px;line-height:28px}.address-card-856e4 .address-contentContainer-25ecd .address-img-86101{width:100%}.address-banner-common-text-d6d8d{color:#fff}.address-banner-common-text-d6d8d h1{font-size:42px;font-weight:400;line-height:60px;margin-bottom:.2rem}.address-banner-common-text-d6d8d p{color:#e3e9f1;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "address-card-856e4",
	"contentContainer": "address-contentContainer-25ecd",
	"title": "address-title-ac700",
	"contentBox": "address-contentBox-5cbe3",
	"flexBox": "address-flexBox-47102",
	"img": "address-img-86101",
	"banner-common-text": "address-banner-common-text-d6d8d",
	"bannerCommonText": "address-banner-common-text-d6d8d"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

import SafeBanner from './SafeBanner';
import SecurityCapability from './SecurityCapability';
import DataLife from './DataLife';
import SafeResponse from './SafeResponse';
import BestPractices from './BestPractices';

export default function SafeTrust() {
    return (
        <>
            <SafeBanner />
            <SecurityCapability />
            <DataLife />
            <SafeResponse />
            <BestPractices />
        </>
    );
}

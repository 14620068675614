import yingjixiangyingIcon from '@/assets/img/img_yingjixiangying.png';
import yonghufuwuzhongxinIcon from '@/assets/img/img_yonghufuwuzhongxin.png';

export default [
    {
        title: '',
        description: (
            <>
                <a href="https://bsrc.baidu.com/v2/#/home" target="_blank" rel="noreferrer">
                    百度安全应急响应中心
                </a>
                （Baidu Security Response
                Center）是百度致力于维护互联网健康生态环境，保障百度产品和业务线的信息安全，促进安全专家的合作与交流而建立的漏洞收集及应急响应平台。
            </>
        ),
        icon: yingjixiangyingIcon,
        iconClassName: 'bsrcIcon',
        buttonText: '提交漏洞',
        buttonLink: 'https://bsrc.baidu.com/v2/#/loophole/create',
    },
    {
        title: '百度用户服务中心',
        description:
            '如果您在使用百度的产品及服务的过程中有任何个人信息保护方面的问题，可点击下方【我要反馈】与我们联系。',
        icon: yonghufuwuzhongxinIcon,
        buttonText: '我要反馈',
        buttonLink: 'https://help.baidu.com/personalinformation',
    },
];

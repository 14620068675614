import baiduAppImg from '@/assets/img/img_baiduapp.png';
import baidudituImg from '@/assets/img/img_baidudituapp.png';
import baiduwangpanImg from '@/assets/img/img_baiduwangpanapp.png';
import baidutiebaImg from '@/assets/img/img_baidutiebaapp.png';
import xiaoduImg from '@/assets/img/img_xiaoduapp.png';
import haokanImg from '@/assets/img/img_haokanshipinapp.png';
import baidushurufaImg from '@/assets/img/img_baidushurufaapp.png';

import dituAppImg1 from '@/assets/privacyManagement/dituApp/1.jpg';
import dituAppImg2 from '@/assets/privacyManagement/dituApp/2.jpg';
import dituAppImg3 from '@/assets/privacyManagement/dituApp/3.jpg';
import dituAppImg4 from '@/assets/privacyManagement/dituApp/4.jpg';
import dituAppImg5 from '@/assets/privacyManagement/dituApp/5.jpg';
import dituAppImg6 from '@/assets/privacyManagement/dituApp/6.jpg';
import dituAppImg7 from '@/assets/privacyManagement/dituApp/7.jpg';
import dituAppImg8 from '@/assets/privacyManagement/dituApp/8.jpg';
import dituAppImg9 from '@/assets/privacyManagement/dituApp/9.jpg';
import dituAppImg10 from '@/assets/privacyManagement/dituApp/10.jpg';
import dituAppImg11 from '@/assets/privacyManagement/dituApp/11.jpg';
import dituAppImg12 from '@/assets/privacyManagement/dituApp/12.jpg';
import dituAppImg13 from '@/assets/privacyManagement/dituApp/13.jpg';

import baiduAppImg1 from '@/assets/privacyManagement/baiduApp/1.jpg';
import baiduAppImg2 from '@/assets/privacyManagement/baiduApp/2.jpg';
import baiduAppImg3 from '@/assets/privacyManagement/baiduApp/3.jpg';
import baiduAppImg4 from '@/assets/privacyManagement/baiduApp/4.jpg';
import baiduAppImg5 from '@/assets/privacyManagement/baiduApp/5.jpg';
import baiduAppImg6 from '@/assets/privacyManagement/baiduApp/6.jpg';
import baiduAppImg7 from '@/assets/privacyManagement/baiduApp/7.jpg';
import baiduAppImg8 from '@/assets/privacyManagement/baiduApp/8.jpg';
import baiduAppImg9 from '@/assets/privacyManagement/baiduApp/9.jpg';
import baiduAppImg10 from '@/assets/privacyManagement/baiduApp/10.jpg';
import baiduAppImg11 from '@/assets/privacyManagement/baiduApp/11.jpg';
import baiduAppImg12 from '@/assets/privacyManagement/baiduApp/12.jpg';
import baiduAppImg13 from '@/assets/privacyManagement/baiduApp/13.jpg';
import baiduAppImg14 from '@/assets/privacyManagement/baiduApp/14.jpg';

import wangpanAppImg0 from '@/assets/privacyManagement/wangpanApp/yinsigaozhi.jpg';
import wangpanAppImg1 from '@/assets/privacyManagement/wangpanApp/1.jpg';
import wangpanAppImg2 from '@/assets/privacyManagement/wangpanApp/2.jpg';
import wangpanAppImg3 from '@/assets/privacyManagement/wangpanApp/3.jpg';
import wangpanAppImg4 from '@/assets/privacyManagement/wangpanApp/4.jpg';
import wangpanAppImg5 from '@/assets/privacyManagement/wangpanApp/5.jpg';
import wangpanAppImg6 from '@/assets/privacyManagement/wangpanApp/6.jpg';
import wangpanAppImg7 from '@/assets/privacyManagement/wangpanApp/7.jpg';
import wangpanAppImg8 from '@/assets/privacyManagement/wangpanApp/8.jpg';
import wangpanAppImg9 from '@/assets/privacyManagement/wangpanApp/9.jpg';
import wangpanAppImg10 from '@/assets/privacyManagement/wangpanApp/10.jpg';

import tiebaAppImg1 from '@/assets/privacyManagement/tiebaApp/1.jpg';
import tiebaAppImg2 from '@/assets/privacyManagement/tiebaApp/2.jpg';
import tiebaAppImg3 from '@/assets/privacyManagement/tiebaApp/3.jpg';
import tiebaAppImg4 from '@/assets/privacyManagement/tiebaApp/4.jpg';
import tiebaAppImg5 from '@/assets/privacyManagement/tiebaApp/5.jpg';
import tiebaAppImg6 from '@/assets/privacyManagement/tiebaApp/6.jpg';
import tiebaAppImg7 from '@/assets/privacyManagement/tiebaApp/7.jpg';
import tiebaAppImg8 from '@/assets/privacyManagement/tiebaApp/8.jpg';
import tiebaAppImg9 from '@/assets/privacyManagement/tiebaApp/9.jpg';
import tiebaAppImg10 from '@/assets/privacyManagement/tiebaApp/10.jpg';
import tiebaAppImg11 from '@/assets/privacyManagement/tiebaApp/11.jpg';
import tiebaAppImg12 from '@/assets/privacyManagement/tiebaApp/12.jpg';

import xiaoduAppImg1 from '@/assets/privacyManagement/xiaoduApp/1.jpg';
import xiaoduAppImg2 from '@/assets/privacyManagement/xiaoduApp/2.jpg';
import xiaoduAppImg3 from '@/assets/privacyManagement/xiaoduApp/3.jpg';
import xiaoduAppImg4 from '@/assets/privacyManagement/xiaoduApp/4.jpg';
import xiaoduAppImg5 from '@/assets/privacyManagement/xiaoduApp/5.jpg';
import xiaoduAppImg6 from '@/assets/privacyManagement/xiaoduApp/6.jpg';
import xiaoduAppImg7 from '@/assets/privacyManagement/xiaoduApp/7.jpg';
import xiaoduAppImg8 from '@/assets/privacyManagement/xiaoduApp/8.jpg';
import xiaoduAppImg9 from '@/assets/privacyManagement/xiaoduApp/9.jpg';
import xiaoduAppImg10 from '@/assets/privacyManagement/xiaoduApp/10.jpg';

import haokanAppImg1 from '@/assets/privacyManagement/haokanApp/1.jpg';
import haokanAppImg2 from '@/assets/privacyManagement/haokanApp/2.jpg';
import haokanAppImg3 from '@/assets/privacyManagement/haokanApp/3.jpg';
import haokanAppImg4 from '@/assets/privacyManagement/haokanApp/4.jpg';
import haokanAppImg5 from '@/assets/privacyManagement/haokanApp/5.jpg';
import haokanAppImg6 from '@/assets/privacyManagement/haokanApp/6.jpg';
import haokanAppImg7 from '@/assets/privacyManagement/haokanApp/7.jpg';
import haokanAppImg8 from '@/assets/privacyManagement/haokanApp/8.jpg';
import haokanAppImg9 from '@/assets/privacyManagement/haokanApp/9.jpg';
import haokanAppImg10 from '@/assets/privacyManagement/haokanApp/10.jpg';
import haokanAppImg11 from '@/assets/privacyManagement/haokanApp/11.jpg';
import haokanAppImg12 from '@/assets/privacyManagement/haokanApp/12.jpg';
import haokanAppImg13 from '@/assets/privacyManagement/haokanApp/13.jpg';
import haokanAppImg14 from '@/assets/privacyManagement/haokanApp/14.jpg';

import shurufaAppImg1 from '@/assets/privacyManagement/shurufaApp/1.jpg';
import shurufaAppImg2 from '@/assets/privacyManagement/shurufaApp/2.jpg';
import shurufaAppImg3 from '@/assets/privacyManagement/shurufaApp/3.jpg';
import shurufaAppImg4 from '@/assets/privacyManagement/shurufaApp/4.jpg';
import shurufaAppImg5 from '@/assets/privacyManagement/shurufaApp/5.jpg';
import shurufaAppImg6 from '@/assets/privacyManagement/shurufaApp/6.jpg';
import shurufaAppImg7 from '@/assets/privacyManagement/shurufaApp/7.jpg';
import shurufaAppImg8 from '@/assets/privacyManagement/shurufaApp/8.jpg';
import shurufaAppImg9 from '@/assets/privacyManagement/shurufaApp/9.jpg';

export default [
    {
        tags: '百度APP',
        icon: baiduAppImg,
        content: [
            {
                tags: '隐私政策告知同意',
                text:
                    '在您使用我们的产品或服务前，我们会通过弹窗等形式向您充分告知我们的个人信息处理方式，并在征得同意后为您提供产品及服务。</br>'
                    + '请您放心，如果具体场景涉及对您敏感个人信息的使用，我们将会依据法律法规另行获取您的同意。',
                imgs: [baiduAppImg1],
            },
            {
                tags: '隐私政策摘要',
                text: '我们为您提供《隐私政策摘要》，摘要旨在帮助您直观、简明地了解我们如何收集处理及保护您的信息。',
                imgs: [baiduAppImg2],
            },
            {
                tags: '双清单',
                text:
                    '我们为您提供《已收集个人信息清单》和《与第三方共享个人信息清单》，'
                    + '方便您以可视化的交互设计、动态查询了解我们在使用服务过程中对个人信息的收集处理情况。',
                imgs: [baiduAppImg3, baiduAppImg4],
            },
            {
                tags: '规则查阅',
                text: '我们为您提供了便捷的查阅入口，方便您随时浏览查阅关于个人信息处理的规则。',
                imgs: [baiduAppImg5],
            },
            {
                tags: '单独权限授权',
                text: '服务需要申请使用的敏感行为或敏感个人信息，我们还会在具体服务场景下单独通过应用权限或产品设计进行告知并另行获取您的同意。',
                imgs: [baiduAppImg6],
            },
            {
                tags: '应用权限说明',
                text: '您可了解我们申请使用权限情况，并可直接跳转至系统进行设置。',
                imgs: [baiduAppImg7],
            },
            {
                tags: '无痕浏览',
                text: '充分考虑和尊重用户的隐私心理，我们提供“无痕浏览”服务，保护您的搜索和浏览历史不被本地记录，满足您的无痕浏览需求。',
                imgs: [baiduAppImg8],
            },
            {
                tags: '个性化推荐控制',
                text:
                    '个性化推荐服务旨在让用户和信息彼此有效触达。您可以通过隐私控制选择开启或关闭个性化内容推荐、个性化或程序化广告推荐。'
                    + '通过隐私控制选择或删除您的兴趣标签。</br>您也可以在浏览单条信息时点击右上角或右下角“×”来反馈您的意见。',
                imgs: [baiduAppImg9, baiduAppImg10, baiduAppImg11],
                isLongImg: [2],
            },
            {
                tags: '账号注销',
                text:
                    '我们为您提供“删除服务痕迹”选项，您可以清除您在当前应用中产生的服务痕迹，'
                    + '但不影响您继续使用百度账号登录使用其他百度系APP服务。'
                    + '当您决定注销并不再使用百度账号及与该账号相关的所有百度服务，您可选择“注销百度账号”。',
                imgs: [baiduAppImg12],
            },
            {
                tags: '帮助与反馈',
                text: '您可通过【我的-设置-帮助与反馈】获取您在使用百度APP服务过程中可能遇到的常见问题解答与指引。',
                imgs: [baiduAppImg13],
            },
            {
                tags: '在线客服',
                text: '您可通过【我的-设置-帮助与反馈-咨询问题】来在线咨询我们的智能客服或人工客服，随时随地获得便捷的在线服务帮助。',
                imgs: [baiduAppImg14],
            },
        ],
    },
    {
        tags: '百度地图',
        icon: baidudituImg,
        content: [
            {
                tags: '隐私政策告知同意',
                text:
                    '在您使用我们的产品或服务前，我们会通过弹窗等形式向您充分告知我们的个人信息处理方式，并在征得同意后为您提供产品及服务。</br>'
                    + '请您放心，如果具体场景涉及对您敏感个人信息的使用，我们将会依据法律法规另行获取您的同意。',
                imgs: [dituAppImg1],
            },
            {
                tags: '隐私政策摘要',
                text: '我们为您提供《隐私政策摘要》，摘要旨在帮助您直观、简明地了解我们如何收集处理及保护您的信息。',
                imgs: [dituAppImg2],
                isLongImg: [0],
            },
            {
                tags: '双清单',
                text:
                    '我们为您提供《已收集个人信息清单》和《与第三方共享个人信息清单》，'
                    + '方便您以可视化的交互设计、动态查询了解我们在使用服务过程中对个人信息的收集处理情况。',
                imgs: [dituAppImg3, dituAppImg4],
            },
            {
                tags: '单独权限授权',
                text: '服务需要申请使用的敏感行为或敏感个人信息，我们还会在具体服务场景下单独通过应用权限或产品设计进行告知并另行获取您的同意。',
                imgs: [dituAppImg5],
            },
            {
                tags: '隐私设置',
                text: '你可以在【我的-设置-隐私设置】中，管理您的各项隐私权限。',
                imgs: [dituAppImg6],
            },
            {
                tags: '语音助手设置',
                text: '您可以在【我的-设置-语音设置-语音助手】中，管理您的语音交互设置。',
                imgs: [dituAppImg7],
            },
            {
                tags: '出行记录设置',
                text: '您可以在【我的-设置-隐私设置-出行记录设置】中设置您的足迹与历史行程，管理您的出行回顾设置。',
                imgs: [dituAppImg8, dituAppImg9, dituAppImg10],
            },
            {
                tags: '个性化推荐设置',
                text: '百度地图为您提供多种推荐服务，提升您的服务体验，您可以通过个性化推荐设置页面选择开启或关闭相关个性化内容推荐。',
                imgs: [dituAppImg11],
            },
            {
                tags: '账号注销',
                text:
                    '您可以通过【我的-账号设置-账号注销】注销账号，我们为您提供“删除服务痕迹”选项，'
                    + '您可以清除您在当前应用中产生的服务痕迹，但不影响您继续使用百度账号登录使用其他百度系APP服务。</br>'
                    + '当您决定注销并不再使用百度账号及与该账号相关的所有百度服务，您可选择“注销百度账号”。',
                imgs: [dituAppImg12],
            },
            {
                tags: '反馈中心',
                text:
                    '您可通过【我的-反馈中心】向我们反馈地图使用中遇到的问题，例如地图具体位置的报错或者新增，帮助更多的用户。'
                    + '同时您也可以通过右上角的【客服】联系我们，随时随地获得便捷的在线服务帮助。',
                imgs: [dituAppImg13],
            },
        ],
    },
    {
        tags: '百度网盘',
        icon: baiduwangpanImg,
        content: [
            {
                tags: '隐私政策告知同意',
                text:
                    '在您使用我们的产品或服务前，我们会通过弹窗等形式向您充分告知我们的个人信息处理方式，并在征得同意后为您提供产品及服务。</br>'
                    + '请您放心，如果具体场景涉及对您敏感个人信息的使用，我们将会依据法律法规另行获取您的同意。',
                imgs: [wangpanAppImg0],
            },
            {
                tags: '隐私政策摘要',
                text: '我们为您提供《隐私政策摘要》，摘要旨在帮助您直观、简明地了解我们如何收集处理及保护您的信息。',
                imgs: [wangpanAppImg1],
                isLongImg: [0],
            },
            {
                tags: '双清单',
                text:
                    '我们为您提供《已收集个人信息清单》和《与第三方共享个人信息清单》，'
                    + '方便您以可视化的交互设计、动态查询了解我们在使用服务过程中对个人信息的收集处理情况。',
                imgs: [wangpanAppImg2, wangpanAppImg3],
            },
            {
                tags: '关于我们',
                text:
                    '您可以通过【我的-设置-关于】中回查我们的用户协议、隐私政策、应用权限说明、第三方SDK情况说明，'
                    + '让您清楚明确地了解我们为您提供服务的情况。',
                imgs: [wangpanAppImg4],
            },
            {
                tags: '自动备份设置',
                text: '您可以通过【我的-设置-自动备份设置】管理您的自动备份权限，帮助您更好的守护您的数据安全。',
                imgs: [wangpanAppImg5],
            },
            {
                tags: '设备管理',
                text: '为保护您的内容安全，您可以在【我的-设置-设备管理】中查看已登录设备、浏览器并进行管理，保护您的内容安全。',
                imgs: [wangpanAppImg6],
            },
            {
                tags: '二级密码设置',
                text:
                    '为了对您的隐私内容进行更为深入的保护，您可以在【我的-设置-二级密码设置】中管理您的二级密码，'
                    + '用来锁定并保护您网盘中的隐藏空间及卡包。',
                imgs: [wangpanAppImg7],
            },
            {
                tags: '锁屏码设置',
                text: '您可以在【我的-设置-百度网盘锁屏码】设置并管理密码锁，避免他人未经允许打开您的网盘。',
                imgs: [wangpanAppImg8],
            },
            {
                tags: '账号注销',
                text:
                    '您可以通过【我的-设置-账号管理-账号注销】注销账号。我们为您提供“删除服务痕迹”选项，'
                    + '您可以清除您在当前应用中产生的服务痕迹，但不影响您继续使用百度账号登录使用其他百度系APP服务。</br>'
                    + '当您决定注销并不再使用百度账号及与该账号相关的所有百度服务，您可选择“注销百度账号”。',
                imgs: [wangpanAppImg9],
            },
            {
                tags: '帮助与反馈',
                text:
                    '您可通过【我的】页面底部的【帮助与反馈】获取您在使用百度网盘APP服务过程中可能遇到的常见问题解答与指引，'
                    + '也可以通过底部的【咨询客服】，随时随地获取我们的在线服务。',
                imgs: [wangpanAppImg10],
            },
        ],
    },
    {
        tags: '百度贴吧',
        icon: baidutiebaImg,
        content: [
            {
                tags: '隐私政策告知同意',
                text:
                    '在您使用我们的产品或服务前，我们会通过弹窗等形式向您充分告知我们的个人信息处理方式，并在征得同意后为您提供产品及服务。</br>'
                    + '请您放心，如果具体场景涉及对您敏感个人信息的使用，我们将会依据法律法规另行获取您的同意。',
                imgs: [tiebaAppImg1],
            },
            {
                tags: '隐私政策摘要',
                text: '我们为您提供《隐私政策摘要》，摘要旨在帮助您直观、简明地了解我们如何收集处理及保护您的信息。',
                imgs: [tiebaAppImg2],
            },
            {
                tags: '双清单',
                text:
                    '我们为您提供《已收集个人信息清单》和《与第三方共享个人信息清单》，'
                    + '方便您以可视化的交互设计、动态查询了解我们在使用服务过程中对个人信息的收集处理情况。',
                imgs: [tiebaAppImg3, tiebaAppImg4],
            },
            {
                tags: '规则查阅',
                text: '我们为您提供了便捷的查阅入口，方便您随时浏览查阅关于个人信息处理的规则。',
                imgs: [tiebaAppImg5],
            },
            {
                tags: '单独权限授权',
                text: '服务需要申请使用的敏感行为或敏感个人信息，我们还会在具体服务场景下单独通过应用权限或产品设计进行告知并另行获取您的同意。',
                imgs: [tiebaAppImg6],
            },
            {
                tags: '应用权限说明',
                text: '您可了解我们申请使用权限情况，并可直接跳转至系统进行设置。',
                imgs: [tiebaAppImg7],
                isLongImg: [0],
            },
            {
                tags: '隐私设置',
                text: '您可通过隐私设置管理您的隐私授权。',
                imgs: [tiebaAppImg8],
            },
            {
                tags: '个性化推荐控制',
                text:
                    '个性化推荐服务旨在让用户和信息彼此有效触达。您可以通过隐私控制选择开启或关闭个性化内容推荐、个性化或程序化广告推荐。通过隐私控制选择或删除您的兴趣标签。</br>'
                    + '您也可以在浏览单条信息时点击右上角或右下角“×”来反馈您的意见。',
                imgs: [tiebaAppImg9],
            },
            {
                tags: '账号注销',
                text:
                    '我们为您提供“删除服务痕迹”选项，您可以清除您在当前应用中产生的服务痕迹，但不影响您继续使用百度账号登录使用其他百度系APP服务。</br>'
                    + '当您决定注销并不再使用百度账号及与该账号相关的所有百度服务，您可选择“注销百度账号”。',
                imgs: [tiebaAppImg10],
            },
            {
                tags: '帮助与反馈',
                text: '您可通过【我的-设置-帮助与反馈】获取您在使用贴吧APP服务过程中可能遇到的常见问题解答与指引。',
                imgs: [tiebaAppImg11],
            },
            {
                tags: '在线客服',
                text: '您可通过【我的-设置-帮助与反馈-咨询问题】来在线咨询我们的智能客服或人工客服，随时随地获得便捷的在线服务帮助。',
                imgs: [tiebaAppImg12],
            },
        ],
    },
    {
        tags: '小度',
        icon: xiaoduImg,
        content: [
            {
                tags: '隐私政策告知同意',
                text:
                    '在您使用我们的产品或服务前，我们会通过弹窗等形式向您充分告知我们的个人信息处理方式，并在征得同意后为您提供产品及服务。</br>'
                    + '请您放心，如果具体场景涉及对您敏感个人信息的使用，我们将会依据法律法规另行获取您的同意。',
                imgs: [xiaoduAppImg10],
            },
            {
                tags: '儿童保护',
                text:
                    '您可通过【我的设备-家长设置】对您的孩子使用小度在家、小度音箱等App绑定设备的时间段、使用时长等进行设置，'
                    + '并可以选择开启儿童模式。儿童模式开启后，App及绑定设备会精选适合儿童观看/收听的内容。',
                imgs: [xiaoduAppImg1],
            },
            {
                tags: '关于小度',
                text: '您可以在【我的-关于小度】中快速查看小度的隐私政策、用户协议等相关文件以及操作其他的隐私管理权限，提升您使用APP的便捷性。',
                imgs: [xiaoduAppImg2],
            },
            {
                tags: '隐私开关',
                text: '您可以在【我的-关于小度-隐私开关】中管理您的隐私权限，为您提供更周全的隐私保护。',
                imgs: [xiaoduAppImg3],
            },
            {
                tags: '隐私常见问题',
                text: '对于您重点关注的隐私问题，小度APP在【我的-关于小度-隐私常见问题问答】中将为您解答，确保您能安心使用小度产品。',
                imgs: [xiaoduAppImg4],
            },
            {
                tags: '个性化推荐设置',
                text: '个性化推荐服务旨在让用户和信息彼此有效触达。您可以通过【我的-关于小度-个性化推荐设置】选择开启或关闭个性化内容推荐。',
                imgs: [xiaoduAppImg5],
            },
            {
                tags: '单独权限授权',
                text: '服务需要申请使用的敏感行为或敏感个人信息，我们还会在具体服务场景下单独通过应用权限或产品设计进行告知并另行获取您的同意。',
                imgs: [xiaoduAppImg6],
            },
            {
                tags: '第三方账号管理',
                text: '您可以在【我的-第三方账号】中管理实现对第三方账号的一键式管理，方便您使用小度产品。',
                imgs: [xiaoduAppImg7],
            },
            {
                tags: '账号注销',
                text:
                    '您可以通过【我的-设置图标-百度账号管理-账号注销】注销账号。</br>'
                    + '当您决定注销并不再使用百度账号及与该账号相关的所有百度服务，您可选择“注销百度账号”。',
                imgs: [xiaoduAppImg8],
            },
            {
                tags: '帮助中心',
                text: '您可通过【我的-设置-帮助与反馈】获取您在使用APP服务过程中可能遇到的常见问题解答与指引。',
                imgs: [xiaoduAppImg9],
            },
        ],
    },
    {
        tags: '好看视频',
        icon: haokanImg,
        content: [
            {
                tags: '隐私政策告知同意',
                text:
                    '在您使用我们的产品或服务前，我们会通过弹窗等形式向您充分告知我们的个人信息处理方式，并在征得同意后为您提供产品及服务。</br>'
                    + '请您放心，如果具体场景涉及对您敏感个人信息的使用，我们将会依据法律法规另行获取您的同意。',
                imgs: [haokanAppImg1],
            },
            {
                tags: '隐私政策摘要',
                text: '我们为您提供《隐私政策摘要》，摘要旨在帮助您直观、简明地了解我们如何收集处理及保护您的信息。',
                imgs: [haokanAppImg2],
            },
            {
                tags: '双清单',
                text:
                    '我们为您提供《已收集个人信息清单》和《与第三方共享个人信息清单》，'
                    + '方便您以可视化的交互设计、动态查询了解我们在使用服务过程中对个人信息的收集处理情况。',
                imgs: [haokanAppImg3, haokanAppImg4],
            },
            {
                tags: '规则查阅',
                text: '我们为您提供了便捷的查阅入口，方便您随时浏览查阅关于个人信息处理的规则。',
                imgs: [haokanAppImg5],
            },
            {
                tags: '单独权限授权',
                text: '服务需要申请使用的敏感行为或敏感个人信息，我们还会在具体服务场景下单独通过应用权限或产品设计进行告知并另行获取您的同意。',
                imgs: [haokanAppImg6],
            },
            {
                tags: '应用权限说明',
                text: '您可了解我们申请使用权限情况，并可直接跳转至系统进行设置。',
                imgs: [haokanAppImg7],
            },
            {
                tags: '隐私设置',
                text: '您可通过隐私设置管理您的隐私授权。',
                imgs: [haokanAppImg8],
            },
            {
                tags: '个性化推荐控制',
                text:
                    '个性化推荐服务旨在让用户和信息彼此有效触达。您可以通过隐私控制选择开启或关闭个性化内容推荐、个性化或程序化广告推荐。通过隐私控制选择或删除您的兴趣标签。</br>'
                    + '您也可以在浏览单条信息时点击右上角或右下角“×”来反馈您的意见。',
                imgs: [haokanAppImg9],
            },
            {
                tags: '青少年模式',
                text:
                    '我们为您提供“青少年模式”服务，您可通过【我的-设置-青少年模式】选择开启并设置独立密码。'
                    + '模式开启后，APP会精选适合青少年观看的内容，并限制操作范围及使用时长。',
                imgs: [haokanAppImg10],
            },
            {
                tags: '账号注销',
                text:
                    '我们为您提供“删除服务痕迹”选项，您可以清除您在当前应用中产生的服务痕迹，但不影响您继续使用百度账号登录使用其他百度系APP服务。</br>'
                    + '当您决定注销并不再使用百度账号及与该账号相关的所有百度服务，您可选择“注销百度账号”。',
                imgs: [haokanAppImg11, haokanAppImg12],
            },
            {
                tags: '帮助与反馈',
                text: '您可通过【我的-设置-帮助与反馈】获取您在使用好看视频APP服务过程中可能遇到的常见问题解答与指引。',
                imgs: [haokanAppImg13],
            },
            {
                tags: '在线客服',
                text: '您可通过【我的-设置-帮助与反馈-咨询问题】来在线咨询我们的智能客服或人工客服，随时随地获得便捷的在线服务帮助。',
                imgs: [haokanAppImg14],
            },
        ],
    },
    {
        tags: '百度输入法',
        icon: baidushurufaImg,
        content: [
            {
                tags: '隐私政策告知同意',
                text:
                    '在您使用我们的产品或服务前，我们会通过弹窗等形式向您充分告知我们的个人信息处理方式，并在征得同意后为您提供产品及服务。</br>'
                    + '请您放心，如果具体场景涉及对您敏感个人信息的使用，我们将会依据法律法规另行获取您的同意。',
                imgs: [shurufaAppImg1],
            },
            {
                tags: '隐私政策摘要',
                text: '我们为您提供《隐私政策摘要》，摘要旨在帮助您直观、简明地了解我们如何收集处理及保护您的信息。',
                imgs: [shurufaAppImg2],
            },
            {
                tags: '双清单',
                text:
                    '我们为您提供《已收集个人信息清单》和《与第三方共享个人信息清单》，'
                    + '方便您以可视化的交互设计、动态查询了解我们在使用服务过程中对个人信息的收集处理情况。',
                imgs: [shurufaAppImg3, shurufaAppImg4],
            },
            {
                tags: '应用权限说明',
                text: '您可了解我们申请使用权限情况，并可直接跳转至系统进行设置。',
                imgs: [shurufaAppImg5],
            },
            {
                tags: '模式切换',
                text:
                    '我们为您提供“基础打字模式”和“完整体验模式”供您随心选择。基础打字模式下，您仅可使用离线文字符号输入服务。'
                    + '完整模式下，您可使用更丰富的云输入、符号、表情等云端服务。',
                imgs: [shurufaAppImg6],
            },
            {
                tags: '词库管理',
                text: '您可通过“词库设置”自主选择是否导入通讯录、个性短语或恢复默认词库内容。',
                imgs: [shurufaAppImg7],
            },
            {
                tags: '账号注销',
                text:
                    '我们为您提供“删除服务痕迹”选项，您可以清除您在当前应用中产生的服务痕迹，但不影响您继续使用百度账号登录使用其他百度系APP服务。</br>'
                    + '当您决定注销并不再使用百度账号及与该账号相关的所有百度服务，您可选择“注销百度账号”。',
                imgs: [shurufaAppImg9],
            },
            {
                tags: '帮助与反馈',
                text: '您可通过【我的-设置-帮助与反馈】获取您在使用好看视频APP服务过程中可能遇到的常见问题解答与指引。',
                imgs: [shurufaAppImg8],
            },
        ],
    },
];

import React from 'react';

import ScrollTab from '@/components/ScrollTab';

import Card from '@/components/Card';
import contentConfig from './contentConfig';

import c from './index.less';
import './index.global.less';

export default function ChildrenPrivacy() {
    return (
        <Card title="儿童个人信息保护规则" className={c.card}>
            <div id="childrenPrivacy">
                <ScrollTab contentConfig={contentConfig} />
            </div>
        </Card>
    );
}
